import React from "react";
import PropTypes from "prop-types";
import "./Fieldset.scss";

const Fieldset = ({ children, className = "", disabled, label, ...props }) => {
  if (!children) return null;
  return (
    <fieldset className={`fieldset${className && ` ${className}`}`} disabled={disabled} {...props}>
      {label && <legend className="fieldset__label">{label}</legend>}
      {children}
    </fieldset>
  );
};

Fieldset.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string
};

export default Fieldset;
