import React from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import SignUp from "../../../Features/Authorization/SignUp";
import ConfirmData from "../../../Features/Authorization/ConfirmData";
import AccountDetails from "../../../Features/Authorization/AccountDetails";
import SignIn from "../../../Features/Authorization/SignIn";
import urls from "../../urls";
import ResetPassword from "../../../Features/Authorization/ResetPassword";
import Header from "../../../layout/Header";
import { ToastContainer } from "react-toastify";
import "./styles.scss";
import { isLoggedIn } from "../../helpers/auth";

const AuthContainer = () => {
  const location = useLocation();

  let headerLink;
  let headerLinkTitle;
  let headerText;

  if (location.pathname === urls.signInUrl.path) {
    headerLink = urls.signUpUrl.path;
    headerLinkTitle = urls.signUpUrl.name;
    headerText = "Not a member yet?";
  } else {
    headerLink = urls.signInUrl.path;
    headerLinkTitle = urls.signInUrl.name;
    headerText = "Already a member?";
  }

  if (isLoggedIn()) return <Redirect to={urls.devicesUrl.path} />;
  return (
    <>
      <Header headerText={headerText} headerLink={headerLink} headerLinkTitle={headerLinkTitle}/>
      <main className="auth-main">
        <Switch>
          <Route path="/auth" exact render={() => <Redirect to={urls.signInUrl.path} />} />
          <Route path={urls.signInUrl.path} exact component={SignIn} />
          <Route path={urls.signUpUrl.path} exact component={SignUp} />
          <Route path={urls.confirmNumberUrl.path} exact render={() => <ConfirmData confirmNumber />} />
          <Route path={urls.confirmEmailUrl.path} exact component={ConfirmData} />
          <Route path={urls.accountDetailsNumberUrl.path} exact render={() => <AccountDetails withNumber />} />
          <Route path={urls.accountDetailsEmailUrl.path} exact component={AccountDetails} />
          <Route path={urls.resetPasswordNumberUrl.path} exact render={() => <ResetPassword withNumber />} />
          <Route path={urls.resetPasswordEmailUrl.path} exact component={ResetPassword} />
          <Route render={() => <Redirect to="/auth" />} />
        </Switch>
      </main>
      <ToastContainer />
    </>
  );
};

export default AuthContainer;
