import React from "react";
import DialogMUI from "../../../Shared/DialogMUI";
import DefaultButton from "../../../Shared/Buttons/DefaultButton";
import "./Dialogs.scss";

const DeleteApiKeyDialog = ({ open, onCLose, keyName, loading, onConfirm }) => {
  return (
    <DialogMUI className="company-dialog t-center" open={open} onClose={onCLose}>
      <h2 className="mb-32">Delete API key</h2>
      <p>
        Are you sure you want to delete API key <b>{keyName ? keyName : "-"}</b>?
      </p>
      <div className="btn-group">
        <DefaultButton className="gray-btn" disabled={loading} onClick={onCLose}>
          Cancel
        </DefaultButton>
        <DefaultButton loading={loading} disabled={loading} onClick={onConfirm}>
          Delete
        </DefaultButton>
      </div>
    </DialogMUI>
  );
};

export default DeleteApiKeyDialog;
