import React, { useEffect } from "react";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getProfile } from "../../actions";
import notifyError from "../../helpers/notifyError";
import notifySuccess from "../../helpers/notifySuccess";
import urls from "../../urls";
// import Dashboard from "../../../Features/Dashboard";
import Header from "../../../layout/Header";
import ProfileSettings from "../../../Features/ProfileSettings";
import Sidebar from "../../../layout/Sidebar";
import Devices from "../../../Features/Devices";
import ManageUsers from "../../../Features/Devices/ManageUsers";
import AddDeviceGroup from "../../../Features/Devices/AddDeviceGroup";
import EditDeviceGroup from "../../../Features/Devices/EditDeviceGroup";
import EditDevice from "../../../Features/Devices/EditDevice";
import Activity from "../../../Features/Activity";
import Users from "../../../Features/Users";
import InviteUsers from "../../../Features/Users/InviteUsers";
import UserPage from "../../../Features/Users/UserPage";
import AddUserGroup from "../../../Features/Users/AddUserGroup";
import EditUserGroup from "../../../Features/Users/EditUserGroup";
import Companies from "../../../Features/Companies";
import ManageCompany from "../../../Features/Companies/ManageCompany";
import OwnersAdmins from "../../../Features/Companies/OwnersAdmins";
import InviteToCompany from "../../../Features/Companies/InviteToCompany";
import Toast from "../../../Shared/Toast";
import ApiKeys from "../../../Features/Companies/ApiKeys";
import ShareAccess from "../../../Features/Devices/ShareAccess";
import { isKeycloakEnabled, isLoggedIn } from "../../helpers/auth";
import { useKeycloak } from "@react-keycloak/web";

const Container = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { errorSnack, errorSnackText, successSnack, successSnackText, profile } = useSelector(({ app }) => app);

  if (!isLoggedIn()) {
    history.replace(urls.signInUrl.path);
  }

  useEffect(() => {
    if (errorSnack) {
      notifyError(errorSnackText);
    }
  }, [errorSnack]);

  useEffect(() => {
    if (successSnack) {
      notifySuccess(successSnackText);
    }
  }, [successSnack]);

  if (isKeycloakEnabled()) {
    const {initialized} = useKeycloak();

    useEffect(() => {
      console.log("getProfile?", initialized);
      if (initialized && location.pathname !== urls.devicesUrl.path) {
        dispatch(getProfile());
      }
    }, [initialized]);
    
    // Wait for keycloak to initialize...
    if (!initialized) { return <></>; }
  } else {
    useEffect(() => {
      if (location.pathname !== urls.devicesUrl.path) {
        dispatch(getProfile());
      }
    }, []);
  }

  return (
    <>
      <Header notifyIcon profile />
      <Sidebar />
      <Switch>
        {/*<Route path="/main" exact render={() => <Redirect to={urls.dashboardUrl.path} />} />*/}
        {/*<Route path={urls.dashboardUrl.path} component={Dashboard} />*/}
        <Route path="/main" exact render={() => <Redirect to={urls.devicesUrl.path} />} />
        <Route path={urls.profileUrl.path} component={ProfileSettings} />
        <Route path={urls.devicesUrl.path} component={Devices} exact />
        <Route path={`${urls.manageUsersUrl.path}/:id`} component={ManageUsers} />
        <Route path={`${urls.inviteUsersToDeviceUrl.path}/:deviceSlug`} component={InviteUsers} />
        <Route path={urls.addDeviceGroupUrl.path} component={AddDeviceGroup} />
        <Route path={urls.editDeviceGroupUrl.path} component={EditDeviceGroup} />
        <Route path={`${urls.editDeviceUrl.path}/:id`} component={EditDevice} />
        <Route path={`${urls.shareAccessUrl.path}/:deviceSlug`} component={ShareAccess} />
        <Route path={urls.activityUrl.path} component={Activity} />
        <Route path={urls.usersUrl.path} component={Users} exact />
        <Route path={urls.inviteUsersUrl.path} component={InviteUsers} exact />
        <Route path={urls.userPageUrl.path} component={UserPage} exact />
        <Route path={urls.inviteSingleUserUrl.path} component={InviteUsers} />
        <Route path={urls.addUserGroupUrl.path} component={AddUserGroup} />
        <Route path={urls.editUserGroupUrl.path} component={EditUserGroup} />
        <Route path={urls.companiesUrl.path} component={Companies} exact />
        <Route path={urls.addCompanyUrl.path} component={ManageCompany} />
        <Route path={`${urls.editCompanyUrl.path}/:id`} component={ManageCompany} />
        <Route path={`${urls.ownersAndAdminsUrl.path}/:id`} component={OwnersAdmins} />
        <Route path={`${urls.inviteUsersToCompanyUrl.path}/:id`} component={InviteToCompany} />
        <Route path={`${urls.apiKeysUrl.path}/:id`} component={ApiKeys} />
        <Route render={() => <Redirect to="/main" />} />
      </Switch>
      {!profile?.is_email_confirmed && (
        <Toast text="Please, approve your email to receive the notifications to your email" />
      )}
      <ToastContainer />
    </>
  );
};

export default Container;
