import React, { useRef } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import DefaultButton from "../Buttons/DefaultButton";
import DialogMUI from "../DialogMUI";
import "./CropperDialog.scss";

const CropperDialog = ({ imageToCrop, onFinishCropping, onCancelCropping, aspectRatio = 1, loading }) => {
  const cropperRef = useRef(null);
  const onSaveCropping = () => onFinishCropping(cropperRef.current.cropper.getCroppedCanvas());

  return (
    <DialogMUI open onClose={onCancelCropping} className="cropper-dialog">
      <Cropper
        className="cropper-dialog__box"
        src={imageToCrop}
        aspectRatio={aspectRatio}
        minCropBoxHeight={10}
        minCropBoxWidth={10}
        autoCropArea={1}
        guides
        viewMode={1}
        ref={cropperRef}
      />
      <DefaultButton
        className="cropper-dialog__btn"
        disabled={!cropperRef || loading}
        loading={loading}
        onClick={onSaveCropping}
      >
        Save
      </DefaultButton>
    </DialogMUI>
  );
};

export default CropperDialog;
