import React from "react";
import DialogMUI from "../../../Shared/DialogMUI";
import DefaultButton from "../../../Shared/Buttons/DefaultButton";

const ChangeRoleDialog = ({ open, onCLose, role, username, loading, onConfirm }) => {
  return (
    <DialogMUI className="change-role-dialog t-center" open={open} onClose={onCLose}>
      <h2 className="mb-16">Make {role === "DEVICE_ADMIN" ? "admin" : "user"}</h2>
      <p className="mb-48 mx-auto max-w-312">
        Confirm that you want to appoint <b>{username ? username : "this user"}</b> as{" "}
        {role === "DEVICE_ADMIN" ? "admin" : "user"}
      </p>
      <DefaultButton loading={loading} disabled={loading} onClick={onConfirm}>
        Confirm
      </DefaultButton>
    </DialogMUI>
  );
};

export default ChangeRoleDialog;
