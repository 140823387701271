import { COMPANIES } from "./companiesActionTypes";

const INITIAL_STATE = {
  loading: true,
  buttonLoading: false,
  companies: {
    results: []
  },
  company: {},
  shortInfo: {},
  activeUsers: {},
  invitedUsers: {},
  apiKeys: [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }]
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case COMPANIES.POST_COMPANY:
      return { ...state, buttonLoading: true };
    case COMPANIES.POST_COMPANY_SUCCESS:
      return { ...state, buttonLoading: false };
    case COMPANIES.POST_COMPANY_FAIL:
      return { ...state, buttonLoading: false };

    case COMPANIES.GET_COMPANIES:
      return { ...state, loading: true };
    case COMPANIES.GET_COMPANIES_SUCCESS:
      return { ...state, loading: false, companies: action?.payload?.data };
    case COMPANIES.GET_COMPANIES_FAIL:
      return { ...state, loading: false };

    case COMPANIES.SET_CURRENT_COMPANY:
      return { ...state, buttonLoading: true };
    case COMPANIES.SET_CURRENT_COMPANY_SUCCESS:
      return { ...state, buttonLoading: false };
    case COMPANIES.SET_CURRENT_COMPANY_FAIL:
      return { ...state, buttonLoading: false };

    case COMPANIES.GET_COMPANY:
      return { ...state, loading: true };
    case COMPANIES.GET_COMPANY_SUCCESS:
      return { ...state, loading: false, company: action?.payload?.data };
    case COMPANIES.GET_COMPANY_FAIL:
      return { ...state, loading: false };

    case COMPANIES.PATCH_COMPANY:
      return { ...state, buttonLoading: true };
    case COMPANIES.PATCH_COMPANY_SUCCESS:
      return { ...state, buttonLoading: false, company: action?.payload?.data };
    case COMPANIES.PATCH_COMPANY_FAIL:
      return { ...state, buttonLoading: false };

    case COMPANIES.GET_COMPANY_SHORT_INFO_SUCCESS:
      return { ...state, shortInfo: action?.payload?.data };

    case COMPANIES.GET_COMPANY_ACTIVE_USERS_SUCCESS:
      return { ...state, activeUsers: action?.payload?.data };

    case COMPANIES.GET_COMPANY_INVITED_USERS_SUCCESS:
      return { ...state, invitedUsers: action?.payload?.data };

    case COMPANIES.DELETE_COMPANY_USER:
      return { ...state, buttonLoading: true };
    case COMPANIES.DELETE_COMPANY_USER_SUCCESS:
      return { ...state, buttonLoading: false };
    case COMPANIES.DELETE_COMPANY_USER_FAIL:
      return { ...state, buttonLoading: false };

    case COMPANIES.POST_INVITE_COMPANY:
      return { ...state, buttonLoading: true };
    case COMPANIES.POST_INVITE_COMPANY_SUCCESS:
      return { ...state, buttonLoading: false };
    case COMPANIES.POST_INVITE_COMPANY_FAIL:
      return { ...state, buttonLoading: false };

    case COMPANIES.GET_API_KEYS:
      return { ...state, loading: true };
    case COMPANIES.GET_API_KEYS_SUCCESS:
      return { ...state, loading: false, apiKeys: action?.payload?.data };
    case COMPANIES.GET_API_KEYS_FAIL:
      return { ...state, loading: false };

    case COMPANIES.POST_API_KEY:
      return { ...state, buttonLoading: true };
    case COMPANIES.POST_API_KEY_SUCCESS:
      return { ...state, buttonLoading: false };
    case COMPANIES.POST_API_KEY_FAIL:
      return { ...state, buttonLoading: false };

    case COMPANIES.DELETE_API_KEY:
      return { ...state, buttonLoading: true };
    case COMPANIES.DELETE_API_KEY_SUCCESS:
      return { ...state, buttonLoading: false };
    case COMPANIES.DELETE_API_KEY_FAIL:
      return { ...state, buttonLoading: false };

    default:
      return state;
  }
}
