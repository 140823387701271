import { REMOTE_LINK } from "./remoteLinkActionTypes";

const INITIAL_STATE = {
  loading: true,
  buttonLoading: false,
  status: null,
  error: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case REMOTE_LINK.GET_DEVICE_STATUS:
      return { ...state, loading: true };
    case REMOTE_LINK.GET_DEVICE_STATUS_SUCCESS:
      return { ...state, loading: false, status: action.payload.data };
    case REMOTE_LINK.GET_DEVICE_STATUS_FAIL:
      return { ...state, loading: false };
      
    case REMOTE_LINK.OPEN_LOCK:
      return { ...state, buttonLoading: true };
    case REMOTE_LINK.OPEN_LOCK_SUCCESS:
      return { ...state, buttonLoading: false };
    case REMOTE_LINK.OPEN_LOCK_FAIL:
      return { ...state, buttonLoading: false };

    default:
      return state;
  }
}
