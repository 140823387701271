import React, { useState } from "react";
import DialogMUI from "../../../Shared/DialogMUI";
import InputField from "../../../Shared/InputField";
import DefaultButton from "../../../Shared/Buttons/DefaultButton";

const RemoveDeviceDialog = ({ open, onCLose, name, loading, onConfirm }) => {
  const [password, setPassword] = useState("");
  const handlePassword = event => setPassword(event.target.value);

  const closeDialog = () => {
    onCLose();
    setPassword("");
  };
  const removeDevice = () => {
    onConfirm(password);
    setPassword("");
  };

  return (
    <DialogMUI className="remove-device-dialog" open={open} onClose={closeDialog}>
      <h2 className="mb-32">Remove device</h2>
      <p className="mb-16">Confirm with the password that you want to remove {name && <b>{name}</b>}</p>
      <InputField label="Your password" fullWidth typePassword value={password} onChange={handlePassword} />

      <div className="btn-group">
        <DefaultButton className="gray-btn" disabled={loading} onClick={closeDialog}>
          Cancel
        </DefaultButton>
        <DefaultButton loading={loading} disabled={loading || !password} onClick={removeDevice}>
          Remove
        </DefaultButton>
      </div>
    </DialogMUI>
  );
};

export default RemoveDeviceDialog;
