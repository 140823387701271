import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { createBrowserHistory } from "history";
import { ConnectedRouter, routerMiddleware } from "connected-react-router";
import { multiClientMiddleware } from "redux-axios-middleware";
import axios from "axios";
import api from "./core/api";
import routes from "./core/routes";
import rootReducer from "./core/rootReducer";
import rootSaga from "./core/rootSaga";
import { StylesProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/dayjs";
import { API_BASE_URL } from "./core/config";
import mqttMiddleware from "./Shared/Mqtt/mqttMiddleware";
import { getAccessToken, isActiveKeycloakSession, isKeycloakEnabled, signOut } from "./core/helpers/auth";
import urls from "./core/urls";
import { toast } from "react-toastify";
import keycloak from "./core/keycloak";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { AUTH_KEYCLOAK } from "./core/helpers/constants";


let keycloakRefreshToken = null;
let keycloakToken = null;
if (isKeycloakEnabled() && isActiveKeycloakSession()) {
  keycloakToken = localStorage.getItem("keycloak-access-token");
  keycloakRefreshToken = localStorage.getItem("keycloak-refresh-token");
}
const keycloakEventHandler = isKeycloakEnabled() ? (event, error) => {
  console.log("onKeycloakEvent", event, error);
  switch (event) {
    case "onAuthSuccess":
    case "onAuthRefreshSuccess":
      localStorage.setItem("keycloak-refresh-token", keycloak.refreshToken);
      localStorage.setItem("keycloak-access-token", keycloak.token);
      if (localStorage.getItem("session-type") !== AUTH_KEYCLOAK) {
        localStorage.setItem("session-type", AUTH_KEYCLOAK);
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        history.push(urls.dashboardUrl.path);
      }
      break;
  }
} : undefined;

const axiosMiddlewareOptions = {
  interceptors: {
    request: [
      async (action, config) => {
        config.headers["Authorization"] = "Bearer " + await getAccessToken();
        return config;
      }
    ],
    response: [
      {
        success: (func, response) => {
          return response;
        },
        error: async (func, error) => {
          if (error.response.status === 401) {
            // User who logs in via third party identity provider could potentially have duplicate email / phone number. 
            // Show that information to the user when it happens.
            if (isActiveKeycloakSession() && error.response?.data?.code?.message === "user_not_unique") {
              localStorage.removeItem("session-type");
              localStorage.removeItem("keycloak-refresh-token");
              localStorage.removeItem("keycloak-access-token");
              history.replace(urls.signInUrl.path);
              toast.error("A different account with the same email or phone number already exists");
            } else {
              const refreshToken = localStorage.getItem("refresh");
              try {
                const res = await axios.post(`${API_BASE_URL}auth/jwt-refresh-token`, { refresh: refreshToken });
                const { access, refresh } = res?.data;
                localStorage.removeItem("session-type");
                localStorage.setItem("token", access);
                localStorage.setItem("refresh", refresh);
                window.location.reload();
              } catch (_error) {
                signOut(true);
                window.location.reload();
              }
            }
          }
          return Promise.reject(error);
        }
      }
    ]
  }
};

const history = createBrowserHistory();
const appRouterMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();
const createStoreWithMiddleware = applyMiddleware(
  multiClientMiddleware(api, axiosMiddlewareOptions),
  appRouterMiddleware,
  sagaMiddleware,
  mqttMiddleware
)(createStore);
const store = createStoreWithMiddleware(
  rootReducer(history),
  {},
  window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
);

sagaMiddleware.run(rootSaga);

const app = (<Provider store={store}>
  <ConnectedRouter history={history}>
    <StylesProvider injectFirst>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>{routes}</MuiPickersUtilsProvider>
    </StylesProvider>
  </ConnectedRouter>
</Provider>);

ReactDOM.render(
  isKeycloakEnabled() ?
  <ReactKeycloakProvider
    authClient={keycloak} 
    onEvent={keycloakEventHandler}
    initOptions={{
      onLoad: undefined,
      checkLoginIframe: false,
      token: keycloakToken,
      refreshToken: keycloakRefreshToken
    }}
  >
    {app}
  </ReactKeycloakProvider> :
  app,
  document.getElementById("root")
);
