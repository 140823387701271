import { COMPANIES } from "./companiesActionTypes";

export function postCompany(data) {
  return {
    type: COMPANIES.POST_COMPANY,
    payload: {
      request: {
        url: "companies/",
        method: "post",
        data
      }
    }
  };
}

export function getCompanies(query = "") {
  return {
    type: COMPANIES.GET_COMPANIES,
    payload: {
      request: {
        url: `companies/${query}`
      }
    }
  };
}

export function setCurrentCompany(id) {
  return {
    type: COMPANIES.SET_CURRENT_COMPANY,
    payload: {
      request: {
        url: "profile/set-current-company/",
        method: "post",
        data: {
          company: id
        }
      }
    }
  };
}

export function getCompany(id) {
  return {
    type: COMPANIES.GET_COMPANY,
    payload: {
      request: {
        url: `companies/${id}/`
      }
    }
  };
}

export function patchCompany(id, data) {
  return {
    type: COMPANIES.PATCH_COMPANY,
    payload: {
      request: {
        url: `companies/${id}/`,
        method: "patch",
        data
      }
    }
  };
}

export function getCompanyShortInfo(id) {
  return {
    type: COMPANIES.GET_COMPANY_SHORT_INFO,
    payload: {
      request: {
        url: `/companies/${id}/short-info/`
      }
    }
  };
}

export const getCompanyUsers = (companyId, access = "users") => (query = "") => {
  let type = COMPANIES.GET_COMPANY_ACTIVE_USERS;
  if (access === "invited") type = COMPANIES.GET_COMPANY_INVITED_USERS;

  return {
    type,
    payload: {
      request: {
        url: `/companies/${companyId}/${access}/${query}`
      }
    }
  };
};

export function deleteCompanyUser(companyId, userId, access = "users") {
  return {
    type: COMPANIES.DELETE_COMPANY_USER,
    payload: {
      request: {
        url: `/companies/${companyId}/${access}/${userId}/`,
        method: "delete"
      }
    }
  };
}

export function postInviteCompany(companyId, role, users) {
  return {
    type: COMPANIES.POST_INVITE_COMPANY,
    payload: {
      request: {
        url: `/companies/${companyId}/invite/`,
        method: "post",
        data: { role, users }
      }
    }
  };
}

export function getApiKeys(companyId) {
  return {
    type: COMPANIES.GET_API_KEYS,
    payload: {
      request: {
        url: `/companies/${companyId}/api-key/`
      }
    }
  };
}

export function postApiKey(companyId, name) {
  return {
    type: COMPANIES.POST_API_KEY,
    payload: {
      request: {
        url: `/companies/${companyId}/api-key/`,
        method: "post",
        data: { name }
      }
    }
  };
}

export function deleteApiKey(companyId, keyId) {
  return {
    type: COMPANIES.DELETE_API_KEY,
    payload: {
      request: {
        url: `/companies/${companyId}/api-key/${keyId}`,
        method: "delete"
      }
    }
  };
}
