import React from "react";
import urls from "../../../core/urls";
import BackLink from "../../../Shared/BackLink";
import InputField from "../../../Shared/InputField";
import SelectComponent from "../../../Shared/SelectComponent";
import DefaultButton from "../../../Shared/Buttons/DefaultButton";
import "./AddDeviceGroup.scss";

const AddDeviceGroup = () => {
  const devices = [{ value: "", label: "" }];

  return (
    <div className="add-device-group page-wrap">
      <BackLink href={urls.devicesUrl.path} text="Devices" />
      <h1 className="page-title">{urls.addDeviceGroupUrl.name}</h1>

      <div className="add-device-group__section section-wrap">
        <div className="add-device-group__row">
          <InputField className="min-w-506" label="Group name" />
        </div>

        <div className="add-device-group__row">
          <h2 className="subtitle mb-24">Devices</h2>
          <SelectComponent className="min-w-506" placeholder="Devices" options={devices} />
        </div>

        <DefaultButton className="add-device-group__add-btn">Add group</DefaultButton>
      </div>
    </div>
  );
};



export default AddDeviceGroup;
