import React from "react";
import { ReactComponent as EmailIcon } from "../../assets/images/invited-avatar.svg";
import { ReactComponent as PhoneIcon } from "../../assets/images/invited-avatar-2.svg";
import { ReactComponent as ErrorIcon } from "../../assets/images/invited-avatar-3.svg";

const InviteItem = ({ title, type = "email", onClick }) => {
  let avatar = <EmailIcon className="autocomplete-item__avatar" />;
  let subtitle = "invite via email";
  let classSlug = "invite";

  if (type === "phone") {
    avatar = <PhoneIcon className="autocomplete-item__avatar" />;
    subtitle = "invite via phone";
  }
  if (type === "error") {
    avatar = <ErrorIcon className="autocomplete-item__avatar" />;
    subtitle = "Enter a valid email or phone number to share the access";
    classSlug = "error";
  }

  return (
    <div className={`autocomplete-item autocomplete-item--${classSlug}`} onClick={onClick}>
      {avatar}
      <div className="autocomplete-item__text-wrap">
        <span className="autocomplete-item__title">{title}</span>
        <span className="autocomplete-item__subtitle">{subtitle}</span>
      </div>
    </div>
  );
};

export default InviteItem;
