import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, FormControlLabel, FormLabel, RadioGroup } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { getDeviceUsers, getShareOptions, getUserInfo, postInviteUsers } from "../usersActions";
import { getDeviceName } from "../../Devices/devicesActions";
import urls from "../../../core/urls";
import { accessTypeData, limitTypeData, pinCodeOptions } from "../../../core/helpers/constants";
import { toastErrors } from "../../../core/helpers/toastErrors";
import BackLink from "../../../Shared/BackLink";
import RadioMUI from "../../../Shared/RadioMUI";
import SelectComponent from "../../../Shared/SelectComponent";
import DefaultButton from "../../../Shared/Buttons/DefaultButton";
import DateTimePickerMUI from "../../../Shared/DateTimePickerMUI";
import InputField from "../../../Shared/InputField";
import NumberField from "../../../Shared/NumberField";
import SearchableSelect from "../../../Shared/SearchableSelect";
import Autocomplete from "../../../Shared/Autocomplete";
import Tooltip from "../../../Shared/Tooltip";
import InvitesSentDialog from "../Dialogs/InvitesSentDialog";
import OverwriteAccessDialog from "../Dialogs/OverwriteAccessDialog";
import AlreadyHasAccessDialog from "../Dialogs/AlreadyHasAccessDialog";
import "./InviteUsers.scss";

const InviteUsers = () => {
  const history = useHistory();
  const { userSlug, deviceSlug } = useParams();
  const dispatch = useDispatch();
  const {
    buttonLoading,
    userInfo: { first_name, last_name },
    shareOptions,
    deviceUsers
  } = useSelector(({ users }) => users);
  const {
    deviceName: { device_name, type, remote_access }
  } = useSelector(({ devices }) => devices);

  useEffect(() => {
    userSlug && dispatch(getUserInfo(userSlug));
    if (deviceSlug) {
      dispatch(getDeviceName(deviceSlug));
      dispatch(getDeviceUsers([deviceSlug]));
      setDevices([deviceSlug]);
    }
    !deviceSlug && dispatch(getShareOptions());
  }, []);

  const deviceOptions = shareOptions?.devices?.map(({ id, device_name, type, serial_number, remote_access }) => ({
    value: id,
    label: device_name,
    firstInfo: type,
    secondInfo: serial_number,
    remoteAccess: remote_access
  }));

  const [devices, setDevices] = useState([]);
  const handleDevices = value => {
    setDevices([value.value]);
    setDeviceType(value.firstInfo);
    setRemoteAccess(value.remote_access);
    userSlug && setUsers([userSlug]);
    !userSlug && dispatch(getDeviceUsers([value?.value]));
  };

  const [deviceType, setDeviceType] = useState("");
  const [remoteAccess, setRemoteAccess] = useState(null);
  useEffect(() => {
    type && setDeviceType(type);
  }, [type]);
  useEffect(() => {
    remote_access && setRemoteAccess(remote_access);
  }, [remote_access]);

  // Access type
  let accessTypeList = accessTypeData;
  const [accessType, setAccessType] = useState("DIGITAL_KEY_ACCESS");
  const [slug, setSlug] = useState("digital_key");
  const handleAccessType = event => {
    const { value, name } = event.target;
    setAccessType(value);
    setSlug(name);
  };

  const [pinLength, setPinLength] = useState({ value: 4, label: "4-digit" });
  const handlePinLength = event => {
    const { value, label } = event;
    setPinLength({ value, label });
  };

  const [rfidNumber, setRfidNumber] = useState("");
  const [vrfidNumber, setVrfidNumber] = useState("");
  const handleNumber = setter => event => setter(event.target.value);

  // Limit type
  let limitTypeList = limitTypeData;
  const [limitType, setLimitType] = useState("unlimited");
  const handleLimitType = event => setLimitType(event.target.value);

  const getInitialDate = () => {
    const date = new Date();
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  };

  const [dateFrom, setDateFrom] = useState(getInitialDate());
  const [dateTo, setDateTo] = useState(getInitialDate());

  const [usage, setUsage] = useState(1);
  if (usage > 100) setUsage(100);
  if (usage < 1) setUsage(1);

  if (deviceType === "EURO_1" || deviceType === "STANDARD_1") {
    accessTypeList = accessTypeList.filter(item => item.value === "DIGITAL_KEY_ACCESS");
  }

  if (accessType === "PIN_ACCESS" || accessType === "RFID_ACCESS") {
    limitTypeList = limitTypeList.filter(item => ["unlimited", "datetime"].includes(item.value));
  } else if (accessType === "LINK_ACCESS") {
    limitTypeList = limitTypeList.filter(item => ["datetime", "datetime_count"].includes(item.value));
  } else {
    limitTypeList = limitTypeList.filter(item => item.value !== "datetime_count");
  }
  if (!limitTypeList.find(item => item.value === limitType)) {
    setLimitType(limitTypeList[0].value);
  }

  const [usersOptions, setUsersOptions] = useState([]);
  useEffect(() => {
    setUsersOptions(deviceUsers);
  }, [deviceUsers]);

  const [users, setUsers] = useState([]);
  const [usersError, setUsersError] = useState(false);
  const handleUsers = value => {
    const sortedUsers = value.map(item => {
      if (item.email || item.phone_number) return item.email || item.phone_number;
    });
    setUsersError(!!value?.find(el => el?.error));
    setUsers(sortedUsers);
  };

  const [revalidate, setRevalidate] = useState(0);
  useEffect(() => {
    if (users.length > 0) {
        setRevalidate(prev => prev + 1);
    }
  }, [accessType, limitType]);

  let disabledButton = false;
  if (buttonLoading) disabledButton = true;
  if (!devices?.length) disabledButton = true;
  if (!users?.length || users.length > 100) disabledButton = true;
  if (accessType === "RFID_ACCESS" && !rfidNumber) disabledButton = true;
  if (accessType === "VIRTUAL_RFID_ACCESS" && !vrfidNumber) disabledButton = true;
  if (limitType === "datetime" && dateFrom >= dateTo) disabledButton = true;
  if (limitType === "datetime_count" && dateFrom >= dateTo) disabledButton = true;
  if ((accessType === "RFID_ACCESS" || accessType === "VIRTUAL_RFID_ACCESS") && users?.length >= 2) {
    disabledButton = true;
  }

  const data = {
    devices,
    users,
    access: {
      type: accessType
    }
  };

  data.access[slug] = { limit_type: limitType };
  if (accessType === "PIN_ACCESS") data.access[slug].pin_length = pinLength.value;
  if (accessType === "RFID_ACCESS") data.access[slug].rfid_number = rfidNumber;
  if (accessType === "VIRTUAL_RFID_ACCESS") data.access[slug].vrfid_number = vrfidNumber;
  if (limitType === "datetime") {
    data.access[slug].datetime_from = dateFrom.toISOString();
    data.access[slug].datetime_to = dateTo.toISOString();
  } else if (limitType === "total_count") {
    data.access[slug].total_count = usage;
  } else if (limitType === "datetime_count") {
    data.access[slug].total_count = usage;
    data.access[slug].datetime_from = dateFrom.toISOString();
    data.access[slug].datetime_to = dateTo.toISOString();
  }

  const sendInvites = async (overwrite = false) => {
    const res = await dispatch(postInviteUsers(data, overwrite));
    if (res?.payload) setSuccessDialog(true);
    if (res?.error) {
      let accessError = res?.error?.response?.data?.non_field_errors?.find(
        item => item?.code === "access_exists" || item?.code === "exact_access_exists"
      )?.code;

      if (!accessError) toastErrors(res);
      if (accessError) setOverwriteDialog(true);
      // if (accessError === "access_exists") setOverwriteDialog(true);
      // if (accessError === "exact_access_exists") setHasAccessDialog(true);
    }
  };

  const [overwriteDialog, setOverwriteDialog] = useState(false);
  const confirmOverwrite = async () => {
    await sendInvites(true);
    closeOverwriteDialog();
  };
  const closeOverwriteDialog = () => setOverwriteDialog(false);

  const [hasAccessDialog, setHasAccessDialog] = useState(false);
  const closeHasAccessDialog = () => setHasAccessDialog(false);

  const [successDialog, setSuccessDialog] = useState(false);
  const closeSuccessDialog = () => {
    let redirect = urls.usersUrl.path;
    if (userSlug) redirect = `${urls.usersUrl.path}/${userSlug}`;
    if (deviceSlug) redirect = `${urls.manageUsersUrl.path}/${deviceSlug}`;
    history.push(redirect);
    setSuccessDialog(false);
  };

  return (
    <div className="invite-users page-wrap">
      {userSlug && (
        <>
          <BackLink href={`${urls.usersUrl.path}/${userSlug}`} text="User" />
          <h1 className="page-title">
            {urls.inviteSingleUserUrl.name}{" "}
            <span className="page-title__inner">
              {first_name && first_name} {last_name && last_name}
            </span>
          </h1>
        </>
      )}
      {deviceSlug && (
        <>
          <BackLink href={`${urls.manageUsersUrl.path}/${deviceSlug}`} text="Device" />
          <h1 className="page-title">
            {urls.inviteUsersToDeviceUrl.name}{" "}
            <span className="page-title__inner">to {device_name && device_name}</span>
          </h1>
        </>
      )}
      {!userSlug && !deviceSlug && (
        <>
          <BackLink href={urls.usersUrl.path} text="Users" />
          <h1 className="page-title">{urls.inviteUsersUrl.name}</h1>
        </>
      )}

      <div className="invite-users__section section-wrap">
        {!deviceSlug && (
          <FormControl className="invite-users__row" component="fieldset">
            <FormLabel className="invite-users__legend" component="legend">
              Add user to
            </FormLabel>
            <SearchableSelect
              className="max-w-352"
              label="Device"
              searchLabel="Search device"
              disabled={!userSlug && users?.length}
              options={deviceOptions}
              onChange={handleDevices}
            />
          </FormControl>
        )}

        <FormControl className="invite-users__row" component="fieldset">
          <FormLabel className="invite-users__legend" component="legend">
            Choose access type
          </FormLabel>
          <RadioGroup className="invite-users__radio-group" value={accessType} onChange={handleAccessType}>
            {accessTypeList.map(({ value, label, name }) => {
              const control = (
                <FormControlLabel
                  key={value}
                  className="invite-users__label"
                  value={value}
                  name={name}
                  control={<RadioMUI />}
                  label={label}
                  disabled={value === "LINK_ACCESS" && remoteAccess !== true}
                />
              );
              if (value === "LINK_ACCESS" && remoteAccess !== true) {
                return (
                  <Tooltip
                    key={value}
                    className="invite-users__link-tooltip"
                    text="The device must have remote access enabled"
                  >
                    {control}
                  </Tooltip>
                );
              }
              return control;
            })}
          </RadioGroup>

          {accessType === "PIN_ACCESS" && (
            <>
              <p className="mt-24 mb-16">
                Select the length of the PIN-code that will be generated automatically and sent to the users specified
                below
              </p>
              <SelectComponent label="Length" options={pinCodeOptions} value={pinLength} onChange={handlePinLength} />
            </>
          )}
          {accessType === "RFID_ACCESS" && (
            <>
              <p className="mt-24 mb-16">
                RFID access with the inserted number will be sent to the users email addresses below
              </p>
              <InputField
                className="min-w-790"
                label="RFID number"
                value={rfidNumber}
                onChange={handleNumber(setRfidNumber)}
              />
            </>
          )}
          {accessType === "VIRTUAL_RFID_ACCESS" && (
            <>
              <p className="mt-24 mb-16">
                Virtual RFID access with the inserted number will be sent to the users email addresses below
              </p>
              <InputField
                className="min-w-790"
                label="VRFID number"
                value={vrfidNumber}
                onChange={handleNumber(setVrfidNumber)}
              />
            </>
          )}
          {accessType === "LINK_ACCESS" && (
            <>
              <p className="mt-24">
                Share a link to a user via email or sms. With a link, the user can open the lock without downloading BLE
                Locking app.
              </p>
              <Alert className="mt-24" severity="info">
                Links require that the device is connected to the internet and has remote access turned on. Disabling
                remote access prevents all shared links of that device from functioning.
              </Alert>
            </>
          )}
        </FormControl>

        <FormControl className="invite-users__row" component="fieldset">
          <FormLabel className="invite-users__legend" component="legend">
            Choose limit type
          </FormLabel>
          <RadioGroup className="invite-users__radio-group" value={limitType} onChange={handleLimitType}>
            {limitTypeList.map(({ value, label }) => (
              <FormControlLabel
                key={value}
                className="invite-users__label"
                value={value}
                control={<RadioMUI />}
                label={label}
              />
            ))}
          </RadioGroup>

          {limitType === "datetime" && (
            <>
              {accessType === "LINK_ACCESS" && (
                <p className="mt-24">Maximum valid time period for link access is 30 days.</p>
              )}
              <div className="mt-24">
                <DateTimePickerMUI className="mr-16" label="From" value={dateFrom} disablePast onChange={setDateFrom} />
                <DateTimePickerMUI label="To" value={dateTo} disablePast minDate={dateFrom} onChange={setDateTo} />
              </div>
            </>
          )}
          {limitType === "total_count" && <NumberField className="mt-24" value={usage} setValue={setUsage} />}
          {limitType === "datetime_count" && (
            <>
              <div className="mt-24">
                <NumberField value={usage} setValue={setUsage} />
              </div>
              {accessType === "LINK_ACCESS" && (
                <p className="mt-24">Maximum valid time period for link access is 30 days.</p>
              )}
              <div className="mt-24">
                <DateTimePickerMUI className="mr-16" label="From" value={dateFrom} disablePast onChange={setDateFrom} />
                <DateTimePickerMUI label="To" value={dateTo} disablePast minDate={dateFrom} onChange={setDateTo} />
              </div>
            </>
          )}
        </FormControl>

        {!userSlug && (
          <>
            <p className="mb-24">
              We will send an email or SMS with an invitation link to the addresses or phones below
            </p>
            <div className="invite-users__row">
              <Autocomplete
                className="max-w-790"
                label="Email addresses or phones"
                disabled={!deviceSlug && !devices?.length}
                formData={data}
                revalidate={revalidate}
                options={usersOptions}
                onChange={handleUsers}
              />
            </div>
          </>
        )}

        <DefaultButton
          className="invite-users__send-btn"
          loading={buttonLoading}
          disabled={disabledButton || usersError}
          onClick={() => sendInvites(false)}
        >
          Send invite{!userSlug && "s"}
        </DefaultButton>
      </div>

      <OverwriteAccessDialog
        open={overwriteDialog}
        onCLose={closeOverwriteDialog}
        loading={buttonLoading}
        onConfirm={confirmOverwrite}
      />
      <AlreadyHasAccessDialog open={hasAccessDialog} onCLose={closeHasAccessDialog} />
      <InvitesSentDialog open={successDialog} onCLose={closeSuccessDialog} single={userSlug} />
    </div>
  );
};

export default InviteUsers;
