import React from "react";
import TableHead from "../../../Shared/TableHead";
import SkeletonMUI from "../../../Shared/SkeletonMUI";
import "./DevicesTable.scss";
import DevicesTableRow from "../DevicesTableRow/DevicesTableRow";

const DevicesTable = ({
  devices,
  accessType,
  onRemoteOpen,
  onTransfer,
  onRemove,
  firstLoad = true,
  sortParams,
  setSortParams
}) => {

  const transferDevice = (id, device_name) => () => {
    onTransfer(id, device_name);
  };

  const removeDevice = (id, device_name) => () => {
    onRemove(id, device_name);
  };

  const openDevice = (device, openSecondRelay = false) => () => {
    onRemoteOpen(device, openSecondRelay);
  };

  let sortList = [
    { value: "device_name", label: "Device", className: "w-20" },
    { value: "device_type__name", label: "Type", className: "w-20" },
    { value: "serial_number", label: "Serial number", className: "w-20" },
    { label: "Remote access", className: "w-30" },
    { className: "w-10" }
  ];

  return (
    <table className="devices-table table full-width">
      <TableHead {...{ sortList, sortParams, setSortParams }} />
      <tbody>
        { firstLoad ? [...Array(5)].map((_, i) =>
          <tr key={i}>
            <td className={sortList[0].className}>
              <SkeletonMUI className="devices-table__device_name" maxWidth="85px"></SkeletonMUI>
            </td>
            <td className={sortList[1].className}>
              <SkeletonMUI maxWidth="85px" />
            </td>
            <td className={sortList[2].className}>
              <SkeletonMUI maxWidth="105px" />
            </td>
            <td className={sortList[3].className}>
              <SkeletonMUI variant="rect" height={32} maxWidth="135px" />
            </td>
            <td className={sortList[sortList.length - 1].className}>
              <SkeletonMUI className="mr-8" variant="circle" width={32} height={32} />
            </td>
          </tr>
        ) : devices?.map((device) => {
          return (
            <DevicesTableRow 
              key={device.id}
              id={device.id}
              deviceName={device.device_name}
              accessType={accessType}
              type={device.type}
              serialNumber={device.serial_number}
              remoteAccess={device.remote_access}
              digitalKey={device.access.digital_key}
              online={device.online}
              closingTime={device.closing_time}
              hasExtensions={device.extensions_count > 0}
              onUnlock={openDevice(device)}
              onUnlockSecondRelay={openDevice(device, true)}
              onTransfer={transferDevice(device.id, device.device_name)}
              onRemove={removeDevice(device.id, device.device_name)}
            />
          );
        })}
      </tbody>
    </table>
  );
};

export default DevicesTable;
