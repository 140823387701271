import React, { useState } from "react";
import urls from "../../../core/urls";
import BackLink from "../../../Shared/BackLink";
import InputField from "../../../Shared/InputField";
import CheckboxMUI from "../../../Shared/CheckboxMUI";
import SelectComponent from "../../../Shared/SelectComponent";
import DefaultButton from "../../../Shared/Buttons/DefaultButton";
import { FormControlLabel } from "@material-ui/core";
import "./EditUserGroup.scss";
import UserGroupCard from "../UserGroupCard";

const EditUserGroup = () => {
  const [checkboxes, setCheckboxes] = useState({
    all_as_admins: true
  });
  const handleCheckboxes = event => {
    setCheckboxes({ ...checkboxes, [event.target.name]: event.target.checked });
  };

  const users = [{ value: "", label: "Anthony Jimenez, Edith Freeman, Louis Phelps, Billy Potte…" }];
  const userGroups = [{ id: 0, name: "test_group", devices: 1 }];

  return (
    <div className="edit-user-group page-wrap">
      <BackLink href={urls.usersUrl.path} text={urls.usersUrl.name} />
      <h1 className="page-title">
        {urls.editUserGroupUrl.name} <span className="edit-user-group__name">test_group</span>
      </h1>

      <div className="edit-user-group__section section-wrap">
        <div className="edit-user-group__row">
          <InputField className="min-w-506" label="Group name" defaultValue="test_group" />

          <div className="edit-user-group__types">
            <div className="type-item">
              <span className="type-item__label">Access type</span>
              <p className="type-item__text">Digital key & PIN-code</p>
            </div>
            <div className="type-item">
              <span className="type-item__label">Limit type</span>
              <p className="type-item__text">
                Date & time limit: <b>11.05.2021 13:05 – 11.06.2021 13:05</b>
              </p>
            </div>
          </div>
        </div>

        <div className="edit-user-group__row">
          <h2 className="subtitle mb-24">
            Users<span className="subtitle__number">5</span>
          </h2>
          <SelectComponent className="min-w-506" label="Users" placeholder="Users" value={users} options={users} />
          <FormControlLabel
            className="edit-user-group__label mt-24 w-100"
            label="Set all users as admins"
            control={
              <CheckboxMUI name="all_as_admins" checked={checkboxes.all_as_admins} onChange={handleCheckboxes} />
            }
          />
        </div>

        <div className="edit-user-group__row">
          <h2 className="subtitle mb-24">
            Have access to device groups
            {userGroups?.length > 0 && <span className="subtitle__number">{userGroups?.length}</span>}
          </h2>
          <div className="edit-user-group__grid">
            {userGroups.map(({ id, name, devices }) => (
              <UserGroupCard key={id} name={name} devices={devices} onRemove={() => console.log("Removed")} />
            ))}
            <button className="add-card-btn" type="button">
              <b>Click here</b> to give users access <br />
              to a device group
            </button>
          </div>
        </div>

        <DefaultButton className="edit-user-group__add-btn">Add group</DefaultButton>
      </div>
    </div>
  );
};

export default EditUserGroup;
