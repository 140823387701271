import React from "react";
import DialogMUI from "../../../Shared/DialogMUI";
import DefaultButton from "../../../Shared/Buttons/DefaultButton";
import "./Dialogs.scss";

const AlreadyHasAccessDialog = ({ open, onCLose }) => {
  return (
    <DialogMUI className="users-dialog t-center" open={open} onClose={onCLose}>
      <h2 className="mb-16">Error</h2>
      <p>The user already has this kind of access</p>
      <DefaultButton className="users-dialog__btn" onClick={onCLose}>
        Ok
      </DefaultButton>
    </DialogMUI>
  );
};

export default AlreadyHasAccessDialog;
