let BASE_URL, SECOND_URL, GOOGLE_MAPS;

const host = window.location.host;

if (host.includes("localhost")) {
  BASE_URL = process.env.REACT_APP_API_URL;
  GOOGLE_MAPS = "AIzaSyBUqaXkpelN9fSXAczJwlFDS5GpbID1aVM";
} else if (host.includes("ble.local")) {
  BASE_URL = "http://api.ble.local:8400/api/web/v1/";
  GOOGLE_MAPS = "AIzaSyBUqaXkpelN9fSXAczJwlFDS5GpbID1aVM";
} else {
  BASE_URL = process.env.REACT_APP_API_URL;
  GOOGLE_MAPS = "AIzaSyBUqaXkpelN9fSXAczJwlFDS5GpbID1aVM";
}

SECOND_URL = "";

export const API_BASE_URL = BASE_URL;
export const API_SECOND_URL = SECOND_URL;
export const GOOGLE_MAPS_KEY = GOOGLE_MAPS;
