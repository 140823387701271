import React, { useState } from "react";
import PropTypes from "prop-types";
import { InputAdornment, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ErrorIcon from "@material-ui/icons/Error";
import { ReactComponent as EyeOpenIcon } from "../../assets/images/eye-open.svg";
import { ReactComponent as EyeCloseIcon } from "../../assets/images/eye-close.svg";
import { ReactComponent as CaretDownIcon } from "../../assets/images/caret-down.svg";
import TooltipMessage from "../TooltipMessage";
import "./styles.scss";

const useStyles = makeStyles(() => ({
  menuPaper: {
    marginTop: 8,
    maxWidth: 348,
    width: "100%",
    maxHeight: 300
  }
}));

const propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  endAdornment: PropTypes.node,
  readOnly: PropTypes.bool
};

const InputField = ({
  id,
  type,
  label,
  autoFocus,
  disabled = false,
  multiline,
  error,
  endAdornment = false,
  typePassword,
  readOnly,
  shrink,
  inputProps,
  ...props
}) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const togglePassword = () => setShowPassword(prevState => !prevState);

  return (
    <TextField
      {...props}
      id={id}
      type={typePassword && !showPassword ? "password" : type}
      label={label}
      variant="filled"
      disabled={disabled}
      error={!!error}
      autoFocus={autoFocus}
      multiline={multiline}
      classes={{
        root: `text-field-root${type === "search" ? " text-field-root--search" : ""}`
      }}
      InputLabelProps={{
        ...props.InputLabelProps,
        classes: {
          root: "input-label",
          focused: "input-label--focused",
          shrink: "input-label--shrink",
          error: "input-label--error",
          disabled: "input-label--disabled"
        },
        shrink
      }}
      InputProps={{
        ...props.InputProps,
        classes: {
          root: "input-field",
          focused: "input-field--focused",
          error: "input-field--error",
          multiline: "input-field--multiline",
          disabled: "input-field--disabled",
          input: "input-field__input"
        },
        disableUnderline: true,
        endAdornment: props?.InputProps?.endAdornment ? (
          props?.InputProps?.endAdornment
        ) : (
          <InputAdornment className="input-adorned-end" position="end">
            {typePassword && (
              <button className="password-btn good-hover" type="button" onClick={togglePassword}>
                {!showPassword ? <EyeOpenIcon /> : <EyeCloseIcon />}
              </button>
            )}

            {error ? (
              <TooltipMessage text={error} delay={200} error>
                <ErrorIcon className="error-icon" />
              </TooltipMessage>
            ) : endAdornment ? (
              endAdornment
            ) : (
              ""
            )}
          </InputAdornment>
        ),
        readOnly: readOnly
      }}
      inputProps={inputProps}
      FormHelperTextProps={{
        ...props.FormHelperTextProps,
        classes: {
          root: "input-helper-text"
        }
      }}
      SelectProps={{
        ...props.SelectProps,
        classes: {
          root: "input-select",
          icon: `input-select__icon${error ? " input-select__icon--error" : ""}`
        },
        IconComponent: CaretDownIcon,
        MenuProps: {
          classes: { paper: classes.menuPaper },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          getContentAnchorEl: null
        }
      }}
    />
  );
};

InputField.propTypes = propTypes;

export default InputField;
