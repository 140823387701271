import React, { useState } from "react";
import urls from "../../../core/urls";
import BackLink from "../../../Shared/BackLink";
import InputField from "../../../Shared/InputField";
import CheckboxMUI from "../../../Shared/CheckboxMUI";
import RadioMUI from "../../../Shared/RadioMUI";
import SelectComponent from "../../../Shared/SelectComponent";
import DefaultButton from "../../../Shared/Buttons/DefaultButton";
import { FormControl, FormControlLabel, FormGroup, FormLabel, RadioGroup } from "@material-ui/core";
import "./AddUserGroup.scss";

const AddUserGroup = () => {
  const [checkboxes, setCheckboxes] = useState({
    digital_key: false,
    pin_code: false,
    all_as_admins: false
  });
  const handleCheckboxes = event => {
    setCheckboxes({ ...checkboxes, [event.target.name]: event.target.checked });
  };

  const limitTypeList = [
    { value: "full_access", label: "Full access" },
    { value: "date_time_limit", label: "Date & time limit" },
    { value: "number_of_usages", label: "Number of usages" }
  ];
  const [limitType, setLimitType] = useState("full_access");
  const handleLimitType = event => setLimitType(event.target.value);

  const users = [{ value: "", label: "" }];

  return (
    <div className="add-user-group page-wrap">
      <BackLink href={urls.usersUrl.path} text={urls.usersUrl.name} />
      <h1 className="page-title">{urls.addUserGroupUrl.name}</h1>

      <div className="add-user-group__section section-wrap">
        <div className="add-user-group__row">
          <InputField className="min-w-506" label="Group name" />

          <FormControl className="add-user-group__fieldset" component="fieldset">
            <FormLabel className="add-user-group__legend" component="legend">
              Choose access type
            </FormLabel>
            <FormGroup className="add-user-group__form-group">
              <FormControlLabel
                className="add-user-group__label"
                control={
                  <CheckboxMUI name="digital_key" checked={checkboxes.digital_key} onChange={handleCheckboxes} />
                }
                label="Digital key"
              />
              <FormControlLabel
                className="add-user-group__label"
                control={<CheckboxMUI name="pin_code" checked={checkboxes.pin_code} onChange={handleCheckboxes} />}
                label="PIN-code"
              />
            </FormGroup>
          </FormControl>

          <FormControl className="add-user-group__fieldset" component="fieldset">
            <FormLabel className="add-user-group__legend" component="legend">
              Choose limit type
            </FormLabel>
            <RadioGroup className="add-user-group__form-group" value={limitType} onChange={handleLimitType}>
              {limitTypeList.map(({ value, label }) => (
                <FormControlLabel
                  key={value}
                  className="add-user-group__label"
                  value={value}
                  control={<RadioMUI />}
                  label={label}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </div>

        <div className="add-user-group__row">
          <h2 className="subtitle mb-24">Users</h2>
          <SelectComponent className="min-w-506" placeholder="Users" options={users} />
          <FormControlLabel
            className="add-user-group__label mt-24 w-100"
            control={
              <CheckboxMUI name="all_as_admins" checked={checkboxes.all_as_admins} onChange={handleCheckboxes} />
            }
            label="Set all users as admins"
          />
        </div>

        <div className="add-user-group__row">
          <h2 className="subtitle mb-24">Have access to device groups</h2>
          <div className="add-user-group__grid">
            <button className="add-card-btn" type="button">
              <b>Click here</b> to give users access <br />
              to a device group
            </button>
          </div>
        </div>

        <DefaultButton className="add-user-group__add-btn">Add group</DefaultButton>
      </div>
    </div>
  );
};

export default AddUserGroup;
