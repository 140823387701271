import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createFilterOptions } from "@material-ui/lab";
import { getTransferOptions, postTransferOwnership } from "../devicesActions";
import { onTabClick } from "../../../core/helpers/functions";
import DialogMUI from "../../../Shared/DialogMUI";
import { TabItem, Tabs } from "../../../Shared/Tabs";
import AutocompleteMUI from "../../../Shared/AutocompleteMUI";
import InputField from "../../../Shared/InputField";
import Avatar from "../../../Shared/Avatar";
import DefaultButton from "../../../Shared/Buttons/DefaultButton";
import notifySuccess from "../../../core/helpers/notifySuccess";
import { toastErrors } from "../../../core/helpers/toastErrors";
import "./Dialogs.scss";

const TransferOwnershipDialog = ({ open, onCLose, deviceId, deviceName, onSuccess }) => {
  const dispatch = useDispatch();
  const { buttonLoading, optionsLoading } = useSelector(({ devices }) => devices);

  const tabs = [
    {
      index: "user",
      label: "Specific user",
      text: "Select the user to whom you want to transfer ownership of",
      inputLabel: "User name or email"
    },
    {
      index: "company",
      label: "Company",
      text: "Select the company to which you wish to transfer ownership of",
      inputLabel: "Company name"
    }
  ];

  const [currentTab, setCurrentTab] = useState(tabs[0].index);

  const [options, setOptions] = useState([]);
  const getOptions = async () => {
    let res;
    if (currentTab === tabs[0].index) res = await dispatch(getTransferOptions(deviceId));
    if (currentTab === tabs[1].index) res = await dispatch(getTransferOptions(deviceId, "companies"));
    setOptions(res?.payload?.data || []);
  };

  const filterOptions = createFilterOptions({
    stringify: option => option.first_name + option.last_name + option.email + option.account_name
  });

  const [newOwnerId, setNewOwnerId] = useState(null);
  const [password, setPassword] = useState("");
  const handlePassword = event => setPassword(event.target.value);

  const closeDialog = () => {
    onCLose();
    setCurrentTab(tabs[0].index);
  };

  const sendData = async () => {
    const res = await dispatch(postTransferOwnership(deviceId, password, newOwnerId, currentTab));
    if (res?.payload) {
      notifySuccess("Device ownership successfully transferred");
      closeDialog();
      onSuccess();
    }
    if (res?.error) toastErrors(res);
  };

  return (
    <DialogMUI className="transfer-ownership-dialog" open={open} onClose={closeDialog}>
      <h2 className="mb-32">Transfer ownership to...</h2>

      <Tabs defaultIndex={tabs[0].index} className="tabs--small" onTabClick={onTabClick(setCurrentTab)}>
        {tabs.map(({ index, label, text, inputLabel }) => (
          <TabItem key={index} index={index} label={label}>
            <p className="mb-16">
              {text} {deviceName && <b>{deviceName}</b>}
            </p>

            <AutocompleteMUI
              className="mb-32"
              options={options}
              filterOptions={filterOptions}
              getOptionLabel={option =>
                currentTab === tabs[0].index ? `${option?.first_name} ${option?.last_name}` : option?.account_name
              }
              renderOption={option =>
                currentTab === tabs[0].index ? <UserOption {...option} /> : option?.account_name
              }
              renderInput={params => (
                <InputField {...params} label={inputLabel} inputProps={{ ...params.inputProps }} />
              )}
              loading={optionsLoading}
              onOpen={getOptions}
              onClose={() => setOptions([])}
              onChange={(event, value) => setNewOwnerId(value?.id)}
            />
          </TabItem>
        ))}
      </Tabs>

      <p className="mb-16">Confirm your choice with the password</p>
      <InputField label="Your password" fullWidth typePassword autoComplete="new-password" onChange={handlePassword} />

      <div className="btn-group">
        <DefaultButton className="gray-btn" disabled={buttonLoading} onClick={closeDialog}>
          Cancel
        </DefaultButton>
        <DefaultButton disabled={!newOwnerId || !password} loading={buttonLoading} onClick={sendData}>
          Confirm
        </DefaultButton>
      </div>
    </DialogMUI>
  );
};

function UserOption(props) {
  return (
    <div className="user-option">
      <Avatar
        className="user-option__avatar"
        firstName={props?.first_name}
        lastName={props?.last_name}
        size={40}
        src={props?.photo}
      />
      <div className="user-option__text-wrap">
        <span className="user-option__title">
          {props?.first_name} {props?.last_name}
        </span>
        <span className="user-option__subtitle">
          {props?.phone_number}
          {props?.phone_number && props?.email && " • "}
          {props?.email}
        </span>
      </div>
    </div>
  );
}

export default TransferOwnershipDialog;
