import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClickAwayListener } from "@material-ui/core";
import { getValidateValue, postValidateEmailsPhones } from "../../Features/Users/usersActions";
import { isEmail, isPhoneNumber, onChangeSearch, useDebounce } from "../../core/helpers/functions";
import AutocompleteChip from "./AutocompleteChip";
import AutocompleteItem from "./AutocompleteItem";
import InviteItem from "./InviteItem";
import "./Autocomplete.scss";
import notifyError from "../../core/helpers/notifyError";

const Autocomplete = ({ className, label, value = [], formData, revalidate, options = [], disabled, onChange }) => {
  const dispatch = useDispatch();
  const { validationLoading, isValid, validatedData } = useSelector(({ users }) => users);

  const rootClasses = ["autocomplete"];
  if (className) rootClasses.push(className);
  if (disabled) rootClasses.push("autocomplete--disabled");

  const [open, setOpen] = useState(false);
  const openDropdown = () => {
    if (selected?.length < 100) {
      focusSearch();
      setOpen(true);
    }
  };
  const closeDropdown = () => {
    setOpen(false);
    setSearch("");
  };

  const searchRef = useRef(null);
  const focusSearch = () => searchRef.current.focus();

  const [state, setState] = useState([]);
  useEffect(() => setState(options), [options]);

  const [selected, setSelected] = useState(value);
  const selectItem = data => () => {
    if (selected?.length < 100) {
      if (typeof onChange === "function") onChange([...selected, data]);
      setSelected([...selected, data]);
      setState(state.filter(item => item.id !== data.id));
      focusSearch();
    }
  };

  const removeItem = data => () => {
    const removedData = selected.filter(item => item.id !== data.id);
    if (typeof onChange === "function") onChange(removedData);
    setSelected(removedData);

    if (!data.newItem) {
      const sortedState = [...state, data].sort((a, b) => {
        if (a?.first_name < b?.first_name) return -1;
        if (a?.first_name > b?.first_name) return 1;
        return 0;
      });
      setState(sortedState);
    }
  };

  const selectNewItem = key => () => {
    if (selected?.length < 100) {
      const newData = [...selected, { id: search, [key]: search, newItem: true }];
      if (typeof onChange === "function") onChange(newData);
      setSelected(newData);
      setSearch("");
      focusSearch();
    }
  };

  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search);
  let filteredOptions = state?.filter(item => {
    return (
      item.email?.toLowerCase().includes(search.toLowerCase()) ||
      item.phone_number?.toLowerCase().includes(search.toLowerCase())
    );
  });

  useEffect(() => {
    if (search.length > 0 && !filteredOptions?.length && !search?.match(/\s+/g)) {
      dispatch(getValidateValue(search));
    }
  }, [debouncedSearch]);

  const uniqueValueInObject = (object, el) => {
    return object?.findIndex(item => Object?.values(item)?.find(value => value === el)) === -1;
  };
  const uniqueValueInArray = (array, el, index) => array?.indexOf(el) === index;

  const onPaste = event => {
    const value = event?.clipboardData?.getData("text")?.toLowerCase();
    event.preventDefault();

    if (value?.match(/\s+/g)) {
      const valueArray = value && value?.replaceAll(",", "")?.split(/\s+/g);
      // ?.slice(0, 100 - selected?.length);

      // if (valueArray?.length > 0 && valueArray?.length <= 100) {
      if (valueArray?.length > 0) {
        const filteredArray = valueArray
          ?.filter(
            (el, index) =>
              el &&
              uniqueValueInArray(valueArray, el, index) &&
              uniqueValueInObject(selected, el) &&
              (isEmail(el) || isPhoneNumber(el))
          )
          ?.map(el => ({
            id: el,
            [el?.includes("@") ? "email" : "phone_number"]: el,
            newItem: true
          }));

        if (filteredArray?.length > 0) {
          const newSelected = [...selected, ...filteredArray];

          if (newSelected?.length > 0) {
            if (typeof onChange === "function") onChange(newSelected);
            setSelected(newSelected);
          }

          if (newSelected?.length > 100) {
            notifyError("Sorry, you can’t add more than 100 contacts at once");
          }
        }
      }
    } else {
      setSearch(value);
    }
  };

  const setErrors = prev => {
    validatedData?.forEach((el, idx) => {
      el?.error ? (prev[idx].error = el?.error) : (prev[idx].error = null);
      el?.warning ? (prev[idx].warning = el?.warning) : (prev[idx].warning = null);
    });
    return prev;
  };

  const setValidatedData = () => {
    if (Array.isArray(validatedData) && validatedData?.length === selected?.length) {
      if (typeof onChange === "function") onChange(setErrors(selected));
      setSelected(setErrors(selected));
    }
  };

  useEffect(() => {
    setValidatedData();
  }, [validatedData]);

  const validateData = () => {
    const data = formData;
    formData.users = selected;
    if (data?.users?.length > 0) {
      dispatch(postValidateEmailsPhones(data));
    }
  };

  useEffect(() => {
    if ((selected.length > 0 && selected.length <= 100) || (revalidate && selected.length <= 100)) {
      validateData();
    }
  }, [selected, revalidate]);

  return (
    <ClickAwayListener onClickAway={closeDropdown}>
      <div className={`${rootClasses.join(" ")}`}>
        <div className="autocomplete-field" onClick={openDropdown}>
          <div className={`autocomplete-field__label${open || selected.length ? " shrink" : ""}`}>
            {label && label}
            {validationLoading && (
              <div className="autocomplete-loader">
                <div />
                <div />
                <div />
              </div>
            )}
          </div>

          <div className="autocomplete-field__container">
            {selected?.map(data => (
              <AutocompleteChip key={data?.id} data={data} onRemoveClick={removeItem(data)} />
            ))}

            {selected?.length < 100 && (
              <input
                className="autocomplete-field__input"
                type="search"
                placeholder="Start typing…"
                ref={searchRef}
                value={search}
                onFocus={() => setOpen(true)}
                onChange={onChangeSearch(setSearch)}
                onPaste={onPaste}
              />
            )}
          </div>
        </div>

        {open && !search?.match(/\s+/g) && selected?.length < 100 && (
          <div className="autocomplete-dropdown">
            <div className="autocomplete-dropdown__list">
              {filteredOptions?.map(data => {
                if (!data?.email && !data?.phone_number) return (filteredOptions = []);
                return <AutocompleteItem key={data?.id} data={data} onClick={selectItem(data)} />;
              })}

              {!filteredOptions?.length &&
                isValid &&
                search &&
                search.includes("@") &&
                selected.findIndex(item => item.id === search) === -1 && (
                  <InviteItem title={search} onClick={selectNewItem("email")} />
                )}

              {!filteredOptions?.length &&
                isValid &&
                search &&
                !search.includes("@") &&
                selected.findIndex(item => item.id === search) === -1 && (
                  <InviteItem title={search} type="phone" onClick={selectNewItem("phone_number")} />
                )}

              {((!filteredOptions?.length && !isValid) ||
                (!filteredOptions?.length && isValid && !search) ||
                selected.findIndex(item => item.id === search) >= 0) && <InviteItem title={search} type="error" />}
            </div>
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default Autocomplete;
