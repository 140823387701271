import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "./usersActions";
import { addQueryFilter, handlePage, onChangeSearch, onTabClick } from "../../core/helpers/functions";
import urls from "../../core/urls";
import InputField from "../../Shared/InputField";
import DefaultButton from "../../Shared/Buttons/DefaultButton";
import Pagination from "../../Shared/Pagination";
import NotFound from "../../Shared/NotFound";
import { TabItem, Tabs } from "../../Shared/Tabs";
import UsersTable from "./UsersTable";
import { ReactComponent as AddIcon } from "../../assets/images/add-white.svg";
import "./Users.scss";

const Users = () => {
  const dispatch = useDispatch();
  const { activeUsers, deviceAdmins, invitedUsers } = useSelector(({ users }) => users);

  const [firstLoad, setFirstLoad] = useState(true);
  const [search, setSearch] = useState("");
  const [currentTab, setCurrentTab] = useState(0);
  const [sortParams, setSortParams] = useState({ sort: "", order: "" });
  const [page, setPage] = useState(0);

  const sortList = [
    { value: "name_search", label: "User", className: "w-20" },
    { value: "email", label: "Email", className: "w-32" },
    { value: "phone_number", label: "Phone number", className: "w-24" },
    {
      value: "devices_count",
      label: currentTab === 0 ? "User in" : currentTab === 1 ? "Device" : "Invited to",
      className: "w-24"
    }
  ];

  const filters = {
    page: page + 1,
    search,
    ordering: sortParams.order + sortParams.sort
  };

  const tabs = [
    {
      label: "Users",
      count: activeUsers?.count,
      users: firstLoad ? [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }] : activeUsers?.results,
      pages: activeUsers?.pages,
      type: "users"
    },
    {
      label: "Device admins",
      count: deviceAdmins?.count,
      users: deviceAdmins?.results,
      pages: deviceAdmins?.pages,
      type: "admins"
    },
    {
      label: "Invited",
      count: invitedUsers?.count,
      users: invitedUsers?.results,
      pages: invitedUsers?.pages,
      type: "invited"
    }
  ];

  const getUsersData = () => {
    if (firstLoad) {
      dispatch(addQueryFilter(getUsers(), filters)).then(res => res?.payload?.status && setFirstLoad(false));
      dispatch(addQueryFilter(getUsers("admins"), filters));
      dispatch(addQueryFilter(getUsers("invited"), filters));
    } else {
      if (currentTab === 0) {
        dispatch(addQueryFilter(getUsers(), filters));
      }
      if (currentTab === 1) {
        dispatch(addQueryFilter(getUsers("admins"), filters));
      }
      if (currentTab === 2) {
        dispatch(addQueryFilter(getUsers("invited"), filters));
      }
    }
  };

  useEffect(() => {
    getUsersData();
  }, [search, sortParams, page, currentTab]);

  return (
    <div className="users page-wrap">
      <h1 className="page-title">{urls.usersUrl.name}</h1>
      <div className="users__section section-wrap">
        <header className="users__header">
          <InputField
            className="min-w-276"
            label="Search users"
            type="search"
            onChange={onChangeSearch(setSearch, 3, setPage)}
          />
          <DefaultButton className="invite-btn" type="link" to={urls.inviteUsersUrl.path}>
            <AddIcon className="add-icon" />
            <span>Invite users</span>
          </DefaultButton>
        </header>

        <Tabs className="mt-48" onTabClick={onTabClick(setCurrentTab, setPage)}>
          {tabs?.map(({ label, count, users, pages, type }, index) => (
            <TabItem key={index} index={index} label={label} counter={count}>
              {users?.length ? (
                <>
                  <UsersTable {...{ users, type, sortList, sortParams, setSortParams, firstLoad }} />
                  {pages > 1 && (
                    <Pagination
                      className="users__pagination"
                      active={page}
                      onChange={handlePage(setPage)}
                      pageCount={pages}
                    />
                  )}
                </>
              ) : (
                <NotFound noData small title="No users" />
              )}
            </TabItem>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default Users;
