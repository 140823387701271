import React from "react";
import PropTypes from "prop-types";
import { Image } from "cloudinary-react";
import { ReactComponent as UserIcon } from "../../assets/images/user.svg";
import "./Avatar.scss";

const Avatar = ({ children, className, firstName, fontSize, lastName, size = 40, src, userIcon = false }) => {
  const classes = ["avatar-component"];
  const wrapStyle = { width: `${size}px`, height: `${size}px`, fontSize: fontSize };
  const alt = `${firstName ? firstName : ""}${lastName ? ` ${lastName}` : ""}`;
  if (className) classes.push(className);

  let content;
  if (userIcon) {
    content = <span className="avatar-component__inner"><UserIcon /></span>;
  } else if (src) {
    content = <Image
      className="avatar-component__inner"
      publicId={src.split("/").pop()}
      cloudName="blelocking"
      width={size}
      height={size}
      crop="thumb"
      alt={alt}
    />;
  } else {
    content = <span className="avatar-component__inner">
      {firstName && firstName.charAt(0).toUpperCase()}
      {lastName && lastName.charAt(0).toUpperCase()}
    </span>;
  }

  return (
    <span className={classes.join(" ")} style={wrapStyle}>
      {content}
      {children}
    </span>
  );
};

Avatar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  firstName: PropTypes.string,
  fontSize: PropTypes.number,
  lastName: PropTypes.string,
  size: PropTypes.number,
  src: PropTypes.string,
  userIcon: PropTypes.bool
};

export default Avatar;
