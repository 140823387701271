export const DEVICES = {
  GET_DEVICES_OWNER: "GET_DEVICES_OWNER",
  GET_DEVICES_OWNER_SUCCESS: "GET_DEVICES_OWNER_SUCCESS",
  GET_DEVICES_OWNER_FAIL: "GET_DEVICES_OWNER_FAIL",

  GET_DEVICES_ADMIN: "GET_DEVICES_ADMIN",
  GET_DEVICES_ADMIN_SUCCESS: "GET_DEVICES_ADMIN_SUCCESS",
  GET_DEVICES_ADMIN_FAIL: "GET_DEVICES_ADMIN_FAIL",

  GET_DEVICES_USER: "GET_DEVICES_USER",
  GET_DEVICES_USER_SUCCESS: "GET_DEVICES_USER_SUCCESS",
  GET_DEVICES_USER_FAIL: "GET_DEVICES_USER_FAIL",

  GET_OPTIONS: "GET_OPTIONS",
  GET_OPTIONS_SUCCESS: "GET_OPTIONS_SUCCESS",
  GET_OPTIONS_FAIL: "GET_OPTIONS_FAIL",

  GET_DEVICE: "GET_DEVICE",
  GET_DEVICE_SUCCESS: "GET_DEVICE_SUCCESS",
  GET_DEVICE_FAIL: "GET_DEVICE_FAIL",

  PATCH_DEVICE: "PATCH_DEVICE",
  PATCH_DEVICE_SUCCESS: "PATCH_DEVICE_SUCCESS",
  PATCH_DEVICE_FAIL: "PATCH_DEVICE_FAIL",

  GET_DEVICE_NAME: "GET_DEVICE_NAME",
  GET_DEVICE_NAME_SUCCESS: "GET_DEVICE_NAME_SUCCESS",
  GET_DEVICE_NAME_FAIL: "GET_DEVICE_NAME_FAIL",

  GET_DEVICE_USERS_LIST: "GET_DEVICE_USERS_LIST",
  GET_DEVICE_USERS_LIST_SUCCESS: "GET_DEVICE_USERS_LIST_SUCCESS",
  GET_DEVICE_USERS_LIST_FAIL: "GET_DEVICE_USERS_LIST_FAIL",

  DELETE_USER: "DELETE_USER",
  DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
  DELETE_USER_FAIL: "DELETE_USER_FAIL",

  GET_DEVICE_INVITED_USERS: "GET_DEVICE_INVITED_USERS",
  GET_DEVICE_INVITED_USERS_SUCCESS: "GET_DEVICE_INVITED_USERS_SUCCESS",
  GET_DEVICE_INVITED_USERS_FAIL: "GET_DEVICE_INVITED_USERS_FAIL",

  DELETE_INVITATION: "DELETE_INVITATION",
  DELETE_INVITATION_SUCCESS: "DELETE_INVITATION_SUCCESS",
  DELETE_INVITATION_FAIL: "DELETE_INVITATION_FAIL",

  DELETE_DEVICE: "DELETE_DEVICE",
  DELETE_DEVICE_SUCCESS: "DELETE_DEVICE_SUCCESS",
  DELETE_DEVICE_FAIL: "DELETE_DEVICE_FAIL",

  GET_TRANSFER_OPTIONS: "GET_TRANSFER_OPTIONS",
  GET_TRANSFER_OPTIONS_SUCCESS: "GET_TRANSFER_OPTIONS_SUCCESS",
  GET_TRANSFER_OPTIONS_FAIL: "GET_TRANSFER_OPTIONS_FAIL",

  POST_TRANSFER_OWNERSHIP: "POST_TRANSFER_OWNERSHIP",
  POST_TRANSFER_OWNERSHIP_SUCCESS: "POST_TRANSFER_OWNERSHIP_SUCCESS",
  POST_TRANSFER_OWNERSHIP_FAIL: "POST_TRANSFER_OWNERSHIP_FAIL",

  POST_CHANGE_ROLE: "POST_CHANGE_ROLE",
  POST_CHANGE_ROLE_SUCCESS: "POST_CHANGE_ROLE_SUCCESS",
  POST_CHANGE_ROLE_FAIL: "POST_CHANGE_ROLE_FAIL",

  MQTT_OPEN_LOCK: "MQTT_OPEN_LOCK",
  MQTT_OPEN_LOCK_SUCCESS: "MQTT_OPEN_LOCK_SUCCESS",
  MQTT_OPEN_LOCK_FAIL: "MQTT_OPEN_LOCK_FAIL",

  POST_HISTORY: "POST_HISTORY",
  POST_HISTORY_SUCCESS: "POST_HISTORY_SUCCESS",
  POST_HISTORY_FAIL: "POST_HISTORY_FAIL"
};
