import { MQTT } from "./actionTypes";

export const mqttConnect = (userId, deviceSerialNumber, userToken) => ({ type: MQTT.MQTT_CONNECT, userId, deviceSerialNumber, userToken });
export const mqttSendCommand = (command) => ({ type: MQTT.MQTT_COMMAND, command });
export const mqttConnecting = host => ({ type: MQTT.MQTT_CONNECTING, host });
export const mqttConnected = () => ({ type: MQTT.MQTT_CONNECTED });
export const mqttFailed = () => ({ type: MQTT.MQTT_FAILED });
export const mqttMessage = (message) => ({ type: MQTT.MQTT_MESSAGE, message });
export const mqttDisconnect = () => ({ type: MQTT.MQTT_DISCONNECT });
export const mqttDisconnected = () => ({ type: MQTT.MQTT_DISCONNECTED });
