import React, { useMemo } from "react";
import { useHistory } from "react-router";
import { useForm, Controller } from "react-hook-form";
import DefaultButton from "../../../Shared/Buttons/DefaultButton";
import { useLocation } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { connect } from "react-redux";

// import { ReactComponent as SuccessIcon } from "../../../assets/icons/success.svg";
import InputField from "../../../Shared/InputField";
import { postRegister } from "./../actions";

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const AccountDetails = ({ withNumber, postRegister }) => {
    const history = useHistory();

    let query = useQuery();

    const schema = useMemo(
        () =>
            yup.object().shape({
                password: yup
                    .string()
                    .min(8)
                    .required(),
                first_name: yup.string().required(),
                last_name: yup.string().required()
            }),
        []
    );

    const onSubmit = data => {
        const obj = {
            verification_token: query.get("token"),
            password: data.password,
            first_name: data.first_name,
            last_name: data.last_name
        };

        if (withNumber) {
            obj.phone_number = `+${query.get("phone")}`;
        } else {
            obj.email = query.get("email");
        }

        postRegister(obj).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                history.push("/auth/sign-in");
            } else {
                if (
                    res.error.response &&
                    res.error.response.data &&
                    res.error.response.data.password &&
                    res.error.response.data.password.length > 0 &&
                    res.error.response.data.password[0].message
                ) {
                    setError("password", {
                        type: "server",
                        message: res.error.response.data.password[0].message
                    });
                }
            }
        });
    };

    const {
        control,
        handleSubmit,
        setError,
        formState: { errors }
    } = useForm({
        mode: "onTouched",
        reValidateMode: "onChange",
        resolver: yupResolver(schema),
        shouldFocusError: true
    });

    return (
        <div className="auth-box">
            <h1 className="auth-box__title">Account details</h1>

            <form className="auth-box__wrap" onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    name="first_name"
                    control={control}
                    render={({ field }) => (
                        <InputField
                            id="first_name"
                            className="mb-24"
                            type="text"
                            label="First name"
                            error={errors.first_name?.message}
                            {...field}
                        />
                    )}
                />
                <Controller
                    name="last_name"
                    control={control}
                    render={({ field }) => (
                        <InputField
                            id="last_name"
                            className="mb-24"
                            type="text"
                            label="Last name"
                            error={errors.last_name?.message}
                            {...field}
                        />
                    )}
                />
                {withNumber ? (
                    <InputField
                        id="phone_number"
                        className="mb-24"
                        type="tel"
                        label="Phone number"
                        value={`+${query.get("phone")}`}
                        helperText="This number is also your login ID and cannot be changed."
                        disabled
                    />
                ) : (
                    <InputField
                        id="email"
                        className="mb-24"
                        type="email"
                        label="Email"
                        value={`${query.get("email")}`}
                        helperText="This email address is also your login ID and cannot be changed."
                        disabled
                    />
                )}

                <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                        <InputField
                            id="password"
                            label="Password"
                            typePassword
                            error={errors.password?.message}
                            {...field}
                        />
                    )}
                />

                {/* <div className="auth-box__msg mt-8">
                    <SuccessIcon />
                    <p>
                        <b>Password strength: </b>good
                    </p>
                </div> */}

                <DefaultButton className="mt-48 mx-auto" type="submit">
                    Continue
                </DefaultButton>
            </form>
        </div>
    );
};

const mapDispatchToProps = { postRegister };

export default connect(null, mapDispatchToProps)(AccountDetails);
