export const POST_LOGIN = "POST_LOGIN";
export const POST_LOGIN_FAIL = "POST_LOGIN_FAIL";
export const POST_LOGIN_SUCCESS = "POST_LOGIN_SUCCESS";

export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";

export const SEND_CODE = "SEND_CODE";
export const SEND_CODE_EMAIL = "SEND_CODE_EMAIL";

export const VERIFY_CODE = "VERIFY_CODE";
export const VERIFY_CODE_EMAIL = "VERIFY_CODE_EMAIL";

export const POST_REGISTER = "POST_REGISTER";

export const RESTORE_SEND_CODE = "RESTORE_SEND_CODE";
export const RESTORE_VERIFY_CODE = "RESTORE_VERIFY_CODE";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";

export const GET_IDP = "GET_IDP";
export const GET_IDP_FAIL = "GET_IDP_FAIL";
export const GET_IDP_SUCCESS = "GET_IDP_SUCCESS";
