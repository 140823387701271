import { USERS } from "./usersActionTypes";

export const getUsers = (access = "users") => (query = "") => {
  let type = USERS.GET_ACTIVE_USERS;
  if (access === "admins") type = USERS.GET_DEVICE_ADMINS;
  if (access === "invited") type = USERS.GET_INVITED_USERS;

  return {
    type,
    payload: {
      request: {
        url: `/my/${access}/${query}`
      }
    }
  };
};

export function getUserInfo(userId) {
  return {
    type: USERS.GET_USER_INFO,
    payload: {
      request: {
        url: `/my/users/${userId}/`
      }
    }
  };
}

export function setUserInfo(user) {
  return {
    type: USERS.SET_USER_INFO,
    payload: user
  };
}

export function getUserDevices(userId, page) {
  return {
    type: USERS.GET_USER_DEVICES,
    payload: {
      request: {
        url: `/my/users/${userId}/devices/?page=${page}`
      }
    }
  };
}

export function deleteDevice(userId, deviceId) {
  return {
    type: USERS.DELETE_DEVICE,
    payload: {
      request: {
        url: `/my/devices/${deviceId}/users/${userId}/`,
        method: "delete"
      }
    }
  };
}

export function getShareOptions() {
  return {
    type: USERS.GET_SHARE_OPTIONS,
    payload: {
      request: {
        url: "/share/options/"
      }
    }
  };
}

export function getDeviceUsers(devices = []) {
  return {
    type: USERS.GET_DEVICE_USERS,
    payload: {
      request: {
        url: `/share/options/users/?devices=${devices?.join(",")}`
      }
    }
  };
}

export function getValidateValue(value) {
  return {
    type: USERS.GET_VALIDATE_VALUE,
    payload: {
      request: {
        url: `/validate/email-phone/?value=${encodeURIComponent(value)}`
      }
    }
  };
}

export function postValidateEmailsPhones(data) {
  return {
    type: USERS.POST_VALIDATE_EMAILS_PHONES,
    payload: {
      request: {
        url: "/validate/emails-phones/",
        method: "post",
        data
      }
    }
  };
}

export function postInviteUsers(data, overwrite = false) {
  return {
    type: USERS.POST_INVITE_USERS,
    payload: {
      request: {
        url: `/share/${overwrite ? "?overwrite=1" : ""}`,
        method: "post",
        data
      }
    }
  };
}
