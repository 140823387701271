import React from "react";
import DialogMUI from "../../../Shared/DialogMUI";
import DefaultButton from "../../../Shared/Buttons/DefaultButton";

const RemoveUserAccessDialog = ({ open, onCLose, firstName, lastName, deviceName, error, onConfirm }) => {
  return (
    <DialogMUI className="users-dialog" open={open} onClose={onCLose}>
      <h2 className="mb-32">Remove access</h2>
      <p className="mb-24">
        Are you sure you want to remove access to <b>{deviceName}</b> for{" "}
        <b>
          {firstName && firstName}
          {lastName && ` ${lastName}`}
        </b>
        ?
      </p>

      <div className="btn-group">
        <DefaultButton className="gray-btn" onClick={onCLose}>
          Cancel
        </DefaultButton>
        <DefaultButton onClick={onConfirm}>Remove</DefaultButton>
      </div>
      {error && <p className="mt-8 t-center alert">Access deletion error!</p>}
    </DialogMUI>
  );
};

export default RemoveUserAccessDialog;
