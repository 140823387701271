import React from "react";
import DialogMUI from "../../../Shared/DialogMUI";
import DefaultButton from "../../../Shared/Buttons/DefaultButton";
import "./Dialogs.scss";

const InvitesSentDialog = ({ open, onCLose }) => {
  return (
    <DialogMUI className="company-dialog t-center" open={open} onClose={onCLose}>
      <h2 className="mb-16">Send invites</h2>
      <p>Invites have been successfully sent</p>
      <DefaultButton className="company-dialog__btn" onClick={onCLose}>
        Ok, great
      </DefaultButton>
    </DialogMUI>
  );
};

export default InvitesSentDialog;
