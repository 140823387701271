import React from "react";
import { ReactComponent as UpIcon } from "./icons/up.svg";
import { ReactComponent as DownIcon } from "./icons/down.svg";
import "./NumberField.scss";

const NumberField = ({ className = "", id = "number", label = "time(s)", value = 1, setValue }) => {
  const onChangeValue = event => {
    let targetValue = event.target.value;
    let value;

    if (targetValue === "" || targetValue === " ") {
      value = "";
    } else {
      value = parseInt(targetValue);
    }
    if (targetValue >= 0) {
      setValue(value);
    }
  };
  const onStepUp = () => setValue(prevState => ++prevState);
  const onStepDown = () => setValue(prevState => --prevState);

  return (
    <div className={`number-field${className && ` ${className}`}`}>
      <label htmlFor={id} className="number-field__label">
        {label}
      </label>
      <input id={id} className="number-field__input" type="number" value={value} onChange={onChangeValue} />
      <div className="number-field__btn-group">
        <button className="number-field__btn" type="button" onClick={onStepUp}>
          <UpIcon />
        </button>
        <button className="number-field__btn" type="button" onClick={onStepDown}>
          <DownIcon />
        </button>
      </div>
    </div>
  );
};

export default NumberField;
