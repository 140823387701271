import React, { useMemo, useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import _ from "lodash";

import DefaultButton from "../../../Shared/Buttons/DefaultButton";
import InputField from "../../../Shared/InputField";
import { incorrectEmail } from "../../../core/helpers/validationTexts";
import urls from "../../../core/urls";
import { Link } from "react-router-dom";
import { MenuItem } from "@material-ui/core";
import { getCountries, getIDP, postLogin } from "../actions";
import { toastErrors } from "../../../core/helpers/toastErrors";
import { isKeycloakEnabled } from "../../../core/helpers/auth";
import { useKeycloak } from "@react-keycloak/web";

const SignIn = () => {
    const { keycloak } = isKeycloakEnabled() ? useKeycloak() : {};
    const dispatch = useDispatch();
    const {
        countries: { country_phone_codes }
    } = useSelector(({ authorization }) => authorization);

    useEffect(() => {
        dispatch(getCountries());
    }, []);

    const history = useHistory();
    const buttonLoading = useSelector(({ app }) => app.buttonLoading);

    const schema = useMemo(
        () =>
            yup.object().shape({
                tab: yup.number(),
                password: yup.string().when("tab", {is: (value) => value !== 2, then: yup.string().required("No password provided")}),
                country: yup.string().when("tab", { is: 0, then: yup.string().required() }),
                phone: yup.string().when("tab", { is: 0, then: yup.string().required() }),
                provider: yup.string().when("tab", { is: 3, then: yup.string().required() }),
                email: yup
                    .string()
                    .email(incorrectEmail)
                    .when("tab", { is: 1, then: yup.string().required("No email provided")})
            }),
        []
    );

    const {
        control,
        handleSubmit,
        setError,
        setValue,
        formState: { errors }
    } = useForm({
        mode: "onTouched",
        reValidateMode: "onChange",
        resolver: yupResolver(schema),
        shouldFocusError: true,
        defaultValues: {
            tab: 0,
            email: "",
            phone: "",
            provider: "",
            country: "",
            password:""
        }
    });

    const [tab, setTab] = useState(0);
    const changeTab = idx => setTab(idx);

    const onSubmit = async data => {
        const { country, phone, tab, email, password, provider } = data;
        if (tab === 0) {
            dispatch(postLogin({ username: `+${country}${phone}`, password }, false)).then(res => {
                if (res.payload && res.payload.status && res.payload.status === 200) {
                    localStorage.setItem("token", res.payload.data.access);
                    localStorage.setItem("refresh", res.payload.data.refresh);
                    history.push(urls.dashboardUrl.path);
                } else {
                    if (
                        res.error.response &&
                        res.error.response.data &&
                        res.error.response.data.password &&
                        res.error.response.data.password.length > 0 &&
                        res.error.response.data.password[0].message
                    ) {
                        setError("password", {
                            type: "server",
                            message: res.error.response.data.password[0].message
                        });
                    }
                    toastErrors(res);
                }
            });
        } else if (tab === 1) {
            dispatch(postLogin({ username: email, password }, true)).then(res => {
                if (res.payload && res.payload.status && res.payload.status === 200) {
                    localStorage.setItem("token", res.payload.data.access);
                    localStorage.setItem("refresh", res.payload.data.refresh);
                    history.push(urls.dashboardUrl.path);
                } else {
                    if (
                        res.error.response &&
                        res.error.response.data &&
                        res.error.response.data.password &&
                        res.error.response.data.password.length > 0 &&
                        res.error.response.data.password[0].message
                    ) {
                        setError("password", {
                            type: "server",
                            message: res.error.response.data.password[0].message
                        });
                    }
                    toastErrors(res);
                }
            });
        } else if (tab === 2) {
            const parts = provider.split("@");
            const provider_name = (parts.length == 2 ? parts[1] : provider).toLowerCase();
            dispatch(getIDP(provider_name)).then(res => {
                if (res?.payload?.status === 200) {
                    if (isKeycloakEnabled()) {
                        keycloak.login({
                            idpHint: provider_name,
                            loginHint: provider,
                            scope: "offline_access"
                        });
                    }
                } else if (res.error?.response?.status === 404) {
                    setError("provider", {
                        type: "server",
                        message: "This organization was not recognized"
                    });
                } else {
                    toastErrors(res);
                }
            });
            
        }
    };

    return (
        <div className="auth-box">
            <h1 className="auth-box__title">Sign in</h1>

            <form className="auth-box__wrap" onSubmit={handleSubmit(onSubmit)}>
                <div className="auth-tabs mb-32">
                    <button
                        className={`auth-tabs__btn${tab === 0 ? " auth-tabs__btn--active" : ""}`}
                        type="button"
                        onClick={() => {
                            changeTab(0);
                            setValue("tab", 0);
                        }}
                    >
                        { isKeycloakEnabled() ? "Phone number" : "Using phone number" }
                    </button>
                    <button
                        className={`auth-tabs__btn${tab === 1 ? " auth-tabs__btn--active" : ""}`}
                        type="button"
                        onClick={() => {
                            changeTab(1);
                            setValue("tab", 1);
                        }}
                    >
                        { isKeycloakEnabled() ? "Email" : "Using email" }
                    </button>
                    { isKeycloakEnabled() &&
                        <button
                            className={`auth-tabs__btn${tab === 2 ? " auth-tabs__btn--active" : ""}`}
                            type="button"
                            onClick={() => {
                                changeTab(2);
                                setValue("tab", 2);
                            }}
                        >
                            Organization
                        </button>
                    }
                </div>

                {tab === 0 && (
                    <>
                        <Controller
                            name="country"
                            control={control}
                            render={({ field }) => (
                                <InputField
                                    select
                                    id="country"
                                    className="mb-24"
                                    label="Country / Region"
                                    error={errors.country?.message}
                                    {..._.omit(field, "ref")}
                                >
                                    {country_phone_codes?.length > 0 ? (
                                        country_phone_codes.map(option => (
                                            <MenuItem key={option?.country_iso_code} value={option?.phone_code}>
                                                {option?.country_name} (+{option?.phone_code})
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <MenuItem disabled>No options</MenuItem>
                                    )}
                                </InputField>
                            )}
                        />
                        <Controller
                            name="phone"
                            control={control}
                            render={({ field }) => (
                                <InputField
                                    id="phone"
                                    className="mb-24"
                                    type="tel"
                                    label="Phone number"
                                    placeholder="0000 0000"
                                    error={errors.phone?.message}
                                    {..._.omit(field, "ref")}
                                />
                            )}
                        />

                        <Controller
                            name="password"
                            control={control}
                            render={({ field }) => (
                                <InputField
                                    id="password"
                                    type="password"
                                    label="Password"
                                    error={errors.password?.message}
                                    {..._.omit(field, "ref")}
                                />
                            )}
                        />
                    </>
                )}

                {tab === 1 && (
                    <>
                        <Controller
                            name="email"
                            control={control}
                            render={({ field }) => (
                                <InputField
                                    id="email"
                                    className="mb-24"
                                    type="email"
                                    label="Email"
                                    error={errors.email?.message}
                                    {..._.omit(field, "ref")}
                                />
                            )}
                        />

                        <Controller
                            name="password"
                            control={control}
                            render={({ field }) => (
                                <InputField
                                    id="password"
                                    type="password"
                                    label="Password"
                                    error={errors.password?.message}
                                    {..._.omit(field, "ref")}
                                />
                            )}
                        />
                    </>
                )}

                {tab === 2 && (
                    <>
                        <Controller
                            name="provider"
                            control={control}
                            render={({ field }) => (
                                <InputField
                                    id="provider"
                                    type="text"
                                    label="Email"
                                    error={errors.provider?.message}
                                    {..._.omit(field, "ref")}
                                />
                            )}
                        />
                    </>
                )}  

                <DefaultButton className="mt-48 mx-auto" disabled={buttonLoading} loading={buttonLoading} type="submit">
                    Continue
                </DefaultButton>
            </form>
            {tab !== 2 &&
                <Link
                    className="auth-box__btm-link"
                    to={tab === 0 ? urls.resetPasswordNumberUrl.path : urls.resetPasswordEmailUrl.path}
                >
                    Reset password
                </Link>
            }
        </div>
    );
};


export default SignIn;
