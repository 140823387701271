const urls = {
  signInUrl: { path: "/auth/sign-in", name: "Sign in" },
  signUpUrl: { path: "/auth/sign-up", name: "Sign up" },
  confirmNumberUrl: { path: "/auth/confirm-number", name: "Confirm number" },
  confirmEmailUrl: { path: "/auth/confirm-email", name: "Confirm email" },
  accountDetailsNumberUrl: { path: "/auth/account-details-number", name: "Account details with number" },
  accountDetailsEmailUrl: { path: "/auth/account-details-email", name: "Account details with email" },
  resetPasswordNumberUrl: { path: "/auth/reset-password-number", name: "Reset password - number" },
  resetPasswordEmailUrl: { path: "/auth/reset-password-email", name: "Reset password - email" },
  dashboardUrl: { path: "/main/dashboard", name: "Dashboard" },
  profileUrl: { path: "/main/profile-settings", name: "Profile settings" },
  devicesUrl: { path: "/main/devices", name: "Devices" },
  manageUsersUrl: { path: "/main/devices/manage-users", name: "Manage users" },
  inviteUsersToDeviceUrl: { path: "/main/devices/invite-users", name: "Invite users" },
  addDeviceGroupUrl: { path: "/main/devices/add-device-group", name: "Add device group" },
  editDeviceGroupUrl: { path: "/main/devices/edit-device-group", name: "Edit device group" },
  editDeviceUrl: { path: "/main/devices/edit-device", name: "Edit device" },
  shareAccessUrl: { path: "/main/devices/share-access", name: "Share access" },
  activityUrl: { path: "/main/activity", name: "Activity" },
  usersUrl: { path: "/main/users", name: "Users" },
  inviteUsersUrl: { path: "/main/users/invite-users", name: "Invite users" },
  userPageUrl: { path: "/main/users/:slug", name: "User page" },
  inviteSingleUserUrl: { path: "/main/users/invite-users/:userSlug", name: "Invite" },
  addUserGroupUrl: { path: "/main/users/add-user-group", name: "Add user group" },
  editUserGroupUrl: { path: "/main/users/edit-user-group", name: "Edit user group" },
  companiesUrl: { path: "/main/companies", name: "Companies" },
  addCompanyUrl: { path: "/main/companies/add-company", name: "Add company" },
  editCompanyUrl: { path: "/main/companies/edit-company", name: "Edit company" },
  ownersAndAdminsUrl: { path: "/main/companies/owners-and-admins", name: "Owners and admins" },
  inviteUsersToCompanyUrl: { path: "/main/companies/invite-users", name: "Invite users" },
  apiKeysUrl: { path: "/main/companies/api-keys", name: "API keys" },
  remoteLinkUrl: { path: "/link", name: "Remote link access" }
};

export default urls;
