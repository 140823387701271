import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as EditIcon } from "../../../assets/images/edit.svg";
import "./ProfileField.scss";

const ProfileField = ({ label, onButtonClick, text, editable = true }) => {
  return (
    <div className="profile-field">
      <span className="profile-field__label">{label}</span>
      <span className="profile-field__text truncate-ellipsis">{text}</span>
      { editable &&
        <button className="profile-field__btn" type="button" onClick={onButtonClick}>
          <EditIcon />
          <span className="visually-hidden">Edit {label}</span>
        </button>
      }
    </div>
  );
};

ProfileField.propTypes = {
  label: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired
};

export default ProfileField;
