import React from "react";
import PropTypes from "prop-types";
import Skeleton from "@material-ui/lab/Skeleton";
import "./SkeletonMUI.scss";

const SkeletonMUI = ({
  animation = "pulse",
  children,
  className = "",
  component = "span",
  height,
  loading = true,
  variant = "text",
  width,
  maxWidth
}) => {
  if (loading) {
    return (
      <Skeleton
        style={maxWidth && { maxWidth: maxWidth }}
        className={className}
        animation={animation}
        classes={{
          root: "skeleton-mui",
          text: "skeleton-mui--text",
          rect: "skeleton-mui--rect",
          circle: "skeleton-mui--circle",
          pulse: "skeleton-mui--pulse",
          wave: "skeleton-mui--wave",
          withChildren: "skeleton-mui--with-children",
          fitContent: "skeleton-mui--fit-content",
          heightAuto: "skeleton-mui--height-auto"
        }}
        component={component}
        width={maxWidth ? "100%" : width}
        height={height}
        variant={variant}
      >
        {children}
      </Skeleton>
    );
  } else {
    return children || null;
  }
};

SkeletonMUI.propTypes = {
  animation: PropTypes.oneOf(["pulse", "wave", false]),
  children: PropTypes.node,
  className: PropTypes.string,
  component: PropTypes.elementType,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  loading: PropTypes.bool,
  variant: PropTypes.oneOf(["text", "rect", "circle"]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default SkeletonMUI;
