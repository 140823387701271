import { PROFILE } from "./profileActionTypes";

export function setPhoto(data) {
  return {
    type: PROFILE.SET_PHOTO,
    payload: {
      request: {
        url: "profile/set-photo",
        method: "post",
        data
      }
    }
  };
}

export function deletePhoto() {
  return {
    type: PROFILE.DELETE_PHOTO,
    payload: {
      request: {
        url: "profile/delete-photo",
        method: "delete"
      }
    }
  };
}

export function editProfile(data) {
  return {
    type: PROFILE.EDIT_PROFILE,
    payload: {
      request: {
        url: "profile/edit",
        method: "patch",
        data
      }
    }
  };
}

export function changeEmail(data) {
  return {
    type: PROFILE.CHANGE_EMAIL,
    payload: {
      request: {
        url: "profile/change-email-request",
        method: "post",
        data
      }
    }
  };
}

export function verifyEmail(code) {
  return {
    type: PROFILE.VERIFY_EMAIL,
    payload: {
      request: {
        url: "profile/change-email-verify",
        method: "post",
        data: {
          code: code
        }
      }
    }
  };
}

export function changePhone(phoneNumber) {
  return {
    type: PROFILE.CHANGE_PHONE,
    payload: {
      request: {
        url: "profile/change-phone-request",
        method: "post",
        data: {
          phone_number: phoneNumber
        }
      }
    }
  };
}

export function verifyPhone(code) {
  return {
    type: PROFILE.VERIFY_PHONE,
    payload: {
      request: {
        url: "profile/change-phone-verify",
        method: "post",
        data: {
          code: code
        }
      }
    }
  };
}

export function changePassword(data) {
  return {
    type: PROFILE.CHANGE_PASSWORD,
    payload: {
      request: {
        url: "profile/change-password",
        method: "post",
        data
      }
    }
  };
}
