import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, FormControlLabel, FormLabel, RadioGroup } from "@material-ui/core";
import { getUserInfo, postInviteUsers } from "../../Users/usersActions";
import { getDeviceName } from "../devicesActions";
import urls from "../../../core/urls";
import { accessTypeData, limitTypeData, pinCodeOptions } from "../../../core/helpers/constants";
import { useQuery } from "../../../core/helpers/functions";
import { toastErrors } from "../../../core/helpers/toastErrors";
import BackLink from "../../../Shared/BackLink";
import RadioMUI from "../../../Shared/RadioMUI";
import SelectComponent from "../../../Shared/SelectComponent";
import DefaultButton from "../../../Shared/Buttons/DefaultButton";
import DateTimePickerMUI from "../../../Shared/DateTimePickerMUI";
import InputField from "../../../Shared/InputField";
import NumberField from "../../../Shared/NumberField";
import ShareAccessDialog from "../Dialogs/ShareAccessDialog";
import Alert from "@material-ui/lab/Alert";
import "./ShareAccess.scss";
import OverwriteAccessDialog from "../../Users/Dialogs/OverwriteAccessDialog";
import AlreadyHasAccessDialog from "../../Users/Dialogs/AlreadyHasAccessDialog";

const ShareAccess = () => {
  const history = useHistory();
  const query = useQuery();
  const { deviceSlug } = useParams();
  const dispatch = useDispatch();
  const {
    buttonLoading,
    userInfo: { first_name, last_name }
  } = useSelector(({ users }) => users);
  const {
    deviceName: { device_name, type }
  } = useSelector(({ devices }) => devices);
  const backToDevice = () => history.push(`${urls.manageUsersUrl.path}/${deviceSlug}`);

  const [userQuery, setUserQuery] = useState("");
  const [inviteQuery, setInviteQuery] = useState("");
  const [accessQuery, setAccessQuery] = useState("");
  useEffect(() => {
    if (query?.get("user") || (query?.get("invite") && query?.get("access"))) {
      setUserQuery(query.get("user"));
      setInviteQuery(query.get("invite"));
      setAccessQuery(query.get("access"));
    } else {
      backToDevice();
    }
  }, [query]);

  const [device, setDevice] = useState("");
  useEffect(() => {
    if (deviceSlug) {
      setDevice(deviceSlug);
      dispatch(getDeviceName(deviceSlug)).then(res => res?.error && history.push(urls.devicesUrl.path));
    }
  }, [deviceSlug]);

  const [deviceType, setDeviceType] = useState("");
  useEffect(() => {
    type && setDeviceType(type);
  }, [type]);

  const [user, setUser] = useState("");
  useEffect(() => {
    if (userQuery) {
      dispatch(getUserInfo(userQuery)).then(res => res?.error && backToDevice());
      setUser(userQuery);
    }
    if (inviteQuery) setUser(inviteQuery);
  }, [userQuery, inviteQuery]);

  // Access type
  let accessTypeList = accessTypeData;
  const [accessType, setAccessType] = useState("DIGITAL_KEY_ACCESS");
  const [accessLabel, setAccessLabel] = useState("Digital key");
  const [accessSlug, setAccessSlug] = useState("digital_key");
  const setAccessState = arr => {
    setAccessType(arr?.value);
    setAccessLabel(arr?.label);
    setAccessSlug(arr?.name);
  };

  useEffect(() => {
    if (accessQuery && deviceType) {
      if (deviceType === "EURO_1" || deviceType === "STANDARD_1") {
        accessTypeList = accessTypeList.filter(item => item.value === "DIGITAL_KEY_ACCESS");
        setAccessState(accessTypeList[0]);
      } else if (limitType === "total_count" && deviceType !== "EURO_1" && deviceType !== "STANDARD_1") {
        accessTypeList = accessTypeList.filter(
          item => item.value === "DIGITAL_KEY_ACCESS" || item.value === "VIRTUAL_RFID_ACCESS"
        );
        setAccessState(accessTypeList[0]);
      } else {
        const currentType = accessTypeList.find(item => item.name === accessQuery);
        setAccessState(currentType);
      }
    }
  }, [accessQuery, deviceType]);

  const [pinLength, setPinLength] = useState({ value: 4, label: "4-digit" });
  const handlePinLength = event => {
    const { value, label } = event;
    setPinLength({ value, label });
  };

  const getInitialDate = () => {
    const date = new Date();
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  };

  const [rfidNumber, setRfidNumber] = useState("");
  const [vrfidNumber, setVrfidNumber] = useState("");
  const handleNumber = setter => event => setter(event.target.value);

  // Limit type
  let limitTypeList = limitTypeData;
  const [limitType, setLimitType] = useState("unlimited");
  const handleLimitType = event => setLimitType(event.target.value);

  const [dateFrom, setDateFrom] = useState(getInitialDate());
  const [dateTo, setDateTo] = useState(getInitialDate());

  const [usage, setUsage] = useState(1);
  if (usage > 100) setUsage(100);
  if (usage < 1) setUsage(1);

  if (accessType === "PIN_ACCESS" || accessType === "RFID_ACCESS") {
    limitTypeList = limitTypeList.filter(item => ["unlimited", "datetime"].includes(item.value));
  } else if (accessType === "LINK_ACCESS") {
    limitTypeList = limitTypeList.filter(item => ["datetime", "datetime_count"].includes(item.value));
  } else {
    limitTypeList = limitTypeList.filter(item => item.value !== "datetime_count");
  }
  if (!limitTypeList.find((item) => item.value === limitType)) {
    setLimitType(limitTypeList[0].value);
  }

  let disabledButton = false;
  if (buttonLoading) disabledButton = true;
  if (!device) disabledButton = true;
  if (!user) disabledButton = true;
  if (accessType === "RFID_ACCESS" && !rfidNumber) disabledButton = true;
  if (accessType === "VIRTUAL_RFID_ACCESS" && !vrfidNumber) disabledButton = true;
  if (limitType === "datetime" && dateFrom >= dateTo) disabledButton = true;
  if (limitType === "datetime_count" && dateFrom >= dateTo) disabledButton = true;

  const sendData = async (overwrite = false) => {
    const data = {
      devices: [device],
      users: [user],
      access: { type: accessType }
    };

    data.access[accessSlug] = { limit_type: limitType };
    if (accessType === "PIN_ACCESS") data.access[accessSlug].pin_length = pinLength.value;
    if (accessType === "RFID_ACCESS") data.access[accessSlug].rfid_number = rfidNumber;
    if (accessType === "VIRTUAL_RFID_ACCESS") data.access[accessSlug].vrfid_number = vrfidNumber;
    if (limitType === "datetime") {
      data.access[accessSlug].datetime_from = dateFrom.toISOString();
      data.access[accessSlug].datetime_to = dateTo.toISOString();
    }
    else if (limitType === "total_count") {
      data.access[accessSlug].total_count = usage;
    }
    else if (limitType === "datetime_count") {
      data.access[accessSlug].total_count = usage;
      data.access[accessSlug].datetime_from = dateFrom.toISOString();
      data.access[accessSlug].datetime_to = dateTo.toISOString();
    }

    const res = await dispatch(postInviteUsers(data, overwrite));
    if (res?.payload) setSuccessDialog(true);
    if (res?.error) {
      let accessError = res?.error?.response?.data?.non_field_errors?.find(
        item => item?.code === "access_exists" || item?.code === "exact_access_exists"
      )?.code;

      if (!accessError) toastErrors(res);
      if (accessError === "access_exists") setOverwriteDialog(true);
      if (accessError === "exact_access_exists") setHasAccessDialog(true);
    }
  };

  const [overwriteDialog, setOverwriteDialog] = useState(false);
  const overwriteAccess = async () => {
    await sendData(true);
    closeOverwriteDialog();
  };
  const closeOverwriteDialog = () => setOverwriteDialog(false);

  const [hasAccessDialog, setHasAccessDialog] = useState(false);
  const closeHasAccessDialog = () => setHasAccessDialog(false);

  const [successDialog, setSuccessDialog] = useState(false);
  const closeSuccessDialog = () => {
    backToDevice();
    setSuccessDialog(false);
  };

  return (
    <div className="share-access page-wrap">
      <BackLink href={`${urls.manageUsersUrl.path}/${deviceSlug}`} text="Device" />
      <h1 className="page-title">
        {userQuery ? urls.shareAccessUrl.name : "Invite"}{" "}
        <span className="page-title__inner">
          {userQuery ? `for ${first_name && first_name} ${last_name && last_name}` : inviteQuery}
        </span>
      </h1>

      <div className="share-access__section section-wrap">
        <div className="share-access__row">
          <span className="share-access__info">Device</span>
          <span className="share-access__text">{device_name ? device_name : "-"}</span>
        </div>

        <div className="share-access__row">
          <span className="share-access__info">Access type</span>
          <span className="share-access__text">{accessLabel}</span>
          {accessSlug !== "digital_key" && (
            <>
              <p className="mt-24 mb-16">
                {accessSlug === "pin" &&
                  "Select the length of the PIN-code that will be generated automatically and sent to the users specified below."}
                {accessSlug === "rfid" &&
                  "RFID access with the inserted number will be sent to the users email addresses below"}
                {accessSlug === "vrfid" &&
                  "Virtual RFID access with the inserted number will be sent to the users email addresses below"}
                {accessSlug === "link" &&
                  "Share a link to a user via email or sms. With a link, the user can open the lock without downloading BLE Locking app."}
              </p>

              {accessSlug === "link" && 
                <Alert className="mt-24" severity="info">
                  Links require that the device is connected to the internet and has remote access turned on. Disabling remote access prevents all shared links of that device from functioning.
                </Alert>
              }

              {accessSlug === "pin" && (
                <SelectComponent label="Length" options={pinCodeOptions} value={pinLength} onChange={handlePinLength} />
              )}
              {(accessSlug === "rfid" || accessSlug === "vrfid") && (
                <InputField
                  className="max-w-790"
                  label={`${accessSlug.toUpperCase()} number`}
                  fullWidth
                  value={accessSlug === "rfid" ? rfidNumber : vrfidNumber}
                  onChange={handleNumber(accessSlug === "rfid" ? setRfidNumber : setVrfidNumber)}
                />
              )}
            </>
          )}
        </div>

        <FormControl className="share-access__row" component="fieldset">
          <FormLabel className="share-access__legend" component="legend">
            Choose limit type
          </FormLabel>
          <RadioGroup className="share-access__radio-group" value={limitType} onChange={handleLimitType}>
            {limitTypeList.map(({ value, label }) => (
              <FormControlLabel
                key={value}
                className="share-access__label"
                value={value}
                control={<RadioMUI />}
                label={label}
              />
            ))}
          </RadioGroup>

          {limitType === "datetime" && (<>
            { accessType === "LINK_ACCESS" &&
              <p className="mt-24" >Maximum valid time period for link access is 30 days.</p>
            }
            <div className="mt-24">
              <DateTimePickerMUI className="mr-16" label="From" value={dateFrom} disablePast onChange={setDateFrom} />
              <DateTimePickerMUI label="To" value={dateTo} disablePast minDate={dateFrom} onChange={setDateTo} />
            </div>
          </>)}
          {limitType === "total_count" && (
            <NumberField className="mt-24" value={usage} setValue={setUsage} />
          )}
          {limitType === "datetime_count" && (<>
            <div className="mt-24">
              <NumberField value={usage} setValue={setUsage} />
            </div>
            { accessType === "LINK_ACCESS" &&
              <p className="mt-24" >Maximum valid time period for link access is 30 days.</p>
            }
            <div className="mt-24">
              <DateTimePickerMUI className="mr-16" label="From" value={dateFrom} disablePast onChange={setDateFrom} />
              <DateTimePickerMUI label="To" value={dateTo} disablePast minDate={dateFrom} onChange={setDateTo} />
            </div>
          </>)}
        </FormControl>

        <DefaultButton
          className="share-access__send-btn"
          loading={buttonLoading}
          disabled={disabledButton}
          onClick={() => sendData()}
        >
          {userQuery ? "Share access" : "Send invite"}
        </DefaultButton>
      </div>

      <OverwriteAccessDialog
        open={overwriteDialog}
        onCLose={closeOverwriteDialog}
        loading={buttonLoading}
        onConfirm={overwriteAccess}
      />
      <AlreadyHasAccessDialog open={hasAccessDialog} onCLose={closeHasAccessDialog} />
      <ShareAccessDialog open={successDialog} onCLose={closeSuccessDialog} access={accessSlug} invite={inviteQuery} />
    </div>
  );
};

export default ShareAccess;
