import React from "react";
import Avatar from "../Avatar";
import { ReactComponent as EmailIcon } from "../../assets/images/invited-avatar.svg";
import { ReactComponent as PhoneIcon } from "../../assets/images/invited-avatar-2.svg";

const AutocompleteItem = ({ data, onClick }) => {
  const { id, first_name, last_name, email, phone_number, photo, has_any_access } = data;
  return (
    <div
      key={id}
      className={`autocomplete-item${has_any_access ? " autocomplete-item--disabled" : ""}`}
      role="option"
      onClick={onClick}
    >
      {first_name || last_name ? (
        <Avatar
          className="autocomplete-item__avatar"
          firstName={first_name}
          lastName={last_name}
          size={40}
          src={photo}
        />
      ) : email ? (
        <EmailIcon className="autocomplete-item__avatar" />
      ) : (
        <PhoneIcon className="autocomplete-item__avatar" />
      )}

      <div className="autocomplete-item__text-wrap">
        <span className="autocomplete-item__title">
          {first_name || last_name ? `${first_name} ${last_name}` : email || phone_number}
        </span>
        <span className="autocomplete-item__subtitle">
          {!has_any_access && (
            <>
              {phone_number && phone_number}
              {phone_number && email && " • "}
              {email && email}
            </>
          )}
          {has_any_access && first_name && last_name && "already has the access to this device"}
          {has_any_access && !first_name && !last_name && `invited via ${email ? "email" : "phone"} to this device`}
        </span>
      </div>
    </div>
  );
};

export default AutocompleteItem;
