import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "../../../core/actions";
import { deletePhoto, setPhoto } from "../profileActions";
import { imageTypeValidation } from "../../../core/helpers/functions";
import notifyError from "../../../core/helpers/notifyError";
import { toastErrors } from "../../../core/helpers/toastErrors";
import Avatar from "../../../Shared/Avatar";
import CropperDialog from "../../../Shared/CropperDialog";
import "./ProfilePhoto.scss";

const ProfilePhoto = ({ photo, firstName, lastName }) => {
  const dispatch = useDispatch();
  const loading = useSelector(({ app }) => app.loading);

  const [imageToCrop, setImageToCrop] = useState(null);
  const onSelectFile = event => {
    event.persist();
    let file = event.target.files[0];

    if (file && imageTypeValidation(file)) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => setImageToCrop([reader.result]);
    } else {
      notifyError("Supported file types: JPG, JPEG, PNG");
    }
  };

  const onUploadPhoto = canvas => {
    canvas.toBlob(async blob => {
      const formData = new FormData();
      formData.append("photo", blob, "photo.jpg");

      const res = await dispatch(setPhoto(formData));
      if (res.payload) dispatch(getProfile());
      if (res.error) toastErrors(res);

      onCancelCropping();
    });
  };

  const onCancelCropping = () => {
    const fileInput = document.getElementById("file-input");
    if (fileInput) fileInput.value = "";
    setImageToCrop(null);
  };

  const onPhotoRemove = async () => {
    const res = await dispatch(deletePhoto());
    if (res.payload) dispatch(getProfile());
    if (res.error) notifyError(res);
  };

  return (
    <div className="profile-photo">
      <Avatar src={photo} firstName={firstName} lastName={lastName} size={144} fontSize={54} />
      <div className="profile-photo__upload">
        <span>Profile photo</span>
        <label htmlFor="file-input">
          Upload
          <input
            id="file-input"
            className="visually-hidden"
            type="file"
            accept=".jpg, .jpeg, .png"
            onChange={onSelectFile}
          />
        </label>
        {photo && (
          <button className="profile-photo__remove-btn" type="button" disabled={loading} onClick={onPhotoRemove}>
            Remove
          </button>
        )}
      </div>

      {imageToCrop && (
        <CropperDialog
          imageToCrop={imageToCrop}
          onFinishCropping={onUploadPhoto}
          onCancelCropping={onCancelCropping}
          loading={loading}
        />
      )}
    </div>
  );
};

export default ProfilePhoto;
