import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ClickAwayListener } from "@material-ui/core";
import urls from "../../core/urls";
import { getProfile } from "../../core/actions";
import { editProfile } from "../../Features/ProfileSettings/profileActions";
import { setCurrentCompany } from "../../Features/Companies/companiesActions";
import { toastErrors } from "../../core/helpers/toastErrors";
import Avatar from "../../Shared/Avatar";
import { ReactComponent as ArrowIcon } from "../../assets/images/caret-down-3.svg";
import { ReactComponent as CurrentIcon } from "../../assets/images/success-2.svg";
import { ReactComponent as LogoutIcon } from "../../assets/images/logout.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/close-small.svg";
import "./ProfileDropdown.scss";
import { signOut } from "../../core/helpers/auth";

const ProfileDropdown = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    profile: { first_name, last_name, photo, current_company, latest_companies, is_first_company_tooltip_showed }
  } = useSelector(({ app }) => app);

  const [dropdown, setDropdown] = useState(false);
  const openDropdown = () => {
    setDropdown(true);
    if (tooltip) closeTooltip();
  };
  const closeDropdown = () => setDropdown(false);

  const switchCompany = id => async () => {
    const res = await dispatch(setCurrentCompany(id));
    if (res?.payload) {
      dispatch(getProfile());
      window.location.reload();
    }
    if (res?.error) toastErrors(res);
    closeDropdown();
  };

  const logout = () => {
    signOut().then(() => history.push(urls.signInUrl.path));
  };

  const [tooltip, setTooltip] = useState(false);
  const closeTooltip = () => {
    dispatch(editProfile({ is_first_company_tooltip_showed: true }));
    setTooltip(false);
  };
  useEffect(() => {
    if (!is_first_company_tooltip_showed && !current_company && latest_companies?.length === 1) {
      setTooltip(true);
    }
  }, [current_company, latest_companies]);

  return (
    <div className="profile-dropdown">
      <button className="profile-btn" type="button" onClick={openDropdown}>
        <span className="profile-btn__info">
          <span className="profile-btn__username truncate-ellipsis">
            {first_name && first_name} {last_name && last_name}
          </span>
          <span className="profile-btn__company truncate-ellipsis">
            {current_company ? current_company?.account_name : "Personal profile"}
          </span>
        </span>
        <Avatar src={photo} firstName={first_name} lastName={last_name} size={48} fontSize={18} />
        <ArrowIcon className={`profile-btn__arrow${dropdown ? " profile-btn__arrow--open" : ""}`} />
      </button>

      {dropdown && (
        <ClickAwayListener onClickAway={closeDropdown}>
          <nav className="profile-nav">
            <button
              className={`profile-nav__item profile-nav__item--profile py-12${
                !current_company ? " profile-nav__item--active" : ""
              }`}
              type="button"
              onClick={switchCompany(null)}
            >
              Personal profile
              {!current_company && <CurrentIcon />}
            </button>

            {latest_companies?.length ? (
              latest_companies.map(({ id, account_name, is_current_company }) => (
                <button
                  key={id}
                  className={`profile-nav__item py-6${is_current_company ? " profile-nav__item--active" : ""}`}
                  type="button"
                  onClick={switchCompany(id)}
                >
                  {account_name ? account_name : "-"}
                  {is_current_company && <CurrentIcon />}
                </button>
              ))
            ) : (
              <span className="profile-nav__item profile-nav__item--text py-6">No companies</span>
            )}
            <Link
              className="profile-nav__item profile-nav__item--small pt-10 pb-16"
              to={latest_companies?.length ? urls.companiesUrl.path : urls.addCompanyUrl.path}
              onClick={closeDropdown}
            >
              {latest_companies?.length ? "See all companies" : "Create company"}
            </Link>

            <Link
              className="profile-nav__item profile-nav__item--bordered py-16"
              to={urls.profileUrl.path}
              onClick={closeDropdown}
            >
              Profile settings
            </Link>
            <button className="profile-nav__item py-16" type="button" onClick={logout}>
              <LogoutIcon />
              Log out
            </button>
          </nav>
        </ClickAwayListener>
      )}

      <div className={`profile-tooltip ${tooltip ? " profile-tooltip--active" : ""}`}>
        <span className="profile-tooltip__text">Open to switch to the company profile</span>
        <button className="profile-tooltip__btn" type="button" aria-label="Close tooltip" onClick={closeTooltip}>
          <CloseIcon />
        </button>
      </div>
    </div>
  );
};

export default ProfileDropdown;
