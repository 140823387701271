export const USERS = {
  GET_ACTIVE_USERS: "GET_ACTIVE_USERS",
  GET_ACTIVE_USERS_SUCCESS: "GET_ACTIVE_USERS_SUCCESS",
  GET_ACTIVE_USERS_FAIL: "GET_ACTIVE_USERS_FAIL",

  GET_DEVICE_ADMINS: "GET_DEVICE_ADMINS",
  GET_DEVICE_ADMINS_SUCCESS: "GET_DEVICE_ADMINS_SUCCESS",
  GET_DEVICE_ADMINS_FAIL: "GET_DEVICE_ADMINS_FAIL",

  GET_INVITED_USERS: "GET_INVITED_USERS",
  GET_INVITED_USERS_SUCCESS: "GET_INVITED_USERS_SUCCESS",
  GET_INVITED_USERS_FAIL: "GET_INVITED_USERS_FAIL",

  GET_USER_INFO: "GET_USER_INFO",
  GET_USER_INFO_SUCCESS: "GET_USER_INFO_SUCCESS",
  GET_USER_INFO_FAIL: "GET_USER_INFO_FAIL",

  GET_USER_DEVICES: "GET_USER_DEVICES",
  GET_USER_DEVICES_SUCCESS: "GET_USER_DEVICES_SUCCESS",
  GET_USER_DEVICES_FAIL: "GET_USER_DEVICES_FAIL",

  DELETE_DEVICE: "DELETE_DEVICE",
  DELETE_DEVICE_SUCCESS: "DELETE_DEVICE_SUCCESS",
  DELETE_DEVICE_FAIL: "DELETE_DEVICE_FAIL",

  GET_SHARE_OPTIONS: "GET_SHARE_OPTIONS",
  GET_SHARE_OPTIONS_SUCCESS: "GET_SHARE_OPTIONS_SUCCESS",
  GET_SHARE_OPTIONS_FAIL: "GET_SHARE_OPTIONS_FAIL",

  GET_DEVICE_USERS: "GET_DEVICE_USERS",
  GET_DEVICE_USERS_SUCCESS: "GET_DEVICE_USERS_SUCCESS",
  GET_DEVICE_USERS_FAIL: "GET_DEVICE_USERS_FAIL",

  GET_VALIDATE_VALUE: "GET_VALIDATE_VALUE",
  GET_VALIDATE_VALUE_SUCCESS: "GET_VALIDATE_VALUE_SUCCESS",
  GET_VALIDATE_VALUE_FAIL: "GET_VALIDATE_VALUE_FAIL",

  POST_VALIDATE_EMAILS_PHONES: "POST_VALIDATE_EMAILS_PHONES",
  POST_VALIDATE_EMAILS_PHONES_SUCCESS: "POST_VALIDATE_EMAILS_PHONES_SUCCESS",
  POST_VALIDATE_EMAILS_PHONES_FAIL: "POST_VALIDATE_EMAILS_PHONES_FAIL",

  POST_INVITE_USERS: "POST_INVITE_USERS",
  POST_INVITE_USERS_SUCCESS: "POST_INVITE_USERS_SUCCESS",
  POST_INVITE_USERS_FAIL: "POST_INVITE_USERS_FAIL",

  SET_USER_INFO: "SET_USER_INFO",
};
