import React from "react";
import { Link } from "react-router-dom";
import urls from "../../../core/urls";
import TableHead from "../../../Shared/TableHead";
import Avatar from "../../../Shared/Avatar";
import SkeletonMUI from "../../../Shared/SkeletonMUI";
import CopyToClipboard from "../../../Shared/CopyToClipboard";
import "./UsersTable.scss";
import Tooltip from "../../../Shared/Tooltip";

const UsersTable = ({ users, type, sortList, sortParams, setSortParams, firstLoad }) => {
  let noData = <b className="users-table__no-data">-</b>;
  if (type !== "invited") {
    noData = <span className="users-table__not-spec">Not specified</span>;
  }

  return (
    <table className="users-table table full-width">
      <TableHead {...{ sortList, sortParams, setSortParams }} />
      <tbody>
        {users?.map(({ id, first_name, last_name, email, phone_number, photo, devices_count, devices }) => {
          const username = `${first_name ? first_name : ""}${last_name ? ` ${last_name}` : ""}`;

          return (
            <tr key={id}>
              <td className={sortList[0].className}>
                {type !== "invited" ? (
                  <Link className="users-table__user" to={`${urls.usersUrl.path}/${id}`}>
                    <SkeletonMUI variant="circle" width={40} height={40} loading={firstLoad}>
                      {username && <Avatar firstName={first_name} lastName={last_name} src={photo} />}
                    </SkeletonMUI>
                    <SkeletonMUI maxWidth={115} loading={firstLoad}>
                      {username ? <span className="users-table__name truncate-ellipsis">{username}</span> : noData}
                    </SkeletonMUI>
                  </Link>
                ) : (
                  noData
                )}
              </td>
              <td className={sortList[1].className}>
                <SkeletonMUI className="items-center" maxWidth={220} loading={firstLoad}>
                  {email ? (
                    <>
                      <span className="truncate-ellipsis">{email}</span>
                      <CopyToClipboard className="ml-4" text={email} ariaLabel="email" />
                    </>
                  ) : (
                    noData
                  )}
                </SkeletonMUI>
              </td>
              <td className={sortList[2].className}>
                <SkeletonMUI className="items-center" maxWidth={115} loading={firstLoad}>
                  {phone_number ? (
                    <>
                      <span className="truncate-ellipsis">{phone_number}</span>
                      <CopyToClipboard className="ml-4" text={phone_number} ariaLabel="phone" />
                    </>
                  ) : (
                    noData
                  )}
                </SkeletonMUI>
              </td>
              <td className={sortList[3].className}>
                <SkeletonMUI maxWidth={80} loading={firstLoad}>
                  {devices_count > 1 ? (
                    <Tooltip text={devices.map(device => device?.device_name).join(", ")}>
                      <b className="users-table__devices">
                        {devices_count} {type === "admins" ? "device" : "locks"}
                        <span className="tooltip"></span>
                      </b>
                    </Tooltip>
                  ) : (
                    <span className="truncate-ellipsis">{devices?.map(device => device?.device_name)}</span>
                  )}
                </SkeletonMUI>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default UsersTable;
