/* eslint-disable no-unused-vars */
import * as actions from "./actions";
import { iot, mqtt } from "aws-iot-device-sdk-v2";
import { v4 } from "uuid";
import { MQTT } from "./actionTypes";

const mqttMiddleware = () => {
  let connection = null;
  let sessionID = null;
  let messageTopic = null;
  let buffer = "";
  let connected = false;

  const onConnected = store => {
    store.dispatch(actions.mqttConnected());
  };

  const onClose = store => {
    store.dispatch(actions.mqttDisconnected());
  };

  const onFailed = store => {
    store.dispatch(actions.mqttFailed());
  };

  const onMessage = (store) => (topic, payload) => {
    const decoder = new TextDecoder("utf8");
    const packet = decoder.decode(new Uint8Array(payload));
    buffer += packet;

    let endIndex = buffer.indexOf("#");
    while (endIndex !== -1) {
      const message = buffer.substring(0, endIndex);
      store.dispatch(actions.mqttMessage(message));
      
      buffer = buffer.substring(endIndex + 1);
      endIndex = buffer.indexOf("#");
    }
  };

  // the middleware part of this function
  return store => next => action => {
    switch (action.type) {
      case MQTT.MQTT_CONNECT:
        if (connection !== null) {
          connection.disconnect();
          connected = false;
        }
        sessionID = v4();
        messageTopic = `${action.deviceSerialNumber}/b2d/ble/${sessionID}`;

        // Documentation: https://aws.github.io/aws-iot-device-sdk-js-v2/browser/classes/aws_iot.AwsIotMqttConnectionConfigBuilder.html
        // connect to the remote host
        connection = (new mqtt.MqttClient()).new_connection(
          iot.AwsIotMqttConnectionConfigBuilder.new_builder_for_websocket()
            .with_clean_session(true)
            .with_client_id(`user-${action.userId}-${action.deviceSerialNumber}`)
            .with_endpoint(process.env.REACT_APP_MQTT_URL)
            .with_custom_authorizer(
              // username is device serial number, password is user access token.
              // It is also required that a session uuid is passed as a query parameter 
              // See more https://docs.aws.amazon.com/iot/latest/developerguide/custom-auth.html#custom-auth-mqtt
              `${action.deviceSerialNumber}?session=${sessionID}`,
              process.env.REACT_APP_MQTT_AUTHORIZER,
              "",
              action.userToken
            )
            .build()
        );

        connection.on("connect", () => {
          connected = true;
          connection.subscribe(`${action.deviceSerialNumber}/d2b/ble/${sessionID}`, mqtt.QoS.AtLeastOnce, onMessage(store));
          onConnected(store);
        });
        connection.on("disconnect", () => onClose(store));

        connection.on("interrupt", (error) => { // Connection will be automatically retried
          if (connected) {
            console.log(`Connection interrupted: error=${error}`);
          }
        });
        connection.on("resume", (return_code, session_present) => { // Called when retry was successful
          console.log(`Resumed: rc: ${return_code} existing session: ${session_present}`);
        });
        connection.on("error", (error) => {
          console.log(error);
        });
        connection.connect();
        
        // Fix when connection fails before ever getting online.
        connection.connection.on("offline", (error) => {
          if (!connected) {
            onFailed(store);
          }
        });
        break;
      case MQTT.MQTT_DISCONNECT:
        if (connection !== null) {
          connection.disconnect();
          connected = false;
        }
        connection = null;
        break;
      case MQTT.MQTT_COMMAND:
        connection.publish(messageTopic, action.command, mqtt.QoS.AtLeastOnce);
        break;
      default:
        return next(action);
    }
  };
};

export default mqttMiddleware();
