import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { changeEmail } from "../profileActions";
import { editEmailSchema } from "../../../core/helpers/validations";
import DialogMUI from "../../../Shared/DialogMUI";
import InputField from "../../../Shared/InputField";
import DefaultButton from "../../../Shared/Buttons/DefaultButton";
import ConfirmDialog from "./ConfirmDialog";
import "./Dialogs.scss";

const EditEmailDialog = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const buttonLoading = useSelector(({ app }) => app.buttonLoading);

  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: { errors, isValid }
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(editEmailSchema),
    defaultValues: {
      email: ""
    }
  });

  const onDialogClose = () => {
    onClose();
    reset();
  };

  const [emailAddress, setEmailAddress] = useState("");
  const [confirm, setConfirm] = useState(false);
  const onConfirmOpen = () => {
    setConfirm(true);
    onDialogClose();
  };
  const onConfirmClose = () => setConfirm(false);

  const onSubmit = async data => {
    const { email } = data;
    setEmailAddress(email);

    const res = await dispatch(changeEmail(data));
    const errors = res.error?.response.data;

    if (res?.payload) onConfirmOpen();
    if (res?.error) {
      errors.email && setError("email", { type: "manual", message: errors.email[0].message });
      errors.non_field_errors && setError("email", { type: "manual", message: errors.non_field_errors[0].message });
    }
  };

  return (
    <>
      <DialogMUI className="profile-dialog" open={open} onClose={onDialogClose}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h2 className="profile-dialog__title">Edit your email</h2>
          <p className="profile-dialog__info">Please enter your new email</p>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <InputField type="email" label="Email" fullWidth error={errors.email?.message} inputProps={field} />
            )}
          />
          <DefaultButton
            className="profile-dialog__btn"
            disabled={!isValid || buttonLoading}
            loading={buttonLoading}
            formAction
          >
            Continue
          </DefaultButton>
        </form>
      </DialogMUI>

      <ConfirmDialog open={confirm} onClose={onConfirmClose} email={emailAddress} />
    </>
  );
};

export default EditEmailDialog;
