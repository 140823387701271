import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { toastErrors } from "../../core/helpers/toastErrors";
import { useQuery } from "../../core/helpers/functions";
import Alert from "@material-ui/lab/Alert";
import DefaultButton from "../../Shared/Buttons/DefaultButton";
import SkeletonMUI from "../../Shared/SkeletonMUI";

import "./RemoteLink.scss";
import { getDeviceStatus, openLock } from "./remoteLinkActions";

const CUSTOM_ERROR_MESSAGES = {
  "token_invalid": "This access link is not yet active or has expired. Please contact the lock owner to get a new access link."
};

const RemoteLink = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const token = query.get("t");
  const {
    loading,
    buttonLoading,
    status
  } = useSelector(({ remoteLink }) => remoteLink);
  const [error, setError] = useState(null);
  const [lockOpened, setLockOpened] = useState(false);


  useEffect(() => {
    dispatch(getDeviceStatus(token)).then(res => {
      if (res?.error) {
        if (res.error.response?.status === 400) {
          const data = res?.error?.response?.data;
          let fieldError = data.non_field_errors?.[0];
          if (fieldError) {
            const customMessage = CUSTOM_ERROR_MESSAGES[fieldError.code];
            if (customMessage)
              setError(customMessage);
            else
              setError(fieldError.message);
            return;
          }
          fieldError = data.token?.[0];
          if (fieldError) {
            setError(CUSTOM_ERROR_MESSAGES["token_invalid"]);
            return;
          }
        }
        toastErrors(res);
        setError("Failed to get device status, please try again later.");
      }
    });
  }, []);
  
  const onClick = (secondRelay = false) => () => {
    console.log(token, secondRelay);
    dispatch(openLock(token, secondRelay)).then(res => {
      if (res?.error) {
        toastErrors(res);
      } else {
        setLockOpened(true);
      }
    });
  };
  let content = null;

  if (loading) {
    content = (<>
      <h1 className="remote-link-box__title">
        <SkeletonMUI className="mx-auto" maxWidth="80%"></SkeletonMUI>
      </h1>
      <div className="remote-link-box__wrap">
        <SkeletonMUI className="remote-link-box__desc mx-auto t-center" maxWidth="100%" />
        <SkeletonMUI className="remote-link-box__desc mb-32 mx-auto t-center" maxWidth="60%" />
        <SkeletonMUI className="mx-auto t-center" variant="rect" height="48px" maxWidth="100%" />
        <SkeletonMUI className="remote-link-box__desc mt-32 mx-auto t-center" maxWidth="100%" />
        <SkeletonMUI className="remote-link-box__desc mx-auto t-center" maxWidth="80%" />
      </div>
    </>);
  } else if (error) {
    content = (<Alert className="mt-16" severity="error">{error}</Alert>);
  } else if (lockOpened) {
    content = (<div className="remote-link-box__wrap">
      <span className="remote-link-box__desc mx-auto t-center">
        Lock opened! You may now close this page or go back to reopen the lock.
      </span>
      <DefaultButton variant="text" className="mt-16" onClick={() => window.location.reload()}>
        Go back
      </DefaultButton>
    </div>);
  } else if (status) {
    content = (<>
      <h1 className="remote-link-box__title">Open lock: {status.device_name}</h1>
      <div className="remote-link-box__wrap">
        <span className="remote-link-box__desc mb-32 mx-auto t-center">
          To open the lock please click on the button below.
        </span>
        {status.has_second_relay ? 
          <>
            <DefaultButton className="" onClick={onClick(false)} disabled={buttonLoading}>
              Open lock 1
            </DefaultButton>
            <DefaultButton className="mt-16" onClick={onClick(true)} disabled={buttonLoading}>
              Open lock 2
            </DefaultButton>
          </>
        :
          <DefaultButton className="" onClick={onClick(false)} disabled={buttonLoading}>
            Open lock
          </DefaultButton>
        }
        { status.limit_type === "datetime_count" &&
          <span className="remote-link-box__desc mt-32 mx-auto t-center">
            This link can be used for {status.total_count_remaining} time{status.total_count_remaining !== 1 && "s"}.<br />
            The access will expire at {dayjs(status.date_to).format("DD.MM.YYYY HH:mm")}.
          </span>
        }
        { status.limit_type === "datetime" &&
          <span className="remote-link-box__desc mt-32 mx-auto t-center">
            This link can be used until {dayjs(status.date_to).format("DD.MM.YYYY HH:mm")}.
          </span>
        }
      </div>
    </>);
  }

  return (
    <div className="remote-link">
      <div className="remote-link-box">
        {content}
      </div>
    </div>
  );
};

export default RemoteLink;
