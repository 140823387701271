import { DEVICES } from "./devicesActionTypes";

export const getDevices = (access = "owner", loadAnimation = true) => (query = "") => {
  let type = DEVICES.GET_DEVICES_OWNER;
  if (access === "admin") type = DEVICES.GET_DEVICES_ADMIN;
  if (access === "user") type = DEVICES.GET_DEVICES_USER;

  return {
    type,
    payload: {
      request: {
        url: `/my/devices/${access}/${query}`
      }
    },
    loadAnimation
  };
};

export function getOptions() {
  return {
    type: DEVICES.GET_OPTIONS,
    payload: {
      request: {
        url: "/my-devices/options"
      }
    }
  };
}

export function getDevice(deviceId) {
  return {
    type: DEVICES.GET_DEVICE,
    payload: {
      request: {
        url: `/my/devices/owner/${deviceId}/`
      }
    }
  };
}

export function patchDevice(deviceId, data) {
  return {
    type: DEVICES.PATCH_DEVICE,
    payload: {
      request: {
        url: `/my/devices/owner/${deviceId}/`,
        method: "patch",
        data
      }
    }
  };
}

export function getDeviceName(deviceId) {
  return {
    type: DEVICES.GET_DEVICE_NAME,
    payload: {
      request: {
        url: `/my/devices/${deviceId}/`
      }
    }
  };
}

export const getDeviceUsersList = deviceId => (query = "") => {
  return {
    type: DEVICES.GET_DEVICE_USERS_LIST,
    payload: {
      request: {
        url: `/my/devices/${deviceId}/users/${query}`
      }
    }
  };
};

export function deleteUser(deviceId, userId, accessType) {
  return {
    type: DEVICES.DELETE_USER,
    payload: {
      request: {
        url: `/my/devices/${deviceId}/users/${userId}/${accessType ? `?access_type=${accessType}` : ""}`,
        method: "delete"
      }
    }
  };
}

export const getDeviceInvitedUsers = deviceId => (query = "") => {
  return {
    type: DEVICES.GET_DEVICE_INVITED_USERS,
    payload: {
      request: {
        url: `/my/devices/${deviceId}/invited/${query}`
      }
    }
  };
};

export function deleteInvitation(deviceId, invitationId, accessType) {
  return {
    type: DEVICES.DELETE_INVITATION,
    payload: {
      request: {
        url: `/my/devices/${deviceId}/invited/${invitationId}/${accessType ? `?access_type=${accessType}` : ""}`,
        method: "delete"
      }
    }
  };
}

export function deleteDevice(deviceId, password) {
  return {
    type: DEVICES.DELETE_DEVICE,
    payload: {
      request: {
        url: `/my/devices/${deviceId}/delete/`,
        method: "post",
        data: { password }
      }
    }
  };
}

export function getTransferOptions(deviceId, type = "users") {
  return {
    type: DEVICES.GET_TRANSFER_OPTIONS,
    payload: {
      request: {
        url: `/my/devices/${deviceId}/transfer-ownership/search-${type}/`
      }
    }
  };
}

export function postTransferOwnership(deviceId, password, newOwnerId, type = "user") {
  let data = { password, user: newOwnerId };
  if (type === "company") {
    data = { password, company: newOwnerId };
  }

  return {
    type: DEVICES.POST_TRANSFER_OWNERSHIP,
    payload: {
      request: {
        url: `/my/devices/${deviceId}/transfer-ownership-to-${type}/`,
        method: "post",
        data
      }
    }
  };
}

export function postChangeRole(deviceId, userId, role) {
  return {
    type: DEVICES.POST_CHANGE_ROLE,
    payload: {
      request: {
        url: `/my/devices/${deviceId}/users/${userId}/change-role/`,
        method: "post",
        data: {
          role
        }
      }
    }
  };
}

export function postHistory(deviceId, userId, date, success=true, button_name="", eventType="OPEN_REMOTE") {
  return {
    type: DEVICES.POST_CHANGE_ROLE,
    payload: {
      request: {
        url: "/my/devices/history/",
        method: "post",
        data: {
          device_id: deviceId,
          user: userId,
          date: date.toISOString(),
          event_type: eventType,
          event_status: success ? "SUCCESS" : "FAILED",
          button_name: button_name,
          os_version: "",
          app_version: "web-admin",
          manufacturer: ""
        }
      }
    }
  };
}

export const openLock = (userId, device, userToken, secondRelay = false) => ({
  type: DEVICES.MQTT_OPEN_LOCK, payload: { userId, device, userToken, secondRelay }
});
