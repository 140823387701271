import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { changePassword } from "../profileActions";
import { changePasswordSchema } from "../../../core/helpers/validations";
import DialogMUI from "../../../Shared/DialogMUI";
import InputField from "../../../Shared/InputField";
import DefaultButton from "../../../Shared/Buttons/DefaultButton";
import "./Dialogs.scss";

const ChangePasswordDialog = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const buttonLoading = useSelector(({ app }) => app.buttonLoading);

  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: { errors, isValid }
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(changePasswordSchema),
    defaultValues: {
      old_password: "",
      new_password: ""
    }
  });

  const [passwordChanged, setPasswordChanged] = useState(false);
  const onPasswordChanged = () => setPasswordChanged(true);
  const onDialogClose = () => {
    onClose();
    reset();
    const timer = setTimeout(() => setPasswordChanged(false), 1000);
    return () => clearTimeout(timer);
  };

  const onSubmit = async data => {
    const res = await dispatch(changePassword(data));
    const errors = res.error?.response.data;

    if (res?.payload) onPasswordChanged();
    if (res?.error) {
      errors.old_password && setError("old_password", { type: "manual", message: errors.old_password[0].message });
      errors.new_password && setError("new_password", { type: "manual", message: errors.new_password[0].message });
    }
  };

  return (
    <DialogMUI className="profile-dialog" open={open} onClose={onDialogClose}>
      <h2 className="mb-16 t-center">Change password</h2>
      {!passwordChanged ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="old_password"
            control={control}
            render={({ field }) => (
              <InputField
                className="mt-16 mb-24"
                label="Old password"
                fullWidth
                typePassword
                error={errors.old_password?.message}
                inputProps={field}
              />
            )}
          />

          <Controller
            name="new_password"
            control={control}
            render={({ field }) => (
              <InputField
                label="New password"
                fullWidth
                typePassword
                error={errors.new_password?.message}
                inputProps={field}
              />
            )}
          />
          <DefaultButton
            className="profile-dialog__btn"
            disabled={!isValid || buttonLoading}
            loading={buttonLoading}
            formAction
          >
            Save
          </DefaultButton>
        </form>
      ) : (
        <>
          <p className="t-center">Your password has been successfully changed</p>
          <DefaultButton className="profile-dialog__btn" onClick={onDialogClose}>
            Ok, great
          </DefaultButton>
        </>
      )}
    </DialogMUI>
  );
};

export default ChangePasswordDialog;
