import { toast } from "react-toastify";

const toastErrors = res => {
  const findMessage = (obj, keyName, elementName = "") => {
    let tempArr = Object.keys(obj);

    for (const el of tempArr) {
      if (el === keyName) {
        if (elementName) {
          return `${elementName.toUpperCase()}: ${obj[el]}`;
        } else {
          return obj[el];
        }
      } else if (typeof obj[el] === "object" && obj[el] !== null) {
        return findMessage(obj[el], keyName, elementName);
      }
    }
  };

  if (res?.error?.response?.data && Object.values(res.error.response.data).length > 0) {
    let tempArr = Object.values(res.error.response.data).flat(Infinity);

    Object.keys(res.error.response.data).forEach((el, id) =>
      toast.error(findMessage(tempArr[id], "message"), {
        autoClose: 5000
      })
    );
  }
  if (res?.error?.response?.status === 500) {
    toast.error(res.error?.message);
  }
};

export { toastErrors };
