// You need to change the path and import the functions into rootSaga.js
import { takeEvery } from "redux-saga/effects";
import { disableButtonLoader, disableLoader, enableButtonLoader, enableLoader } from "../../core/rootSaga";

export function* handleProfileLoaders() {
  yield takeEvery("SET_PHOTO", enableLoader);
  yield takeEvery("SET_PHOTO_SUCCESS", disableLoader);
  yield takeEvery("SET_PHOTO_FAIL", disableLoader);

  yield takeEvery("DELETE_PHOTO", enableLoader);
  yield takeEvery("DELETE_PHOTO_SUCCESS", disableLoader);
  yield takeEvery("DELETE_PHOTO_FAIL", disableLoader);

  yield takeEvery("EDIT_PROFILE", enableButtonLoader);
  yield takeEvery("EDIT_PROFILE_SUCCESS", disableButtonLoader);
  yield takeEvery("EDIT_PROFILE_FAIL", disableButtonLoader);

  yield takeEvery("CHANGE_EMAIL", enableButtonLoader);
  yield takeEvery("CHANGE_EMAIL_SUCCESS", disableButtonLoader);
  yield takeEvery("CHANGE_EMAIL_FAIL", disableButtonLoader);

  yield takeEvery("VERIFY_EMAIL", enableButtonLoader);
  yield takeEvery("VERIFY_EMAIL_SUCCESS", disableButtonLoader);
  yield takeEvery("VERIFY_EMAIL_FAIL", disableButtonLoader);

  yield takeEvery("CHANGE_PHONE", enableButtonLoader);
  yield takeEvery("CHANGE_PHONE_SUCCESS", disableButtonLoader);
  yield takeEvery("CHANGE_PHONE_FAIL", disableButtonLoader);

  yield takeEvery("VERIFY_PHONE", enableButtonLoader);
  yield takeEvery("VERIFY_PHONE_SUCCESS", disableButtonLoader);
  yield takeEvery("VERIFY_PHONE_FAIL", disableButtonLoader);

  yield takeEvery("CHANGE_PASSWORD", enableButtonLoader);
  yield takeEvery("CHANGE_PASSWORD_SUCCESS", disableButtonLoader);
  yield takeEvery("CHANGE_PASSWORD_FAIL", disableButtonLoader);
}
