import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteApiKey, getApiKeys, getCompanyShortInfo, postApiKey } from "../companiesActions";
import { toastErrors } from "../../../core/helpers/toastErrors";
import urls from "../../../core/urls";
import BackLink from "../../../Shared/BackLink";
import DefaultButton from "../../../Shared/Buttons/DefaultButton";
import SkeletonMUI from "../../../Shared/SkeletonMUI";
import NotFound from "../../../Shared/NotFound";
import AddApiKeyDialog from "../Dialogs/AddApiKeyDialog";
import DeleteApiKeyDialog from "../Dialogs/DeleteApiKeyDialog";
import { ReactComponent as AddIcon } from "../../../assets/images/add-white.svg";
import { ReactComponent as CloseIcon } from "../../../assets/images/close.svg";
import "./ApiKeys.scss";

const ApiKeys = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    loading,
    buttonLoading,
    shortInfo: { account_name },
    apiKeys
  } = useSelector(({ companies }) => companies);

  const getApiKeysData = () => {
    dispatch(getCompanyShortInfo(id));
    dispatch(getApiKeys(id)).then(res => res?.error && toastErrors(res));
  };

  useEffect(() => {
    getApiKeysData();
  }, []);

  const [addKey, setAddKey] = useState(false);
  const [addedKey, setAddedKey] = useState(null);
  const addKeyOpen = () => setAddKey(true);
  const addKeyClose = () => {
    setAddKey(false);
    if (addedKey) {
      const timer = setTimeout(() => setAddedKey(null), 500);
      return () => clearTimeout(timer);
    }
  };
  const addKeyConfirm = name => () => {
    dispatch(postApiKey(id, name)).then(res => {
      if (res?.payload?.status === 201) {
        getApiKeysData();
        setAddedKey(res?.payload?.data);
      } else {
        toastErrors(res);
      }
    });
  };

  const [deleteKey, setDeleteKey] = useState({
    open: false,
    id: "",
    name: ""
  });
  const deleteKeyOpen = data => () => {
    setDeleteKey({
      ...deleteKey,
      ...data,
      open: true
    });
  };
  const deleteKeyClose = () => {
    setDeleteKey({
      ...deleteKey,
      open: false
    });
  };
  const deleteKeyConfirm = () => {
    dispatch(deleteApiKey(id, deleteKey?.id)).then(res => {
      if (res?.payload?.status === 204) {
        getApiKeysData();
        deleteKeyClose();
      } else {
        toastErrors(res);
      }
    });
  };

  return (
    <div className="api-keys page-wrap">
      <BackLink href={urls.companiesUrl.path} text="Companies" />
      <h1 className="page-title">
        {urls.apiKeysUrl.name} <span className="page-title__inner">of {account_name && account_name}</span>
      </h1>

      <div className="api-keys__section section-wrap">
        <header className="api-keys__header">
          <DefaultButton className="invite-btn" onClick={addKeyOpen}>
            <AddIcon className="add-icon" />
            <span>Add new</span>
          </DefaultButton>
        </header>

        {apiKeys?.length ? (
          <table className="api-keys__table table full-width">
            <thead>
              <tr>
                <th className="w-30">Name</th>
                <th className="w-70">Key</th>
              </tr>
            </thead>
            <tbody>
              {apiKeys?.map(({ id, prefix, name }) => {
                return (
                  <tr key={id}>
                    <td className="w-30">
                      <SkeletonMUI maxWidth={60} height={20} loading={loading}>
                        <b className="truncate-ellipsis">{name && name}</b>
                      </SkeletonMUI>
                    </td>
                    <td className="w-70">
                      <SkeletonMUI maxWidth={124} height={20} loading={loading}>
                        <span className="truncate-ellipsis">{prefix && `${prefix}********`}</span>
                      </SkeletonMUI>
                      <SkeletonMUI
                        className="api-keys__del-btn"
                        variant="rect"
                        width={16}
                        height={16}
                        loading={loading}
                      >
                        <button className="api-keys__del-btn" type="button" onClick={deleteKeyOpen({ id, name })}>
                          <CloseIcon />
                        </button>
                      </SkeletonMUI>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <NotFound noData small title="No API keys" />
        )}
      </div>

      <AddApiKeyDialog
        open={addKey}
        onCLose={addKeyClose}
        loading={buttonLoading}
        onConfirm={addKeyConfirm}
        addedKey={addedKey}
      />
      <DeleteApiKeyDialog
        open={deleteKey.open}
        onCLose={deleteKeyClose}
        keyName={deleteKey.name}
        loading={buttonLoading}
        onConfirm={deleteKeyConfirm}
      />
    </div>
  );
};

export default ApiKeys;
