import React from "react";
import Avatar from "../Avatar";
import Tooltip from "../Tooltip";
import { ReactComponent as RemoveIcon } from "../../assets/images/close.svg";
import { ReactComponent as EmailIcon } from "../../assets/images/invited-avatar.svg";
import { ReactComponent as PhoneIcon } from "../../assets/images/invited-avatar-2.svg";

const AutocompleteChip = ({ data, onRemoveClick }) => {
  const { id, first_name, last_name, email, phone_number, photo, newItem, error, warning } = data;

  let title = `${first_name} ${last_name}`;
  if ((newItem || (!first_name && !last_name)) && (email || phone_number)) title = email || phone_number;

  let subtitle = email || phone_number;
  if ((newItem || (!first_name && !last_name)) && email) subtitle = "invite via email";
  if ((newItem || (!first_name && !last_name)) && phone_number) subtitle = "invite via phone";

  return (
    <div
      key={id}
      className={`autocomplete-chip${error ? " autocomplete-chip--error" : ""}${
        warning ? " autocomplete-chip--warning" : ""
      }`}
    >
      <div className="autocomplete-chip__inner">
        {!newItem && (first_name || last_name) && (
          <Avatar
            className="autocomplete-chip__avatar"
            firstName={first_name}
            lastName={last_name}
            size={32}
            src={photo}
          />
        )}
        {(newItem || (!first_name && !last_name)) && email && <EmailIcon className="autocomplete-chip__icon" />}
        {(newItem || (!first_name && !last_name)) && phone_number && <PhoneIcon className="autocomplete-chip__icon" />}

        <div className="autocomplete-chip__text-wrap">
          <span className="autocomplete-chip__title">{title}</span>
          <span className="autocomplete-chip__subtitle">{subtitle}</span>
          {(error || warning) && (
            <Tooltip className="autocomplete-chip__tooltip" text={error || warning}>
              <span className="visually-hidden">{error || warning}</span>
            </Tooltip>
          )}
        </div>
      </div>
      <button className="autocomplete-chip__remove" type="button" onClick={onRemoveClick}>
        <RemoveIcon className="good-hover" />
      </button>
    </div>
  );
};

export default AutocompleteChip;
