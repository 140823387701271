const APP = {
  LOADING: "LOADING",
  BUTTON_LOADING: "BUTTON_LOADING",

  ERROR_SNACK_OPEN: "ERROR_SNACK_OPEN",
  ERROR_SNACK_CLOSE: "ERROR_SNACK_CLOSE",

  SUCCESS_SNACK_OPEN: "SUCCESS_SNACK_OPEN",
  SUCCESS_SNACK_CLOSE: "SUCCESS_SNACK_CLOSE",

  CHANGE_PANEL_STATE: "CHANGE_PANEL_STATE",

  SET_CONFIRMATION_DIALOG: "SET_CONFIRMATION_DIALOG",
  CLOSE_CONFIRMATION_DIALOG: "CLOSE_CONFIRMATION_DIALOG",

  PROFILE: "PROFILE",
  PROFILE_SUCCESS: "PROFILE_SUCCESS",
  PROFILE_FAIL: "PROFILE_FAIL"
};

export default APP;
