import React from "react";
import { ReactComponent as RemoveIcon } from "../../../assets/images/close.svg";
import "./UserDeviceCard.scss";

const UserDeviceCard = ({ name, type, number, relationType, access, owner, onRemove }) => {
  return (
    <article className="user-device-card">
      {name && <span className="user-device-card__name truncate-ellipsis">{name}</span>}
      {type && <span className="user-device-card__info truncate-ellipsis">{type}</span>}
      {number && <span className="user-device-card__info truncate-ellipsis">{number}</span>}

      {(relationType || access) && (
        <div className="user-device-card__access">
          {relationType === "admin" ? <span className="is-admin">Admin</span> : "User"}
          {access && ` • ${access}`}
        </div>
      )}

      {owner && (
        <div className="user-device-card__owner">
          Owned by{" "}
          <span>
            {owner?.first_name} {owner?.last_name}
          </span>
        </div>
      )}

      <button className="user-device-card__remove-btn" type="button" aria-label="Remove device" onClick={onRemove}>
        <RemoveIcon />
      </button>
    </article>
  );
};

export default UserDeviceCard;
