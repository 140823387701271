import React from "react";
import Switch from "@material-ui/core/Switch";
import { FormControlLabel } from "@material-ui/core";

import "./SwitchMUI.scss";

const SwitchMUI = ({
  labelClassName = "",
  switchClassName = "",
  checked,
  disabled = false,
  color = "primary",
  onChange,
  inputRef,
  name,
  label
}) => {
  return (
    <FormControlLabel className={`switch-mui__label ${labelClassName}`} control={
      <Switch 
        className={switchClassName}
        classes={{
          root: "switch-mui",
          switchBase: "switch-mui__base",
          track: "switch-mui__track",
          thumb: "switch-mui__thumb",
          checked: "switch-mui--checked",
          disabled: "switch-mui--disabled",
          colorPrimary: "switch-mui-color--primary",
          colorSecondary: "switch-mui-color--secondary"
        }}
        name={name}
        color={color}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        inputRef={inputRef}
      />
    } label={label} />
  );
};

export default SwitchMUI;
