import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { MenuItem } from "@material-ui/core";
import { getProfile } from "../../../core/actions";
import { getCountries } from "../../Authorization/actions";
import { getCompany, patchCompany, postCompany } from "../companiesActions";
import urls from "../../../core/urls";
import { manageCompanySchema } from "../../../core/helpers/validations";
import { toastErrors } from "../../../core/helpers/toastErrors";
import notifySuccess from "../../../core/helpers/notifySuccess";
import BackLink from "../../../Shared/BackLink";
import InputField from "../../../Shared/InputField";
import Fieldset from "../../../Shared/Fieldset";
import DefaultButton from "../../../Shared/Buttons/DefaultButton";
import "./ManageCompany.scss";

const ManageCompany = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { loading, buttonLoading, company } = useSelector(({ companies }) => companies);
  const { country_phone_codes } = useSelector(({ authorization }) => authorization.countries);

  const getCompanyData = async id => {
    const res = await dispatch(getCompany(id));
    if (res?.error) history.push(urls.companiesUrl.path);
  };

  useEffect(() => {
    dispatch(getCountries());
    id && getCompanyData(id);
  }, []);

  const {
    reset,
    control,
    handleSubmit,
    setError,
    setValue,
    formState: { errors, isValid, isDirty }
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(manageCompanySchema),
    shouldFocusError: true,
    defaultValues: {
      account_name: "",
      full_company_name: "",
      description: "",
      country_code: "",
      city: "",
      address: "",
      zip_code: "",
      registration_code: "",
      vat_code: "",
      contact_email: "",
      contact_phone: "",
      invoice_email: ""
    }
  });

  useEffect(() => {
    if (id && company) {
      setValue("account_name", company?.account_name);
      setValue("full_company_name", company?.full_company_name);
      setValue("description", company?.description);
      setValue("country_code", company?.country_code);
      setValue("city", company?.city);
      setValue("address", company?.address);
      setValue("zip_code", company?.zip_code);
      setValue("registration_code", company?.registration_code);
      setValue("vat_code", company?.vat_code);
      setValue("contact_email", company?.contact_email);
      setValue("contact_phone", company?.contact_phone);
      setValue("invoice_email", company?.invoice_email);
    } else {
      reset();
    }
  }, [id, company]);

  const onSubmit = async data => {
    let res;
    id ? (res = await dispatch(patchCompany(id, data))) : (res = await dispatch(postCompany(data)));
    const errors = res.error?.response.data;

    if (res?.payload) {
      dispatch(getProfile());
      notifySuccess(`Company successfully ${id ? "edited" : "created"}`);
      history.push(urls.companiesUrl.path);
    }
    if (res?.error) {
      toastErrors(res);

      errors.account_name && setError("account_name", { type: "manual", message: errors.account_name[0].message });
      errors.full_company_name &&
        setError("full_company_name", {
          type: "manual",
          message: errors.full_company_name[0].message
        });
      errors.description && setError("description", { type: "manual", message: errors.description[0].message });
      errors.country_code && setError("country_code", { type: "manual", message: errors.country_code[0].message });
      errors.city && setError("city", { type: "manual", message: errors.city[0].message });
      errors.address && setError("address", { type: "manual", message: errors.address[0].message });
      errors.zip_code && setError("zip_code", { type: "manual", message: errors.zip_code[0].message });
      errors.registration_code &&
        setError("registration_code", {
          type: "manual",
          message: errors.registration_code[0].message
        });
      errors.vat_code && setError("vat_code", { type: "manual", message: errors.vat_code[0].message });
      errors.contact_email && setError("contact_email", { type: "manual", message: errors.contact_email[0].message });
      errors.contact_phone && setError("contact_phone", { type: "manual", message: errors.contact_phone[0].message });
      errors.invoice_email && setError("invoice_email", { type: "manual", message: errors.invoice_email[0].message });
    }
  };

  return (
    <div className="manage-company page-wrap">
      <BackLink href={urls.companiesUrl.path} text={urls.companiesUrl.name} />
      <h1 className="page-title">
        {id ? "Edit company " : urls.addCompanyUrl.name}
        {id && !loading && company?.account_name && <span className="page-title__inner">{company?.account_name}</span>}
      </h1>
      <form className="manage-company__section section-wrap" onSubmit={handleSubmit(onSubmit)}>
        {id && loading ? (
          <h2 className="manage-company__loading loading-text">Loading...</h2>
        ) : (
          <>
            <Fieldset label="General info">
              <div className="manage-company__fields">
                <Controller
                  name="account_name"
                  control={control}
                  render={({ field }) => (
                    <InputField
                      className="max-w-224"
                      label="Account name"
                      error={errors.account_name?.message}
                      inputProps={field}
                    />
                  )}
                />
                <Controller
                  name="full_company_name"
                  control={control}
                  render={({ field }) => (
                    <InputField
                      className="max-w-472"
                      label="Full company name"
                      error={errors.full_company_name?.message}
                      inputProps={field}
                    />
                  )}
                />
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <InputField
                      label="Company description"
                      multiline
                      rows={3}
                      error={errors.description?.message}
                      inputProps={field}
                    />
                  )}
                />
              </div>
            </Fieldset>

            <Fieldset label="Location">
              <div className="manage-company__fields">
                <Controller
                  name="country_code"
                  control={control}
                  render={({ field }) => (
                    <InputField
                      className="max-w-348"
                      label="Country / Region"
                      select
                      error={errors.country_code?.message}
                      inputProps={field}
                    >
                      {country_phone_codes?.length > 0 ? (
                        country_phone_codes.map(({ country_name, country_iso_code }) => (
                          <MenuItem key={country_iso_code} value={country_iso_code}>
                            {country_name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem disabled>No options</MenuItem>
                      )}
                    </InputField>
                  )}
                />
                <Controller
                  name="city"
                  control={control}
                  render={({ field }) => (
                    <InputField className="max-w-348" label="City" error={errors.city?.message} inputProps={field} />
                  )}
                />
                <Controller
                  name="address"
                  control={control}
                  render={({ field }) => (
                    <InputField label="Company address" error={errors.address?.message} inputProps={field} />
                  )}
                />
                <Controller
                  name="zip_code"
                  control={control}
                  render={({ field }) => (
                    <InputField
                      className="max-w-224"
                      label="Zip code"
                      error={errors.zip_code?.message}
                      inputProps={field}
                    />
                  )}
                />
                <Controller
                  name="registration_code"
                  control={control}
                  render={({ field }) => (
                    <InputField
                      className="max-w-224"
                      label="Registration code"
                      error={errors.registration_code?.message}
                      inputProps={field}
                    />
                  )}
                />
                <Controller
                  name="vat_code"
                  control={control}
                  render={({ field }) => (
                    <InputField
                      className="max-w-224"
                      label="VAT code"
                      error={errors.vat_code?.message}
                      inputProps={field}
                    />
                  )}
                />
              </div>
            </Fieldset>

            <Fieldset label="Contacts">
              <div className="manage-company__fields">
                <Controller
                  name="contact_email"
                  control={control}
                  render={({ field }) => (
                    <InputField
                      className="max-w-472"
                      label="Email"
                      type="email"
                      error={errors.contact_email?.message}
                      inputProps={field}
                    />
                  )}
                />
                <Controller
                  name="contact_phone"
                  control={control}
                  render={({ field }) => (
                    <InputField
                      className="max-w-224"
                      label="Phone number"
                      type="tel"
                      error={errors.contact_phone?.message}
                      inputProps={field}
                    />
                  )}
                />
                <Controller
                  name="invoice_email"
                  control={control}
                  render={({ field }) => (
                    <InputField
                      className="max-w-472"
                      label="Invoice email"
                      type="email"
                      error={errors.invoice_email?.message}
                      inputProps={field}
                    />
                  )}
                />
              </div>
            </Fieldset>

            <DefaultButton disabled={!isValid || !isDirty || buttonLoading} loading={buttonLoading} formAction>
              {id ? "Save changes" : "Add company"}
            </DefaultButton>
          </>
        )}
      </form>
    </div>
  );
};

export default ManageCompany;
