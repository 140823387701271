import { REMOTE_LINK } from "./remoteLinkActionTypes";

export function getDeviceStatus(token) {
  return {
    type: REMOTE_LINK.GET_DEVICE_STATUS,
    payload: {
      request: {
        url: `../../v1/device/remote/status?token=${token}`,
        method: "get"
      }
    }
  };
}

export function openLock(token, secondRelay=false) {
  return {
    type: REMOTE_LINK.OPEN_LOCK,
    payload: {
      request: {
        url: "../../v1/device/remote/open",
        method: "post",
        data: {
          token: token,
          second_relay: secondRelay
        }
      }
    }
  };
}
