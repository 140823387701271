import { POST_LOGIN, POST_LOGIN_FAIL, GET_COUNTRIES_SUCCESS } from "./actionTypes";

const INITIAL_STATE = {
    error: null,
    countries: {}
};

const authReducer = (state = INITIAL_STATE, { type, error, payload }) => {
    switch (type) {
        case POST_LOGIN:
            return { ...state, error: null };
        case POST_LOGIN_FAIL:
            return { ...state, error };
        case GET_COUNTRIES_SUCCESS:
            return {
                ...state,
                countries: payload?.data
            };
        default:
            return state;
    }
};

export default authReducer;
