import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import urls from "../../../core/urls";
import { deleteCompanyUser, getCompanyShortInfo, getCompanyUsers } from "../companiesActions";
import { addQueryFilter, handlePage, onChangeSearch, onTabClick } from "../../../core/helpers/functions";
import { toastErrors } from "../../../core/helpers/toastErrors";
import BackLink from "../../../Shared/BackLink";
import InputField from "../../../Shared/InputField";
import DefaultButton from "../../../Shared/Buttons/DefaultButton";
import { TabItem, Tabs } from "../../../Shared/Tabs";
import OwnersAdminsTable from "../OwnersAdminsTable";
import Pagination from "../../../Shared/Pagination";
import NotFound from "../../../Shared/NotFound";
import RemoveUserAccessDialog from "../Dialogs/RemoveUserAccessDialog";
import { ReactComponent as AddIcon } from "../../../assets/images/add-white.svg";
import "./OwnersAdmins.scss";

const OwnersAdmins = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    buttonLoading,
    shortInfo: { account_name, my_role },
    activeUsers,
    invitedUsers
  } = useSelector(({ companies }) => companies);

  const [firstLoad, setFirstLoad] = useState(true);
  const [search, setSearch] = useState("");
  const [currentTab, setCurrentTab] = useState(0);
  const [sortParams, setSortParams] = useState({ sort: "", order: "" });
  const [page, setPage] = useState(0);

  const sortList = [
    { value: "full_name", label: "User", className: "w-22" },
    { value: "email", label: "Email", className: "w-30" },
    { value: "phone_number", label: "Phone number", className: "w-22" },
    { value: "role", label: "Role", className: "w-26" }
  ];

  const filters = {
    page: page + 1,
    search,
    ordering: sortParams.order + sortParams.sort
  };

  const tabs = [
    {
      label: "Active",
      count: activeUsers?.results?.length,
      users: firstLoad ? [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }] : activeUsers?.results,
      pages: activeUsers?.pages
    },
    {
      label: "Invited",
      count: invitedUsers?.results?.length,
      users: invitedUsers?.results,
      pages: invitedUsers?.pages
    }
  ];

  const getOwnersAndAdminsData = () => {
    if (firstLoad) {
      dispatch(getCompanyShortInfo(id));
      dispatch(addQueryFilter(getCompanyUsers(id), filters)).then(res => res?.payload?.status && setFirstLoad(false));
      dispatch(addQueryFilter(getCompanyUsers(id, "invited"), filters));
    } else {
      if (currentTab === 0) {
        dispatch(addQueryFilter(getCompanyUsers(id), filters));
      }
      if (currentTab === 1) {
        dispatch(addQueryFilter(getCompanyUsers(id, "invited"), filters));
      }
    }
  };

  const [removeAccess, setRemoveAccess] = useState({
    open: false,
    userId: "",
    username: "",
    email: "",
    phone: ""
  });
  const removeAccessOpen = data => {
    setRemoveAccess({
      ...removeAccess,
      ...data,
      open: true
    });
  };
  const removeAccessClose = () => {
    setRemoveAccess({
      ...removeAccess,
      open: false
    });
  };
  const removeAccessConfirm = () => {
    let access = "users";
    if (currentTab === 1) access = "invited";

    dispatch(deleteCompanyUser(id, removeAccess.userId, access)).then(res => {
      if (res?.payload?.status === 204) {
        getOwnersAndAdminsData();
        removeAccessClose();
      } else {
        toastErrors(res);
      }
    });
  };

  useEffect(() => {
    getOwnersAndAdminsData();
  }, [search, sortParams, page, currentTab]);

  return (
    <div className="owners-admins page-wrap">
      <BackLink href={urls.companiesUrl.path} text="Companies" />
      <h1 className="page-title">
        {urls.ownersAndAdminsUrl.name}{" "}
        <span className="page-title__inner">of {!firstLoad && account_name && account_name}</span>
      </h1>
      <div className="owners-admins__section section-wrap">
        <header className="owners-admins__header">
          <InputField
            className="max-w-276"
            label="Search users"
            type="search"
            fullWidth
            onChange={onChangeSearch(setSearch, 3, setPage)}
          />
          {my_role === "owner" && (
            <DefaultButton className="invite-btn" type="link" to={`${urls.inviteUsersToCompanyUrl.path}/${id}`}>
              <AddIcon className="add-icon" />
              <span>Invite users</span>
            </DefaultButton>
          )}
        </header>

        <Tabs className="mt-48" onTabClick={onTabClick(setCurrentTab, setPage)}>
          {tabs?.map(({ label, count, users, pages }, index) => (
            <TabItem key={index} index={index} label={label} counter={count}>
              {users?.length ? (
                <>
                  <OwnersAdminsTable
                    {...{
                      users,
                      myRole: my_role,
                      sortList,
                      sortParams,
                      setSortParams,
                      firstLoad,
                      onRemoveAccessOpen: removeAccessOpen
                    }}
                  />
                  {pages > 1 && (
                    <Pagination
                      className="owners-admins__pagination"
                      active={page}
                      onChange={handlePage(setPage)}
                      pageCount={pages}
                    />
                  )}
                </>
              ) : (
                <NotFound noData small title="No users" />
              )}
            </TabItem>
          ))}
        </Tabs>
      </div>

      <RemoveUserAccessDialog
        open={removeAccess.open}
        onCLose={removeAccessClose}
        revokeType={currentTab === 1}
        company={account_name}
        username={removeAccess.username || removeAccess.email || removeAccess.phone}
        loading={buttonLoading}
        onConfirm={removeAccessConfirm}
      />
    </div>
  );
};

export default OwnersAdmins;
