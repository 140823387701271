import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as CloseIcon } from "../../../assets/images/close.svg";
import "./DeviceGroupCard.scss";

const DeviceGroupCard = ({ title, titleType = "text", onTitleClick, type, number, users, devices, onRemove }) => {
  return (
    <div className="device-group-card">
      {title && titleType === "text" && <span className="device-group-card__title truncate-ellipsis">{title}</span>}
      {title && titleType === "button" && (
        <button
          className="device-group-card__title device-group-card__title--btn truncate-ellipsis"
          type="button"
          onClick={onTitleClick}
        >
          {title}
        </button>
      )}
      <span className="device-group-card__info truncate-ellipsis">
        {type && `${type} • `}
        {number}
        {devices && `${devices} device`}
        {users && `${users} users`}
      </span>
      {onRemove && (
        <button className="device-group-card__btn" type="button" onClick={onRemove}>
          <span className="visually-hidden">Remove</span>
          <CloseIcon />
        </button>
      )}
    </div>
  );
};

DeviceGroupCard.propTypes = {
  devices: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onRemove: PropTypes.func,
  onTitleClick: PropTypes.func,
  title: PropTypes.string,
  titleType: PropTypes.oneOf(["text", "button"]),
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  users: PropTypes.number
};

export default DeviceGroupCard;
