import React from "react";
import DialogMUI from "../../../Shared/DialogMUI";
import DefaultButton from "../../../Shared/Buttons/DefaultButton";
import "./Dialogs.scss";

const RemoveUserAccessDialog = ({ open, onCLose, revokeType, company, username, loading, onConfirm }) => {
  let title = "Remove access";
  if (revokeType) title = "Revoke invitation";

  return (
    <DialogMUI className="company-dialog" open={open} onClose={onCLose}>
      <h2 className="mb-32">{title}</h2>
      <p className="mb-24">
        Are you sure you want to {title.toLowerCase()} to <b>{company}</b> for{" "}
        <b>{username ? username : "this" + " user"}</b>?
      </p>

      <div className="btn-group">
        <DefaultButton className="gray-btn" disabled={loading} onClick={onCLose}>
          Cancel
        </DefaultButton>
        <DefaultButton loading={loading} disabled={loading} onClick={onConfirm}>
          {revokeType ? "Revoke" : "Remove"}
        </DefaultButton>
      </div>
    </DialogMUI>
  );
};

export default RemoveUserAccessDialog;
