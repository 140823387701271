import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet";
import RemoteLink from "../../../Features/RemoteLink";
import Header from "../../../layout/Header";
import urls from "../../urls";
import "react-toastify/dist/ReactToastify.css";
import "./styles.scss";


const RemoteLinkContainer = () => {
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <Header />
      <main className="remote-link-main">
        <Switch>
          <Route path={urls.remoteLinkUrl.path} exact component={RemoteLink} />
          <Route render={() => <Redirect to="/auth" />} />
        </Switch>
      </main>
      <ToastContainer />
    </>
  );
};

export default RemoteLinkContainer;
