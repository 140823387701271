export const COMPANIES = {
  POST_COMPANY: "POST_COMPANY",
  POST_COMPANY_SUCCESS: "POST_COMPANY_SUCCESS",
  POST_COMPANY_FAIL: "POST_COMPANY_FAIL",

  GET_COMPANIES: "GET_COMPANIES",
  GET_COMPANIES_SUCCESS: "GET_COMPANIES_SUCCESS",
  GET_COMPANIES_FAIL: "GET_COMPANIES_FAIL",

  SET_CURRENT_COMPANY: "SET_CURRENT_COMPANY",
  SET_CURRENT_COMPANY_SUCCESS: "SET_CURRENT_COMPANY_SUCCESS",
  SET_CURRENT_COMPANY_FAIL: "SET_CURRENT_COMPANY_FAIL",

  GET_COMPANY: "GET_COMPANY",
  GET_COMPANY_SUCCESS: "GET_COMPANY_SUCCESS",
  GET_COMPANY_FAIL: "GET_COMPANY_FAIL",

  PATCH_COMPANY: "PATCH_COMPANY",
  PATCH_COMPANY_SUCCESS: "PATCH_COMPANY_SUCCESS",
  PATCH_COMPANY_FAIL: "PATCH_COMPANY_FAIL",

  GET_COMPANY_SHORT_INFO: "GET_COMPANY_SHORT_INFO",
  GET_COMPANY_SHORT_INFO_SUCCESS: "GET_COMPANY_SHORT_INFO_SUCCESS",
  GET_COMPANY_SHORT_INFO_FAIL: "GET_COMPANY_SHORT_INFO_FAIL",

  GET_COMPANY_ACTIVE_USERS: "GET_COMPANY_ACTIVE_USERS",
  GET_COMPANY_ACTIVE_USERS_SUCCESS: "GET_COMPANY_ACTIVE_USERS_SUCCESS",
  GET_COMPANY_ACTIVE_USERS_FAIL: "GET_COMPANY_ACTIVE_USERS_FAIL",

  DELETE_COMPANY_USER: "DELETE_COMPANY_USER",
  DELETE_COMPANY_USER_SUCCESS: "DELETE_COMPANY_USER_SUCCESS",
  DELETE_COMPANY_USER_FAIL: "DELETE_COMPANY_USER_FAIL",

  GET_COMPANY_INVITED_USERS: "GET_COMPANY_INVITED_USERS",
  GET_COMPANY_INVITED_USERS_SUCCESS: "GET_COMPANY_INVITED_USERS_SUCCESS",
  GET_COMPANY_INVITED_USERS_FAIL: "GET_COMPANY_INVITED_USERS_FAIL",

  POST_INVITE_COMPANY: "POST_INVITE_COMPANY",
  POST_INVITE_COMPANY_SUCCESS: "POST_INVITE_COMPANY_SUCCESS",
  POST_INVITE_COMPANY_FAIL: "POST_INVITE_COMPANY_FAIL",

  GET_API_KEYS: "GET_API_KEYS",
  GET_API_KEYS_SUCCESS: "GET_API_KEYS_SUCCESS",
  GET_API_KEYS_FAIL: "GET_API_KEYS_FAIL",

  POST_API_KEY: "POST_API_KEY",
  POST_API_KEY_SUCCESS: "POST_API_KEY_SUCCESS",
  POST_API_KEY_FAIL: "POST_API_KEY_FAIL",

  DELETE_API_KEY: "DELETE_API_KEY",
  DELETE_API_KEY_SUCCESS: "DELETE_API_KEY_SUCCESS",
  DELETE_API_KEY_FAIL: "DELETE_API_KEY_FAIL"
};
