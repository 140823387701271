import React from "react";
import "./TableHead.scss";

const TableHead = ({ sortList, sortParams, setSortParams }) => {
  const handleSort = (value, sortParams, setSortParams) => () => {
    let order = "";
    if (value === sortParams.sort) {
      if (!sortParams.order) order = "-";
    }
    setSortParams({ ...sortParams, sort: value, order });
  };

  if (!sortList) return null;
  return (
    <thead>
      <tr>
        {sortList.map(({ value, label, className }, index) => {
          let sortBtnClass = ["sort-btn"];
          if (value === sortParams.sort) {
            if (sortParams.order === "-") sortBtnClass.push("sort-btn--down");
            if (!sortParams.order) sortBtnClass.push("sort-btn--up");
          }

          return (
            <th key={index} className={className}>
              {value && (
                <button
                  className={sortBtnClass.join(" ")}
                  type="button"
                  onClick={handleSort(value, sortParams, setSortParams)}
                >
                  {label}
                </button>
              )}
              {!value && label}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default TableHead;