import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import urls from "../../core/urls";
import { getProfile } from "../../core/actions";
import { getCompanies, setCurrentCompany } from "./companiesActions";
import { addQueryFilter, handlePage, onChangeSearch } from "../../core/helpers/functions";
import InputField from "../../Shared/InputField";
import DefaultButton from "../../Shared/Buttons/DefaultButton";
import CompanyCard from "./CompanyCard";
import Pagination from "../../Shared/Pagination";
import NotFound from "../../Shared/NotFound";
import SwitchCompanyDialog from "./Dialogs/SwitchCompanyDialog";
import { ReactComponent as AddIcon } from "../../assets/images/add-white.svg";
import "./Companies.scss";

const Companies = () => {
  const dispatch = useDispatch();
  const { loading, buttonLoading, companies } = useSelector(({ companies }) => companies);

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const filters = {
    page: page + 1,
    search
  };

  const getData = () => dispatch(addQueryFilter(getCompanies, filters));
  useEffect(() => {
    getData();
  }, [page, search]);

  const [selectedCompany, setSelectedCompany] = useState({});
  const [switchError, setSwitchError] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const openConfirmDialog = (id, name) => {
    setSelectedCompany({ id, name });
    setConfirmDialog(true);
  };
  const closeConfirmDialog = () => {
    setSwitchError(false);
    setConfirmDialog(false);
  };

  const switchCompany = async () => {
    const res = await dispatch(setCurrentCompany(selectedCompany.id));
    if (res?.payload) {
      closeConfirmDialog();
      dispatch(getProfile());
      getData();
    }
    if (res?.error) setSwitchError(true);
  };

  return (
    <div className="companies page-wrap">
      <h1 className="page-title">{urls.companiesUrl.name}</h1>
      <div className="companies__section section-wrap">
        <header className="companies__header">
          <InputField
            className="min-w-276"
            label="Search companies"
            type="search"
            onChange={onChangeSearch(setSearch, 2, setPage)}
          />
          <DefaultButton className="invite-btn" type="link" to={urls.addCompanyUrl.path}>
            <AddIcon className="add-icon" />
            <span>Add new</span>
          </DefaultButton>
        </header>

        {loading && <h2 className="loading-text">Loading...</h2>}
        {!loading && companies?.results?.length > 0 && (
          <>
            <div className="companies__list">
              {companies?.results.map(({ id, account_name, description, is_current_company, role }) => (
                <CompanyCard
                  key={id}
                  id={id}
                  title={account_name}
                  desc={description}
                  current={is_current_company}
                  role={role}
                  onSwitch={openConfirmDialog}
                />
              ))}
            </div>
            {companies?.pages > 1 && (
              <Pagination active={page} onChange={handlePage(setPage)} pageCount={companies?.pages} />
            )}
          </>
        )}
        {!loading && !companies?.results?.length && <NotFound noData small title="No companies to show" />}
      </div>

      <SwitchCompanyDialog
        open={confirmDialog}
        onCLose={closeConfirmDialog}
        name={selectedCompany?.name}
        loading={buttonLoading}
        error={switchError}
        onConfirm={switchCompany}
      />
    </div>
  );
};

export default Companies;
