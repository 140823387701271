import { AUTH_KEYCLOAK } from "./constants";
import keycloak from "../keycloak";

export function isKeycloakEnabled() {
  return process.env.REACT_APP_FEATURE_KEYCLOAK == "1";
}

function getAccessTokenLocalStorage() {
  if (localStorage.token || localStorage.token_res) {
    let token = localStorage.token ? localStorage.token : localStorage.token_res;
    return token;
  }
  return null;
}

export function getAccessToken() {
  if (isKeycloakEnabled() && isActiveKeycloakSession()) {
    return keycloak.updateToken(10).then(() => {
      return keycloak.token;
    }).catch((e) => {
      console.log("failed to update token");
      console.log(e);
      return getAccessTokenLocalStorage();
    });
  }
  return getAccessTokenLocalStorage();
}

export function isActiveKeycloakSession() {
  const sessionType = localStorage.getItem("session-type");
  return sessionType === AUTH_KEYCLOAK;
}

export function isLoggedIn() {
  return isActiveKeycloakSession() || !!localStorage.getItem("refresh");
}

export async function signOut(skipIdpLogout = false) {
  localStorage.removeItem("session-type");
  localStorage.removeItem("token");
  localStorage.removeItem("refresh");

  if (isKeycloakEnabled() && !skipIdpLogout) {
    localStorage.removeItem("keycloak-refresh-token");
    localStorage.removeItem("keycloak-access-token");
    return keycloak.logout();
  }
}
