import React from "react";
import DialogMUI from "../../../Shared/DialogMUI";
import DefaultButton from "../../../Shared/Buttons/DefaultButton";
import "./Dialogs.scss";

const InvitesSentDialog = ({ single, open, onCLose }) => {
  let ending = single ? "" : "s";
  return (
    <DialogMUI className="users-dialog t-center" open={open} onClose={onCLose}>
      <h2 className="mt-32">
        Invite{ending} {single ? "was" : "were"} sent successfully
      </h2>
      <DefaultButton className="users-dialog__btn" onClick={onCLose}>
        Ok, great
      </DefaultButton>
    </DialogMUI>
  );
};

export default InvitesSentDialog;
