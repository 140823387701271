import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "../../core/actions";
import { deleteDevice, getDevices, getOptions, openLock } from "./devicesActions";
import {
  addQueryFilter,
  handlePage,
  onChangeSearch,
  onChangeSort,
  onTabClick,
  transformForSelect
} from "../../core/helpers/functions";
import { defaultSort } from "../../core/helpers/constants";
import { toastErrors } from "../../core/helpers/toastErrors";
import InputField from "../../Shared/InputField";
import SelectComponent from "../../Shared/SelectComponent";
import DevicesTable from "./DevicesTable/DevicesTable";
import { TabItem, Tabs } from "../../Shared/Tabs";
import Pagination from "../../Shared/Pagination";
import NotFound from "../../Shared/NotFound";
import TransferOwnershipDialog from "./Dialogs/TransferOwnershipDialog";
import RemoveDeviceDialog from "./Dialogs/RemoveDeviceDialog";
import "./Devices.scss";

const Devices = () => {
  const dispatch = useDispatch();
  const {
    buttonLoading,
    ownedByMe,
    sharedAsAdmin,
    sharedAsUser,
    options: { types }
  } = useSelector(({ devices }) => devices);
  const { profile } = useSelector(({ app }) => app);

  const [firstLoad, setFirstLoad] = useState(true);
  const [search, setSearch] = useState("");
  const [currentTab, setCurrentTab] = useState(0);
  const [page, setPage] = useState(0);
  const [type, setType] = useState(defaultSort);
  const [sortParams, setSortParams] = useState({ sort: "", order: "" });
  const typeList = transformForSelect(types, defaultSort);

  const filters = {
    page: page + 1,
    search,
    device_type: type.value,
    ordering: sortParams.order + sortParams.sort
  };

  const tabs = [
    {
      label: "Owned by me",
      count: ownedByMe?.count,
      devices: ownedByMe?.results,
      pages: ownedByMe?.pages,
      accessType: "owner"
    },
    {
      label: "Shared to me as admin",
      count: sharedAsAdmin?.count,
      devices: sharedAsAdmin?.results,
      pages: sharedAsAdmin?.pages,
      accessType: "admin"
    },
    {
      label: sharedAsUser?.results && "Shared to me as user",
      count: sharedAsUser?.count,
      devices: sharedAsUser?.results,
      pages: sharedAsUser?.pages,
      accessType: "user"
    }
  ];

  useEffect(() => {
    dispatch(getOptions());
  }, []);

  useEffect(() => {
    if (firstLoad) {
      dispatch(addQueryFilter(getDevices(), filters)).then(res => {
        if (res?.payload?.data) {
          dispatch(addQueryFilter(getDevices("admin", false), filters));
          dispatch(getProfile()).then(res => {
            if (!res?.payload?.data?.current_company) {
              dispatch(addQueryFilter(getDevices("user", false), filters));
            }
          });
        }
      }).then(() => setFirstLoad(false));
    } else {
      if (currentTab === 0) {
        dispatch(addQueryFilter(getDevices(), filters));
      }
      if (currentTab === 1) {
        dispatch(addQueryFilter(getDevices("admin"), filters));
      }
      if (currentTab === 2) {
        dispatch(addQueryFilter(getDevices("user"), filters));
      }
    }
  }, [search, type, page, currentTab, sortParams]);

  const [selectedDevice, setSelectedDevice] = useState({});
  const [transferDialog, setTransferDialog] = useState(false);
  const openTransferDialog = (id, name) => {
    setSelectedDevice({ id, name });
    setTransferDialog(true);
  };
  const closeTransferDialog = () => setTransferDialog(false);

  const [removeDialog, setRemoveDialog] = useState(false);
  const openRemoveDialog = (id, name) => {
    setSelectedDevice({ id, name });
    setRemoveDialog(true);
  };
  const closeRemoveDialog = () => setRemoveDialog(false);

  const removeDevice = async password => {
    const res = await dispatch(deleteDevice(selectedDevice.id, password));
    if (res?.payload) {
      closeRemoveDialog();
      dispatch(addQueryFilter(getDevices(), filters));
    }
    if (res?.error) toastErrors(res);
  };

  const openDevice = (device, openSecondRelay) => {
    dispatch(openLock(profile.id, device, localStorage.token, openSecondRelay));
  };

  return (
    <div className="devices page-wrap">
      <h1 className="page-title">Devices</h1>
      <div className="devices__section section-wrap">
        <header className="devices__header">
          <InputField
            className="min-w-276"
            label="Search devices"
            type="search"
            onChange={onChangeSearch(setSearch, 3, setPage)}
          />
          {(currentTab === 0 || currentTab === 1) && (
            <SelectComponent label="Type" options={typeList} value={type} onChange={onChangeSort(setType, setPage)} />
          )}
        </header>

        <Tabs className="mt-48" onTabClick={onTabClick(setCurrentTab, setPage)}>
          {tabs &&
            tabs.map(({ label, count, devices, pages, accessType }, index) => (
              <TabItem key={index} index={index} label={label} counter={count}>
                {firstLoad || devices?.length ? (
                  <>
                    <div className="devices__list">
                      <DevicesTable 
                        devices={devices}
                        accessType={accessType}
                        onRemoteOpen={openDevice}
                        onTransfer={openTransferDialog}
                        onRemove={openRemoveDialog}
                        firstLoad={firstLoad}
                        sortParams={sortParams}
                        setSortParams={setSortParams}
                      />
                    </div>
                    {pages > 1 && (
                      <Pagination
                        className="ml-16 mr-16"
                        active={page}
                        onChange={handlePage(setPage)}
                        pageCount={pages}
                      />
                    )}
                  </>
                ) : (
                  <NotFound noData small title="No devices" />
                )}
              </TabItem>
            ))}
        </Tabs>
      </div>

      <TransferOwnershipDialog
        open={transferDialog}
        onCLose={closeTransferDialog}
        deviceId={selectedDevice?.id}
        deviceName={selectedDevice?.name}
        onSuccess={() => dispatch(addQueryFilter(getDevices(), filters))}
      />
      <RemoveDeviceDialog
        open={removeDialog}
        onCLose={closeRemoveDialog}
        name={selectedDevice?.name}
        loading={buttonLoading}
        onConfirm={removeDevice}
      />
    </div>
  );
};

export default Devices;
