import React, { useEffect, useState, useRef } from "react";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ClickAwayListener } from "@material-ui/core";
import urls from "../../../core/urls";
import DefaultButton from "../../../Shared/Buttons/DefaultButton";
import Tooltip from "../../../Shared/Tooltip";
import { ReactComponent as MoreIcon } from "../../../assets/images/more.svg";
import { ReactComponent as UserIcon } from "../../../assets/images/user.svg";
import { ReactComponent as EditIcon } from "../../../assets/images/edit.svg";
import { ReactComponent as TransferIcon } from "../../../assets/images/transfer.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/images/delete.svg";
import "./DevicesTableRow.scss";

const REMOTE_ACCESS_UNSUPPORTED = 1;
const REMOTE_ACCESS_DISABLED = 2;
const REMOTE_ACCESS_HAS_EXTENSIONS = 3;
const REMOTE_ACCESS_NO_DIGITAL_KEY = 4;
const REMOTE_ACCESS_OFFLINE = 5;
const REMOTE_ACCESS_EXPIRED = 6;
const REMOTE_ACCESS_READY = 7;
const REMOTE_ACCESS_OPENING = 8;
const REMOTE_ACCESS_OPENING_SELF = 9;
const REMOTE_ACCESS_OPEN = 10;

const DevicesTableRow = ({
                           id,
                           deviceName,
                           type,
                           serialNumber,
                           accessType,
                           remoteAccess,
                           digitalKey,
                           online,
                           closingTime,
                           onUnlock,
                           onUnlockSecondRelay,
                           onTransfer,
                           onRemove,
                           hasExtensions
                         }) => {
  const [more, setMore] = useState(false);
  const openMore = () => setMore(true);
  const closeMore = () => setMore(false);
  const { lastOpenedLock, openingLock } = useSelector(({ devices }) => devices);

  const [counter, setCounter] = useState(0);

  const didMount = useRef(false);
  useEffect(() => {
    if (didMount.current) {
      if (lastOpenedLock === serialNumber) {
        setCounter(closingTime || 5);
      }
    } else {
      didMount.current = true;
    }
  }, [lastOpenedLock]);

  useEffect(() => {
    if (counter > 0) {
      setTimeout(() => {
        setCounter(count => count - 1);
      }, 1000);
    }
  }, [counter]);

  let tableWidths = ["w-20", "w-20", "w-20", "w-30", "w-10"];

  let remoteAccessState = REMOTE_ACCESS_READY;
  const dualRelay = type === "PRO_1";
  let remoteAccessTooltip = "";
  if (!["PRO_1", "STANDARD_2", "EXTENSION"].includes(type)) {
    remoteAccessState = REMOTE_ACCESS_UNSUPPORTED;
    remoteAccessTooltip = "This device does not support remote opening";
  } else if (counter > 0) {
    remoteAccessState = REMOTE_ACCESS_OPEN;
  } else if (!remoteAccess) {
    remoteAccessState = REMOTE_ACCESS_DISABLED;
    remoteAccessTooltip = "Remote opening is disabled for this device";
  } else if (hasExtensions) {
    remoteAccessState = REMOTE_ACCESS_HAS_EXTENSIONS;
    remoteAccessTooltip = "This device is used to host extensions and cannot be opened by itself";
  } else if (!digitalKey) {
    remoteAccessState = REMOTE_ACCESS_NO_DIGITAL_KEY;
    remoteAccessTooltip = "You must have a digital key to open the device remotely";
  } else if (["total_count", "datetime_count"].includes(digitalKey.limit_type) && digitalKey.total_count_remaining <= 0) {
    remoteAccessState = REMOTE_ACCESS_EXPIRED;
    remoteAccessTooltip = "Your digital key has no more uses available. Contact the lock owner for new access.";
  } else if (["datetime", "datetime_count"].includes(digitalKey.limit_type) && dayjs().isBefore(digitalKey.datetime_from)) {
    remoteAccessState = REMOTE_ACCESS_EXPIRED;
    remoteAccessTooltip = `Your digital key will be active from ${dayjs(digitalKey.datetime_from).format("DD.MM.YYYY HH:mm")}`;
  } else if (["datetime", "datetime_count"].includes(digitalKey.limit_type) && dayjs().isAfter(digitalKey.datetime_to)) {
    remoteAccessState = REMOTE_ACCESS_EXPIRED;
    remoteAccessTooltip = "Your digital key has expired. Contact the lock owner for new access.";
  } else if (online === null) { // online will be null, if the device has never been online. This may indicate that the device has older firmware that does not communicate via mqtt.
    remoteAccessState = REMOTE_ACCESS_OFFLINE;
    remoteAccessTooltip = "Device is offline. Your device may not support remote opening.";
  } else if (online === false) {
    remoteAccessState = REMOTE_ACCESS_OFFLINE;
    remoteAccessTooltip = "Device is offline. Make sure the device is powered on and has internet connection.";
  } else if (openingLock?.serialNumber === serialNumber) {
    remoteAccessState = REMOTE_ACCESS_OPENING_SELF;
  } else if (openingLock !== null) {
    remoteAccessState = REMOTE_ACCESS_OPENING;
    remoteAccessTooltip = "You are already opening another lock. Please wait...";
  }

  return (
    <tr key={id}>
      <td className={tableWidths[0]}>
        <div className="devices-table-row__device_name_wrap">
          <span className="devices-table-row__device_name truncate-ellipsis" title={deviceName}>
            {deviceName}
          </span>
          {online === true &&
            <span className="devices-table-row__device_online">
              Available
            </span>
          }
          {online === false &&
            <span className="devices-table-row__device_offline">
              No Connection
            </span>
          }
        </div>
      </td>
      <td className={tableWidths[1]}>
        <span className="truncate-ellipsis" title={type}>
          {type}
        </span>
      </td>
      <td className={tableWidths[2]}>
        <span className="truncate-ellipsis" title={serialNumber}>
          {serialNumber}
        </span>
      </td>
      <td className={tableWidths[3]}>
        {[
            REMOTE_ACCESS_UNSUPPORTED,
            REMOTE_ACCESS_DISABLED,
            REMOTE_ACCESS_NO_DIGITAL_KEY,
            REMOTE_ACCESS_HAS_EXTENSIONS,
            REMOTE_ACCESS_EXPIRED
          ].includes(remoteAccessState) &&
          <Tooltip text={remoteAccessTooltip}>
            -
          </Tooltip>
        }
        {[
            REMOTE_ACCESS_OFFLINE,
            REMOTE_ACCESS_OPENING
          ].includes(remoteAccessState) &&
            <Tooltip text={remoteAccessTooltip}>
              {dualRelay ?
                <>
                  <DefaultButton 
                    type="button" 
                    variant="outlined" 
                    size="small"
                    disabled
                  >
                    Unlock 1
                  </DefaultButton>
                  <DefaultButton 
                    className="ml-20" 
                    type="button" 
                    variant="outlined" 
                    size="small"
                    disabled
                  >
                    Unlock 2
                  </DefaultButton>
                </>
                :
                <DefaultButton 
                  type="button" 
                  variant="outlined"
                  size="small"
                  disabled
                >
                  Unlock
                </DefaultButton>
              }
            </Tooltip>
        }
        {[REMOTE_ACCESS_READY, REMOTE_ACCESS_OPENING_SELF].includes(remoteAccessState) &&
          (dualRelay ?
              <>
                <DefaultButton
                  type="button"
                  variant="outlined"
                  size="small"
                  onClick={onUnlock}
                  loading={openingLock?.serialNumber === serialNumber && !openingLock?.secondRelay}
                  disabled={remoteAccessState === REMOTE_ACCESS_OPENING_SELF}
                >
                  Unlock 1
                </DefaultButton>
                <DefaultButton
                  className="ml-20"
                  type="button"
                  variant="outlined"
                  size="small"
                  onClick={onUnlockSecondRelay}
                  loading={openingLock?.serialNumber === serialNumber && openingLock?.secondRelay}
                  disabled={remoteAccessState === REMOTE_ACCESS_OPENING_SELF}
                >
                  Unlock 2
                </DefaultButton>
              </>
              :
              <DefaultButton
                type="button"
                variant="outlined"
                size="small"
                onClick={onUnlock}
                loading={openingLock?.serialNumber === serialNumber}
                disabled={remoteAccessState === REMOTE_ACCESS_OPENING_SELF}
              >
                Unlock
              </DefaultButton>
          )
        }
        {remoteAccessState === REMOTE_ACCESS_OPEN &&
          <span className="devices-table-row__counter">Will be locked in <span
            className="devices-table-row__counter-seconds">{counter} {counter === 1 ? "second" : "seconds"}</span></span>
        }
      </td>
      <td className={tableWidths[4]}>
        {accessType !== "user" && (
          <div className="devices-table-row__more-wrap">
            <button
              className={`devices-table-row__more-btn${more ? " devices-table-row__more-btn--active" : ""}`}
              type="button"
              aria-label="More actions"
              onClick={openMore}
            >
              <MoreIcon />
            </button>

            {more && (
              <ClickAwayListener onClickAway={closeMore}>
                <div className="devices-table-row__more-menu">
                  <Link className="devices-table-row__more-item" to={`${urls.manageUsersUrl.path}/${id}`}>
                    <UserIcon />
                    <span>Manage users</span>
                  </Link>
                  {accessType === "owner" && (
                    <>
                      <Link className="devices-table-row__more-item" to={`${urls.editDeviceUrl.path}/${id}`}>
                        <EditIcon />
                        <span>Edit device</span>
                      </Link>
                      {type !== "EXTENSION" && (
                        <button className="devices-table-row__more-item" type="button" onClick={onTransfer}>
                          <TransferIcon />
                          <span>Transfer ownership</span>
                        </button>
                      )}
                      <button
                        className="devices-table-row__more-item devices-table-row__more-item--red"
                        type="button"
                        onClick={onRemove}
                      >
                        <DeleteIcon />
                        <span>Remove device</span>
                      </button>
                    </>
                  )}
                </div>
              </ClickAwayListener>
            )}
          </div>
        )}
      </td>
    </tr>
  );
};

export default DevicesTableRow;
