import React from "react";
import "../../assets/main.scss";
import { Context as ResponsiveContext } from "react-responsive";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";

toast.configure();

const App = props => {
  return <>
    <Helmet>
      <meta name="viewport" content="width=1366, user-scalable=yes" />
    </Helmet>
    <ResponsiveContext.Provider>{props.children}</ResponsiveContext.Provider>
  </>;
};

export default App;
