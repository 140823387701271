import React, { useMemo, useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import _ from "lodash";

import DefaultButton from "../../../Shared/Buttons/DefaultButton";
import InputField from "../../../Shared/InputField";
import urls from "../../../core/urls";
import { Checkbox, MenuItem } from "@material-ui/core";
import { incorrectEmail } from "../../../core/helpers/validationTexts";

import { ReactComponent as CheckboxOff } from "../../../assets/images/checkbox-off.svg";
import { ReactComponent as CheckboxOn } from "../../../assets/images/checkbox-on.svg";
import { connect } from "react-redux";
import { getCountries, sendCode, sendCodeEmail } from "../actions";
import { toastErrors } from "../../../core/helpers/toastErrors";

const SignUp = ({ getCountries, countries: { country_phone_codes }, sendCode, sendCodeEmail }) => {
    const history = useHistory();
    const buttonLoading = useSelector(({ app }) => app.buttonLoading);

    useEffect(() => {
        getCountries();
    }, []);

    const schema = useMemo(
        () =>
            yup.object().shape({
                tab1: yup.boolean(),
                tab0: yup.boolean(),
                country: yup.string().when("tab0", { is: true, then: yup.string().required() }),
                phone: yup.string().when("tab0", { is: true, then: yup.string().required() }),
                email: yup
                    .string()
                    .email(incorrectEmail)
                    .when("tab1", {
                        is: true,
                        then: yup.string().required("No email provided")
                    })
            }),
        []
    );

    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm({
        mode: "onTouched",
        reValidateMode: "onChange",
        resolver: yupResolver(schema),
        shouldFocusError: true,
        defaultValues: {
            tab1: false,
            tab0: true
        }
    });

    const [tab, setTab] = useState(0);
    const changeTab = idx => setTab(idx);

    const onSubmit = data => {
        const { country, phone, tab0, tab1, email } = data;

        if (tab === 0 && tab0) {
            sendCode(`%2B${country}${phone}`).then(res => {
                if (res.payload && res.payload.status && res.payload.status === 200) {
                    history.push(`${urls.confirmNumberUrl.path}?phone=${country}${phone}`);
                } else {
                    toastErrors(res);
                }
            });
        } else if (tab === 1 && tab1) {
            sendCodeEmail(email).then(res => {
                if (res.payload && res.payload.status && res.payload.status === 200) {
                    history.push(`${urls.confirmEmailUrl.path}?email=${email}`);
                } else {
                    toastErrors(res);
                }
            });
        }
    };

    return (
        <div className="auth-box">
            <h1 className="auth-box__title">Create account</h1>

            <form className="auth-box__wrap" onSubmit={handleSubmit(onSubmit)}>
                <div className="auth-tabs mb-32">
                    <button
                        className={`auth-tabs__btn${tab === 0 ? " auth-tabs__btn--active" : ""}`}
                        type="button"
                        onClick={() => {
                            changeTab(0);
                            setValue("tab1", false);
                            setValue("tab0", true);
                        }}
                    >
                        Using phone number
                    </button>
                    <button
                        className={`auth-tabs__btn${tab === 1 ? " auth-tabs__btn--active" : ""}`}
                        type="button"
                        onClick={() => {
                            changeTab(1);
                            setValue("tab1", true);
                            setValue("tab0", false);
                        }}
                    >
                        Using email
                    </button>
                </div>

                {tab === 0 && (
                    <>
                        <Controller
                            name="country"
                            control={control}
                            render={({ field }) => (
                                <InputField
                                    select
                                    id="country"
                                    className="mb-24"
                                    type="text"
                                    label="Country / Region"
                                    error={errors.country?.message}
                                    {..._.omit(field, "ref")}
                                >
                                    {country_phone_codes &&
                                        country_phone_codes.map(option => (
                                            <MenuItem key={option.country_iso_code} value={option.phone_code}>
                                                {option.country_name} (+{option.phone_code})
                                            </MenuItem>
                                        ))}
                                </InputField>
                            )}
                        />
                        <Controller
                            name="phone"
                            control={control}
                            render={({ field }) => (
                                <InputField
                                    id="phone"
                                    type="tel"
                                    label="Phone number"
                                    placeholder="0000 0000"
                                    defaultValue=""
                                    helperText="We will send you a one time SMS to confirm your number. Carrier fees may apply."
                                    error={errors.phone?.message}
                                    {..._.omit(field, "ref")}
                                />
                            )}
                        />
                    </>
                )}

                {tab === 1 && (
                    <Controller
                        name="email"
                        control={control}
                        render={({ field }) => (
                            <InputField
                                id="email"
                                type="text"
                                label="Email"
                                error={errors.email?.message}
                                {..._.omit(field, "ref")}
                            />
                        )}
                    />
                )}

                <div className="auth-box__row mt-32">
                    <Checkbox
                        className="auth-box__checkbox"
                        icon={<CheckboxOff />}
                        checkedIcon={<CheckboxOn />}
                        disableRipple
                        required
                    />
                    <span className="auth-box__text">
                        I agree to the <a href="#">Terms & Conditions</a> and <a href="#">Privacy Policy</a>
                    </span>
                </div>

                <DefaultButton className="mt-48 mx-auto" type="submit" disabled={buttonLoading} loading={buttonLoading}>
                    Continue
                </DefaultButton>
            </form>
        </div>
    );
};

const mapStateToProps = ({ authorization }) => {
    return {
        countries: authorization.countries
    };
};

const mapDispatchToProps = { getCountries, sendCode, sendCodeEmail };

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
