import React from "react";
import urls from "../../../core/urls";
import BackLink from "../../../Shared/BackLink";
import InputField from "../../../Shared/InputField";
import SelectComponent from "../../../Shared/SelectComponent";
import DefaultButton from "../../../Shared/Buttons/DefaultButton";
import DeviceGroupCard from "../DeviceGroupCard";
import "./EditDeviceGroup.scss";

const EditDeviceGroup = () => {
  const devices = [{ value: [], label: "EMKA Demo, Elevator Demo" }];
  const groups = [
    { name: "test_group", users: 4 },
    { name: "random_group", users: 3 },
    { name: "some_group", users: 9 }
  ];

  return (
    <div className="edit-device-group page-wrap">
      <BackLink href={urls.devicesUrl.path} text="Devices" />
      <h1 className="page-title">
        {urls.editDeviceGroupUrl.name} <span className="edit-device-group__name">test_group</span>
      </h1>

      <div className="edit-device-group__section section-wrap">
        <div className="edit-device-group__row">
          <InputField className="min-w-506" label="Group name" defaultValue="test_group" />
        </div>

        <div className="edit-device-group__row">
          <h2 className="subtitle mb-24">
            Devices<span className="subtitle__number">2</span>
          </h2>
          <SelectComponent className="min-w-506" label="Devices" options={devices} defaultValue={devices[0]} />
        </div>

        <div className="edit-device-group__row">
          <h2 className="subtitle mb-24">
            User groups with access<span className="subtitle__number">3</span>
          </h2>
          <div className="edit-device-group__list">
            {groups.map(({ name, users }, index) => (
              <DeviceGroupCard key={index} title={name} titleType="button" users={users} />
            ))}
          </div>
        </div>

        <DefaultButton className="edit-device-group__add-btn">Save changes</DefaultButton>
      </div>
    </div>
  );
};


export default EditDeviceGroup;
