import { DEVICES } from "./devicesActionTypes";

const INITIAL_STATE = {
  loading: true,
  buttonLoading: false,
  optionsLoading: true,
  openingLock: null,
  lastOpenedLock: null,
  ownedByMe: {},
  sharedAsAdmin: {},
  sharedAsUser: {},
  options: {},
  device: {},
  deviceName: {},
  deviceUsersList: {},
  deviceInvitedUsers: {}
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DEVICES.GET_DEVICES_OWNER:
      return { ...state, loading: action.loadAnimation };
    case DEVICES.GET_DEVICES_OWNER_SUCCESS:
      return { ...state, ownedByMe: action?.payload?.data, loading: false };
    case DEVICES.GET_DEVICES_OWNER_FAIL:
      return { ...state, loading: false };

    case DEVICES.GET_DEVICES_ADMIN:
      return { ...state, loading: action.loadAnimation };
    case DEVICES.GET_DEVICES_ADMIN_SUCCESS:
      return { ...state, sharedAsAdmin: action?.payload?.data, loading: false };
    case DEVICES.GET_DEVICES_ADMIN_FAIL:
      return { ...state, loading: false };

    case DEVICES.GET_DEVICES_USER:
      return { ...state, loading: action.loadAnimation };
    case DEVICES.GET_DEVICES_USER_SUCCESS:
      return { ...state, sharedAsUser: action?.payload?.data, loading: false };
    case DEVICES.GET_DEVICES_USER_FAIL:
      return { ...state, loading: false };

    case DEVICES.GET_OPTIONS_SUCCESS:
      return { ...state, options: action?.payload?.data };

    case DEVICES.GET_DEVICE:
      return { ...state, loading: true };
    case DEVICES.GET_DEVICE_SUCCESS:
      return { ...state, device: action?.payload?.data, loading: false };
    case DEVICES.GET_DEVICE_FAIL:
      return { ...state, loading: false };

    case DEVICES.PATCH_DEVICE:
      return { ...state, buttonLoading: true };
    case DEVICES.PATCH_DEVICE_SUCCESS:
      return { ...state, device: action?.payload?.data, buttonLoading: false };
    case DEVICES.PATCH_DEVICE_FAIL:
      return { ...state, buttonLoading: false };

    case DEVICES.GET_DEVICE_NAME_SUCCESS:
      return { ...state, deviceName: action?.payload?.data };

    case DEVICES.GET_DEVICE_USERS_LIST:
      return { ...state, loading: true };
    case DEVICES.GET_DEVICE_USERS_LIST_SUCCESS:
      return { ...state, deviceUsersList: action?.payload?.data, loading: false };
    case DEVICES.GET_DEVICE_USERS_LIST_FAIL:
      return { ...state, loading: false };

    case DEVICES.DELETE_USER:
      return { ...state, buttonLoading: true };
    case DEVICES.DELETE_USER_SUCCESS:
      return { ...state, buttonLoading: false };
    case DEVICES.DELETE_USER_FAIL:
      return { ...state, buttonLoading: false };

    case DEVICES.GET_DEVICE_INVITED_USERS:
      return { ...state, loading: true };
    case DEVICES.GET_DEVICE_INVITED_USERS_SUCCESS:
      return { ...state, deviceInvitedUsers: action?.payload?.data, loading: false };
    case DEVICES.GET_DEVICE_INVITED_USERS_FAIL:
      return { ...state, loading: false };

    case DEVICES.DELETE_INVITATION:
      return { ...state, buttonLoading: true };
    case DEVICES.DELETE_INVITATION_SUCCESS:
      return { ...state, buttonLoading: false };
    case DEVICES.DELETE_INVITATION_FAIL:
      return { ...state, buttonLoading: false };

    case DEVICES.DELETE_DEVICE:
      return { ...state, buttonLoading: true };
    case DEVICES.DELETE_DEVICE_SUCCESS:
      return { ...state, buttonLoading: false };
    case DEVICES.DELETE_DEVICE_FAIL:
      return { ...state, buttonLoading: false };

    case DEVICES.GET_TRANSFER_OPTIONS:
      return { ...state, optionsLoading: true };
    case DEVICES.GET_TRANSFER_OPTIONS_SUCCESS:
      return { ...state, optionsLoading: false };
    case DEVICES.GET_TRANSFER_OPTIONS_FAIL:
      return { ...state, optionsLoading: false };

    case DEVICES.POST_TRANSFER_OWNERSHIP:
      return { ...state, buttonLoading: true };
    case DEVICES.POST_TRANSFER_OWNERSHIP_SUCCESS:
      return { ...state, buttonLoading: false };
    case DEVICES.POST_TRANSFER_OWNERSHIP_FAIL:
      return { ...state, buttonLoading: false };

    case DEVICES.POST_CHANGE_ROLE:
      return { ...state, buttonLoading: true };
    case DEVICES.POST_CHANGE_ROLE_SUCCESS:
      return { ...state, buttonLoading: false };
    case DEVICES.POST_CHANGE_ROLE_FAIL:
      return { ...state, buttonLoading: false };

    case DEVICES.MQTT_OPEN_LOCK:
      return { ...state, openingLock: {serialNumber: action.payload.device.serial_number, secondRelay: action.payload.secondRelay}, lastOpenedLock: null };
    case DEVICES.MQTT_OPEN_LOCK_SUCCESS:
      return { ...state, openingLock: null, lastOpenedLock: action.payload.device.serial_number };
    case DEVICES.MQTT_OPEN_LOCK_FAIL:
      return { ...state, openingLock: null };

    default:
      return state;
  }
}
