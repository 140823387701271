import React from "react";
import DialogMUI from "../../../Shared/DialogMUI";
import DefaultButton from "../../../Shared/Buttons/DefaultButton";
import "./Dialogs.scss";

const ShareAccessDialog = ({ access, invite, open, onCLose }) => {
  return (
    <DialogMUI className="devices-dialog t-center" open={open} onClose={onCLose}>
      <h2 className="mb-16">{invite ? "Send invite" : "Share access"}</h2>
      <p>
        {invite ? "Invite" : "Access"} has been successfully {invite ? "sent" : "shared"}{" "}
        {access === "vrfid" && "The user will also be given a digital key along" + " VRFID."}
      </p>
      <DefaultButton className="devices-dialog__btn" onClick={onCLose}>
        Ok, great
      </DefaultButton>
    </DialogMUI>
  );
};

export default ShareAccessDialog;
