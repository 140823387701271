import React, { useState } from "react";
import { useHistory } from "react-router";
import { useForm } from "react-hook-form";
import DefaultButton from "../../../Shared/Buttons/DefaultButton";

import { ReactComponent as AlertIcon } from "../../../assets/images/alert.svg";
import urls from "../../../core/urls";
import { connect } from "react-redux";
import { sendCode, verifyCode, verifyCodeEmail, sendCodeEmail } from "./../actions";
import { useLocation } from "react-router-dom";
import { toastErrors } from "../../../core/helpers/toastErrors";

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ConfirmData = ({ confirmNumber = false, sendCode, verifyCode, sendCodeEmail, verifyCodeEmail }) => {
    const history = useHistory();
    const { register, handleSubmit, setValue } = useForm();

    const [resend, setResend] = useState(false);

    const [error, setError] = useState(false);

    let query = useQuery();

    const [timer, setTimer] = useState(59);

    let interval = null;

    const resendCode = () => {
        let tempTimer = 59;
        setResend(true);
        interval = setInterval(() => {
            if (tempTimer === 0) {
                setResend(false);
                setTimer(59);
                clearInterval(interval);
                return;
            } else {
                setTimer(tempTimer - 1);
                tempTimer--;
            }
        }, 1000);
    };

    const onSubmit = data => {
        const { firstDigit, secondDigit, thirdDigit, fourthDigit, fifthDigit, sixthDigit } = data;
        if (confirmNumber) {
            verifyCode(
                firstDigit + secondDigit + thirdDigit + fourthDigit + fifthDigit + sixthDigit,
                `%2B${query.get("phone")}`
            ).then(res => {
                if (res.payload && res.payload.status && res.payload.status === 200) {
                    const token = res.payload.data.verification_token;
                    history.push(`${urls.accountDetailsNumberUrl.path}?token=${token}&phone=${query.get("phone")}`);
                } else {
                    setError(true);
                    toastErrors(res);
                }
            });
        } else {
            verifyCodeEmail(
                firstDigit + secondDigit + thirdDigit + fourthDigit + fifthDigit + sixthDigit,
                `${query.get("email")}`
            ).then(res => {
                if (res.payload && res.payload.status && res.payload.status === 200) {
                    const token = res.payload.data.verification_token;
                    history.push(`${urls.accountDetailsEmailUrl.path}?token=${token}&email=${query.get("email")}`);
                } else {
                    setError(true);
                    toastErrors(res);
                }
            });
        }
    };

    return (
        <div className="auth-box">
            <h1 className="auth-box__title">Confirm your {confirmNumber ? "number" : "email"}</h1>

            <form className="auth-box__wrap" onSubmit={handleSubmit(onSubmit)}>
                <span className={`auth-box__desc mb-40 mx-auto max-w-${confirmNumber ? "260" : "280"} t-center`}>
                    Enter the code we’ve sent by {confirmNumber ? "SMS" : "email"} to verify
                    <b> {confirmNumber ? "+" + query.get("phone") : query.get("email")}</b>
                </span>

                <div className="code-wrap">
                    <div className="code-wrap__fields">
                        <input
                            className="code-wrap__input"
                            defaultValue={""}
                            maxLength="1"
                            id="firstInput"
                            onPaste={e => {
                                const pasted = e.clipboardData.getData("Text").split("");
                                const fields = [
                                    "firstDigit",
                                    "secondDigit",
                                    "thirdDigit",
                                    "fourthDigit",
                                    "fifthDigit",
                                    "sixthDigit"
                                ];
                                pasted.forEach((el, idx) => {
                                    setValue(fields[idx], el);
                                });
                            }}
                            {...register("firstDigit", { required: true, maxLength: 1 })}
                            onChange={e => {
                                if (e.target.value && e.target.value.length > 0) {
                                    document.getElementById("secondInput").focus();
                                }
                            }}
                        />
                        <input
                            className="code-wrap__input"
                            maxLength="1"
                            defaultValue={""}
                            id="secondInput"
                            {...register("secondDigit", { required: true, maxLength: 1 })}
                            onChange={e => {
                                if (e.target.value && e.target.value.length > 0) {
                                    document.getElementById("thirdInput").focus();
                                } else {
                                    document.getElementById("firstInput").focus();
                                }
                            }}
                        />
                        <input
                            className="code-wrap__input"
                            maxLength="1"
                            defaultValue={""}
                            id="thirdInput"
                            {...register("thirdDigit", { required: true, maxLength: 1 })}
                            onChange={e => {
                                if (e.target.value && e.target.value.length > 0) {
                                    document.getElementById("fourthInput").focus();
                                } else {
                                    document.getElementById("secondInput").focus();
                                }
                            }}
                        />
                        <input
                            className="code-wrap__input"
                            maxLength="1"
                            defaultValue={""}
                            id="fourthInput"
                            {...register("fourthDigit", { required: true, maxLength: 1 })}
                            onChange={e => {
                                if (e.target.value && e.target.value.length > 0) {
                                    document.getElementById("fifthInput").focus();
                                } else {
                                    document.getElementById("thirdInput").focus();
                                }
                            }}
                        />
                        <input
                            className="code-wrap__input"
                            maxLength="1"
                            defaultValue={""}
                            id="fifthInput"
                            {...register("fifthDigit", { required: true, maxLength: 1 })}
                            onChange={e => {
                                if (e.target.value && e.target.value.length > 0) {
                                    document.getElementById("sixthInput").focus();
                                } else {
                                    document.getElementById("fourthInput").focus();
                                }
                            }}
                        />
                        <input
                            className="code-wrap__input"
                            maxLength="1"
                            defaultValue={""}
                            id="sixthInput"
                            {...register("sixthDigit", { required: true, maxLength: 1 })}
                            onChange={e => {
                                if (e.target.value && e.target.value.length > 0) {
                                    document.getElementById("sixthInput").blur();
                                } else {
                                    document.getElementById("fifthInput").focus();
                                }
                            }}
                        />
                    </div>
                    {error && (
                        <div className="auth-box__msg auth-box__msg--alert mt-14">
                            <AlertIcon />
                            <p>
                                Sorry we are not able to verify the code. Please make sure you entered correct code for
                                that number.
                            </p>
                        </div>
                    )}
                </div>

                <DefaultButton className="mt-48 mx-auto" type="submit">
                    Verify
                </DefaultButton>
            </form>

            <div className="auth-box__resend">
                Didn’t receive the{confirmNumber && " SMS"} code?
                {resend ? (
                    <span className="auth-box__resend-countdown">
                        Resend in 00:{String(timer).length === 1 ? "0" + timer : timer}
                    </span>
                ) : (
                    <button
                        className="auth-box__resend-btn good-hover"
                        onClick={() => {
                            if (confirmNumber) {
                                sendCode("%2B" + query.get("phone")).then(res => {
                                    if (res.payload && res.payload.status && res.payload.status === 200) {
                                        console.log(1);
                                    } else {
                                        toastErrors(res);
                                    }
                                });
                            } else {
                                sendCodeEmail(query.get("email")).then(res => {
                                    if (res.payload && res.payload.status && res.payload.status === 200) {
                                        console.log(1);
                                    } else {
                                        toastErrors(res);
                                    }
                                });
                            }
                            resendCode();
                        }}
                    >
                        Resend
                    </button>
                )}
            </div>
        </div>
    );
};

const mapDispatchToProps = { sendCode, verifyCode, verifyCodeEmail, sendCodeEmail };

export default connect(null, mapDispatchToProps)(ConfirmData);
