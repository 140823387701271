import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";

const propTypes = {
  className: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  content: PropTypes.node,
  children: PropTypes.node.isRequired,
  show: PropTypes.bool,
  onHover: PropTypes.bool,
  direction: PropTypes.oneOf(["left", "up"])
};

const defaultProps = {
  show: false,
  onHover: true,
  direction: "up"
};

const Tooltip = ({
  className,
  text,
  content,
  children,
  show,
  onHover,
  direction
}) => {
  const classes = ["tooltip-wrap"];
  if (className) {
    classes.push(className);
  }
  switch (direction) {
    case "left":
      classes.push("tooltip-wrap--left");
      break;
    case "up":
    default:
      classes.push("tooltip-wrap--up");
  }
  if (onHover) {
    classes.push("tooltip-wrap--hover");
  } else if (show === true) {
    classes.push("tooltip-wrap--shown");
  }
  return (
    <div className={classes.join(" ")}>
      {children}
      <div className="tooltip-wrap__tooltip">{content ? content : text}</div>
    </div>
  );
};

Tooltip.propTypes = propTypes;
Tooltip.defaultProps = defaultProps;

export default Tooltip;
