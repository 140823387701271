import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { getProfile } from "../../../core/actions";
import { verifyEmail, verifyPhone } from "../profileActions";
import { toastErrors } from "../../../core/helpers/toastErrors";
import DialogMUI from "../../../Shared/DialogMUI";
import DefaultButton from "../../../Shared/Buttons/DefaultButton";
import "./Dialogs.scss";

const ConfirmDialog = ({ open, onClose, email, phone }) => {
  const dispatch = useDispatch();
  const buttonLoading = useSelector(({ app }) => app.buttonLoading);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { isValid }
  } = useForm({
    mode: "onTouched"
  });

  const fields = ["field1", "field2", "field3", "field4", "field5", "field6"];

  const onNumberPaste = event => {
    const pasted = event.clipboardData.getData("Text").split("");
    pasted.forEach((el, idx) => {
      setValue(fields[idx], el);
    });
  };

  const onFieldChange = (nextField, prevField, event) => {
    if (event.target.value && event.target.value.length > 0) {
      document.getElementById(nextField).focus();
    } else {
      document.getElementById(prevField).focus();
    }
  };

  const onDialogClose = () => {
    onClose();
    reset();
  };

  const onSubmit = async data => {
    const code = Object.values(data).join("");
    let res;
    if (email) res = await dispatch(verifyEmail(code));
    if (phone) res = await dispatch(verifyPhone(code));

    if (res?.payload) {
      dispatch(getProfile());
      onDialogClose();
    }
    if (res?.error) toastErrors(res);
  };

  return (
    <DialogMUI className="profile-dialog" open={open} onClose={onDialogClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h2 className="profile-dialog__title">
          Confirm your {email && "email"}
          {phone && "phone number"}
        </h2>
        <p className="profile-dialog__info">
          Enter the code we’ve sent by {email && "email"}
          {phone && "SMS"} to verify {email && <b>{email}</b>}
          {phone && <b>{phone}</b>}
        </p>
        <div className="code-wrap">
          <div className="code-wrap__fields">
            <input
              id="field1"
              className="code-wrap__input"
              maxLength="1"
              onPaste={onNumberPaste}
              {...register("field1", { required: true, maxLength: 1 })}
              onChange={event => onFieldChange("field2", "field1", event)}
            />
            <input
              id="field2"
              className="code-wrap__input"
              maxLength="1"
              {...register("field2", { required: true, maxLength: 1 })}
              onChange={event => onFieldChange("field3", "field1", event)}
            />
            <input
              id="field3"
              className="code-wrap__input"
              maxLength="1"
              {...register("field3", { required: true, maxLength: 1 })}
              onChange={event => onFieldChange("field4", "field2", event)}
            />
            <input
              id="field4"
              className="code-wrap__input"
              maxLength="1"
              {...register("field4", { required: true, maxLength: 1 })}
              onChange={event => onFieldChange("field5", "field3", event)}
            />
            <input
              id="field5"
              className="code-wrap__input"
              maxLength="1"
              {...register("field5", { required: true, maxLength: 1 })}
              onChange={event => onFieldChange("field6", "field4", event)}
            />
            <input
              id="field6"
              className="code-wrap__input"
              maxLength="1"
              {...register("field6", { required: true, maxLength: 1 })}
              onChange={event => onFieldChange("field6", "field5", event)}
            />
          </div>
        </div>
        <DefaultButton
          className="profile-dialog__btn"
          disabled={!isValid || buttonLoading}
          loading={buttonLoading}
          formAction
        >
          Confirm
        </DefaultButton>
      </form>
    </DialogMUI>
  );
};

export default ConfirmDialog;
