import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import urls from "../../../core/urls";
import { getDevice, patchDevice } from "../devicesActions";
import { editDeviceSchema } from "../../../core/helpers/validations";
import notifySuccess from "../../../core/helpers/notifySuccess";
import BackLink from "../../../Shared/BackLink";
import InputField from "../../../Shared/InputField";
import DefaultButton from "../../../Shared/Buttons/DefaultButton";
import MapWithAutocomplete from "../../../Shared/MapWithAutocomplete";
import SwitchMUI from "../../../Shared/SwitchMUI";
import { ReactComponent as InfoIcon } from "../../../assets/images/info.svg";
import Tooltip from "../../../Shared/Tooltip";
import "./EditDevice.scss";

const EditDevice = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    loading,
    buttonLoading,
    device: { device_name, serial_number, type, nfc_tag, address, latitude, longitude, address_description, remote_access, online }
  } = useSelector(({ devices }) => devices);

  useEffect(() => {
    dispatch(getDevice(id));
  }, []);

  const {
    reset,
    control,
    handleSubmit,
    setError,
    setValue,
    formState: { errors, isValid, isDirty }
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(editDeviceSchema),
    defaultValues: {
      device_name: "",
      address: "",
      latitude: null,
      longitude: null,
      address_description: "",
      remote_access: false
    }
  });

  const setLocation = ({ address, latitude, longitude }) => {
    if (address) setValue("address", address);
    if (latitude) setValue("latitude", latitude, { shouldDirty: true });
    if (longitude) setValue("longitude", longitude, { shouldDirty: true });
  };

  useEffect(() => {
    if (device_name) setValue("device_name", device_name);
    if (address) setValue("address", address);
    if (latitude) setValue("latitude", latitude);
    if (longitude) setValue("longitude", longitude);
    if (address_description) setValue("address_description", address_description);
    if (remote_access !== undefined) setValue("remote_access", remote_access);
  }, [device_name, address, latitude, longitude, address_description, remote_access]);

  const infoList = [
    { label: "Type", text: type ? type : "-" },
    { label: "Serial number", text: serial_number ? serial_number : "-" }
  ];

  const onSubmit = async data => {
    const res = await dispatch(patchDevice(id, data));
    const errors = res.error?.response.data;

    if (res?.payload) {
      reset(data);
      notifySuccess("Changes saved");
    }
    if (res?.error && errors) {
      errors.device_name && setError("device_name", { type: "manual", message: errors.device_name[0].message });
      errors.address && setError("address", { type: "manual", message: errors.address[0].message });
      errors.latitude && setError("address", { type: "manual", message: errors.latitude[0].message });
      errors.longitude && setError("address", { type: "manual", message: errors.longitude[0].message });
      errors.address_description &&
        setError("address_description", {
          type: "manual",
          message: errors.address_description[0].message
        });
      errors.remote_access && setError("remote_access", { type: "manual", message: errors.remote_access[0].message });
    }
  };

  return (
    <div className="edit-device page-wrap">
      <BackLink href={urls.devicesUrl.path} text="Devices" />
      <h1 className="page-title">
        {urls.editDeviceUrl.name} <span className="page-title__inner">{!loading && device_name && device_name}</span>
      </h1>
      <form className="edit-device__section section-wrap" onSubmit={handleSubmit(onSubmit)}>
        {loading ? (
          <h2 className="edit-device__loading loading-text">Loading...</h2>
        ) : (
          <>
            <div className="edit-device__row">
              <Controller
                name="device_name"
                control={control}
                render={({ field }) => (
                  <InputField
                    className="max-w-458"
                    label="Device name"
                    fullWidth
                    error={errors.device_name?.message}
                    inputProps={field}
                  />
                )}
              />

              <div className="nfc-tag">
                <span className="nfc-tag__name">NFC tag</span>
                <span className={`nfc-tag__status${nfc_tag ? " nfc-tag__status--assigned" : ""}`}>
                  {!nfc_tag ? "Not assigned" : "Assigned"}
                </span>
                <div className="nfc-tag__icon">
                  <Tooltip text={!nfc_tag ? "NFC tags can be assigned by owner via BLE Locking app" : <span>{nfc_tag}</span>}>
                    <InfoIcon />
                  </Tooltip>
                </div>
              </div>
            </div>

            <ul className="edit-device__row edit-device__row--flex">
              {infoList.map(({ label, text }) => (
                <li key={label} className="edit-device__info">
                  <span className="edit-device__info-label">{label}</span>
                  <span className="edit-device__info-text">{text}</span>
                </li>
              ))}
            </ul>

            { ["STANDARD_2", "PRO_1"].includes(type) && <div className="edit-device__row">
              <p className="edit-device__remote_access-label">Enable remote access</p>
              { online === null ?
                <p className="edit-device__remote_access-text">
                  Make sure the device has internet connection and is powered on. 
                  If this option is still not available, your device may not support remote opening, yet. 
                  Contact support for more information.
                </p>
                :
                <p className="edit-device__remote_access-text">Remote access works only when the module is connected to the internet. Disabling remote access prevents shared link accesses from working.</p>
              }
              

              <Controller
                control={control}
                name="remote_access"
                render={({
                  field: { onChange, value, name, ref },
                }) => (
                  <SwitchMUI 
                    name={name}
                    checked={value}
                    onChange={onChange}
                    inputRef={ref}
                    disabled={online === null}
                    label={value ? "Activated" : "Not Activated"}
                  />
                )}
              />
            </div>}

            <div className="edit-device__row">
              <MapWithAutocomplete
                className="max-w-592"
                address={address}
                latitude={latitude}
                longitude={longitude}
                error={errors.address?.message}
                onChange={setLocation}
              />
            </div>

            <div className="edit-device__row">
              <Controller
                name="address_description"
                control={control}
                render={({ field }) => (
                  <InputField
                    className="max-w-592"
                    label="Description"
                    multiline
                    fullWidth
                    rows={2}
                    error={errors.address_description?.message}
                    inputProps={field}
                  />
                )}
              />
            </div>

            <div className="edit-device__bottom-group">
              <DefaultButton disabled={!isValid || !isDirty || buttonLoading} loading={buttonLoading} formAction>
                Save changes
              </DefaultButton>
            </div>
          </>
        )}
      </form>
    </div>
  );
};

export default EditDevice;
