import React, { useEffect } from "react";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { useLocation } from "react-router-dom";

export const configureSentry = ({ dsn, dev, prod }) => {
  const host = window.location.host;
  let environment;
  if (host.includes(dev)) environment = "development";
  if (host.includes(prod)) environment = "production";

  if (!host.includes("localhost")) {
    return Sentry.init({
      dsn,
      autoSessionTracking: true,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0,
      environment
    });
  }
};

export const joinQueries = arr => `${arr.length && arr.length !== 0 ? "?" : ""}${arr.join("&")}`;

export const splitByCommas = data => data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const capitalize = string => string && string.charAt(0).toUpperCase() + string.slice(1);

export const imageTypeValidation = file => {
  const validTypes = ["image/jpeg", "image/jpg", "image/png"];
  return validTypes.indexOf(file.type) !== -1;
};

export const renameKeys = (keysMap, obj) =>
  Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      ...{ [keysMap[key] || key]: obj[key] }
    }),
    {}
  );

export const transformForSelect = (array, firstObj) => {
  const list = array ? array.map(item => renameKeys({ id: "value", name: "label" }, item)) : [];
  firstObj && list && list.unshift(firstObj);
  return list;
};

export const onChangeSearch = (setter, minStringLength = 1, resetPage) => event => {
  event.target.value.length >= minStringLength ? setter(event.target.value) : setter("");
  if (typeof resetPage === "function") {
    (!event.target.value || event.target.value.length >= minStringLength) && resetPage(0);
  }
};

export const onChangeSort = (setter, resetPage) => event => {
  if (resetPage && typeof resetPage === "function") {
    resetPage(0);
  }
  setter({ value: event.value, label: event.label });
};

export const onChangeDate = (setter, resetPage) => date => {
  if (resetPage && typeof resetPage === "function") {
    resetPage(0);
  }
  setter(date);
};

export const addQueryFilter = (request, object) => {
  const filters = [];

  for (let key in object) {
    if (object[key]) {
      filters.push(`${key}=${object[key].value ? object[key].value : object[key]}`);
    }
  }

  return request(`${filters?.length ? "?" : ""}${filters?.join("&")}`);
};

export const dateForQuery = date => {
  if (!date?.isValid()) {
    return;
  }
  return encodeURIComponent(date?.toISOString().replace(".000Z", ""));
};

export const handlePage = (setter, scroll = false) => event => {
  setter(event.selected);
  scroll && document?.querySelector(".page-wrap").scrollTo(0, 0);
};

export const onTabClick = (setCurrentTab, setPage) => (tabIndex) => {
  setCurrentTab(tabIndex);
  setPage && setPage(0);
};

export function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const isEmail = value => {
  const inputValue = value?.toString();

  if (inputValue) {
    // return !!inputValue?.match(/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i);
    return !!inputValue?.match(
      /^([\w-]+(?:\.[\w-]+)*(?:\+[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
    ); // Allow "+"
  } else {
    return false;
  }
};

export const isPhoneNumber = value => {
  const inputValue = value?.toString();
  if (inputValue) {
    return !!inputValue?.match(/^([+]?\d{1,2}[-\s]?|)\d{3}[-\s]?\d{3}[-\s]?\d{4}$/);
  } else {
    return false;
  }
};

export const useDebounce = (value, delay = 500) => {
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return debouncedValue;
};
