import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import urls from "../../../core/urls";
import {
  deleteInvitation,
  deleteUser,
  getDeviceInvitedUsers,
  getDeviceName,
  getDeviceUsersList,
  postChangeRole
} from "../devicesActions";
import { addQueryFilter, handlePage, onChangeSearch, onTabClick } from "../../../core/helpers/functions";
import { toastErrors } from "../../../core/helpers/toastErrors";
import BackLink from "../../../Shared/BackLink";
import { TabItem, Tabs } from "../../../Shared/Tabs";
import InputField from "../../../Shared/InputField";
import DefaultButton from "../../../Shared/Buttons/DefaultButton";
import ManageUsersTable from "../ManageUsersTable";
import Pagination from "../../../Shared/Pagination";
import NotFound from "../../../Shared/NotFound";
import ChangeRoleDialog from "../Dialogs/ChangeRoleDialog";
import RemoveUserAccessDialog from "../Dialogs/RemoveUserAccessDialog";
import { ReactComponent as AddIcon } from "../../../assets/images/add-white.svg";
import "./ManageUsers.scss";

const ManageUsers = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    buttonLoading,
    deviceName: { device_name, type, remote_access },
    deviceUsersList,
    deviceInvitedUsers
  } = useSelector(({ devices }) => devices);

  const [firstLoad, setFirstLoad] = useState(true);
  const [search, setSearch] = useState("");
  const [currentTab, setCurrentTab] = useState(0);
  const [sortParams, setSortParams] = useState({ sort: "", order: "" });
  const [page, setPage] = useState(0);

  const sortList = [
    { value: "full_name", label: "User", className: "w-18" },
    { value: "user__email", label: "Email", className: "w-22" },
    { value: "user__phone_number", label: "Phone number", className: "w-20" },
    { value: null, label: "Access & limit type", className: "w-30" },
    { className: "w-10" }
  ];

  const filters = {
    page: page + 1,
    search,
    ordering: sortParams.order + sortParams.sort
  };

  const tabs = [
    {
      label: "Active",
      count: deviceUsersList?.count,
      users: firstLoad ? [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }] : deviceUsersList?.results,
      pages: deviceUsersList?.pages
    },
    {
      label: "Invited",
      count: deviceInvitedUsers?.count,
      users: deviceInvitedUsers?.results,
      pages: deviceInvitedUsers?.pages
    }
  ];

  const getDeviceData = () => {
    if (firstLoad) {
      dispatch(getDeviceName(id)).then(res => res?.error && history.push(urls.devicesUrl.path));
      dispatch(addQueryFilter(getDeviceUsersList(id), filters)).then(res => {
        res?.payload?.status && setFirstLoad(false);
      });
      dispatch(addQueryFilter(getDeviceInvitedUsers(id), filters));
    } else {
      if (currentTab === 0) {
        dispatch(addQueryFilter(getDeviceUsersList(id), filters));
      }
      if (currentTab === 1) {
        dispatch(addQueryFilter(getDeviceInvitedUsers(id), filters));
      }
    }
  };

  const [changeRole, setChangeRole] = useState({
    open: false,
    userId: "",
    username: "",
    role: ""
  });
  const onChangeRoleOpen = data => {
    setChangeRole({
      ...changeRole,
      ...data,
      open: true
    });
  };
  const onChangeRoleClose = () => {
    setChangeRole({
      ...changeRole,
      open: false
    });
  };
  const onChangeRoleConfirm = async () => {
    const res = await dispatch(postChangeRole(id, changeRole.userId, changeRole.role));
    if (res.payload) {
      getDeviceData();
      onChangeRoleClose();
    }
    if (res?.error) toastErrors(res);
  };

  const [removeAccess, setRemoveAccess] = useState({
    open: false,
    userId: "",
    username: "",
    email: "",
    phone: "",
    accessType: "",
    accessName: ""
  });
  const onRemoveAccessOpen = data => {
    setRemoveAccess({
      ...removeAccess,
      ...data,
      open: true
    });
  };
  const onRemoveAccessClose = () => {
    setRemoveAccess({
      ...removeAccess,
      open: false
    });

    const timer = setTimeout(
      () =>
        setRemoveAccess({
          open: false,
          userId: "",
          username: "",
          email: "",
          phone: "",
          accessType: "",
          accessName: ""
        }),
      500
    );
    return () => clearTimeout(timer);
  };
  const onRemoveAccessConfirm = () => {
    let action;
    if (currentTab === 0) action = deleteUser;
    if (currentTab === 1) action = deleteInvitation;

    dispatch(action(id, removeAccess.userId, removeAccess.accessType)).then(res => {
      if (res?.payload?.status === 204) {
        getDeviceData();
        onRemoveAccessClose();
      } else {
        toastErrors(res);
      }
    });
  };

  useEffect(() => {
    getDeviceData();
  }, [search, sortParams, page, currentTab]);

  return (
    <div className="manage-users page-wrap">
      <BackLink href={urls.devicesUrl.path} text="Devices" />
      <h1 className="page-title">
        {urls.manageUsersUrl.name}{" "}
        <span className="page-title__inner">of {!firstLoad && device_name && device_name}</span>
      </h1>
      <div className="manage-users__section section-wrap">
        <header className="manage-users__header">
          <InputField
            className="mr-16 min-w-276"
            label="Search users"
            type="search"
            onChange={onChangeSearch(setSearch, 3, setPage)}
          />
          <DefaultButton className="invite-btn" type="link" to={`${urls.inviteUsersToDeviceUrl.path}/${id}`}>
            <AddIcon className="add-icon" />
            <span>Invite users</span>
          </DefaultButton>
        </header>

        <Tabs className="mt-48" onTabClick={onTabClick(setCurrentTab, setPage)}>
          {tabs?.map(({ label, count, users, pages }, index) => (
            <TabItem key={index} index={index} label={label} counter={count}>
              {users?.length > 0 ? (
                <>
                  <ManageUsersTable
                    {...{
                      users,
                      deviceId: id,
                      deviceAccess: deviceUsersList?.relation_type,
                      deviceType: type,
                      deviceRemoteAccess: remote_access,
                      sortList,
                      sortParams,
                      setSortParams,
                      firstLoad,
                      inviteTab: currentTab === 1,
                      onChangeRoleOpen,
                      onRemoveAccessOpen
                    }}
                  />
                  {pages > 1 && <Pagination active={page} onChange={handlePage(setPage)} pageCount={pages} />}
                </>
              ) : (
                <NotFound noData small title="No users" />
              )}
            </TabItem>
          ))}
        </Tabs>
      </div>

      <ChangeRoleDialog
        open={changeRole.open}
        onCLose={onChangeRoleClose}
        role={changeRole.role}
        username={changeRole.username}
        loading={buttonLoading}
        onConfirm={onChangeRoleConfirm}
      />
      <RemoveUserAccessDialog
        open={removeAccess.open}
        onCLose={onRemoveAccessClose}
        revokeType={currentTab === 1}
        deviceName={device_name}
        username={removeAccess.username || removeAccess.email || removeAccess.phone}
        accessType={removeAccess.accessType}
        accessName={removeAccess.accessName}
        loading={buttonLoading}
        onConfirm={onRemoveAccessConfirm}
      />
    </div>
  );
};

export default ManageUsers;
