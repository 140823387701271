import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, FormControlLabel, FormLabel, RadioGroup } from "@material-ui/core";
import { getCompanyShortInfo, postInviteCompany } from "../companiesActions";
import urls from "../../../core/urls";
import { toastErrors } from "../../../core/helpers/toastErrors";
import BackLink from "../../../Shared/BackLink";
import RadioMUI from "../../../Shared/RadioMUI";
import InputField from "../../../Shared/InputField";
import DefaultButton from "../../../Shared/Buttons/DefaultButton";
import InvitesSentDialog from "../Dialogs/InvitesSentDialog";
import "./InviteToCompany.scss";

const InviteToCompany = () => {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    buttonLoading,
    shortInfo: { account_name, my_role }
  } = useSelector(({ companies }) => companies);

  useEffect(() => {
    dispatch(getCompanyShortInfo(id));
  }, []);

  let roleList = [
    { value: "owner", label: "Owner" },
    { value: "admin", label: "Admin" }
  ];
  const [role, setRole] = useState("owner");
  const handleRole = event => setRole(event.target.value);

  const [users, setUsers] = useState("");
  const handleUsers = event => setUsers(event.target.value);

  const sendInvites = async () => {
    const userArray = users.split(", ");
    const res = await dispatch(postInviteCompany(id, role, userArray));
    if (res?.payload) setSuccessDialog(true);
    if (res?.error) toastErrors(res);
  };

  const [successDialog, setSuccessDialog] = useState(false);
  const closeSuccessDialog = () => {
    history.push(`${urls.ownersAndAdminsUrl.path}/${id}`);
    setSuccessDialog(false);
  };

  if (my_role && my_role !== "owner") history.push("/");
  return (
    <div className="invite-to-company page-wrap">
      <BackLink href={`${urls.ownersAndAdminsUrl.path}/${id}`} text="Company" />
      <h1 className="page-title">
        {urls.inviteUsersToDeviceUrl.name} <span className="page-title__inner">to {account_name && account_name}</span>
      </h1>

      <div className="invite-to-company__section section-wrap">
        <FormControl className="invite-to-company__row" component="fieldset">
          <FormLabel className="invite-to-company__legend" component="legend">
            Choose user role
          </FormLabel>
          <RadioGroup className="invite-to-company__radio-group" value={role} onChange={handleRole}>
            {roleList.map(({ value, label }) => (
              <FormControlLabel
                key={value}
                className="invite-to-company__label"
                value={value}
                control={<RadioMUI />}
                label={label}
              />
            ))}
          </RadioGroup>
        </FormControl>

        <p className="mb-24">We will send an email or SMS with an invitation link to the addresses or phones below</p>
        <div className="invite-to-company__row">
          <InputField
            className="max-w-790"
            label="Email addresses or phones"
            multiline
            fullWidth
            rows={2}
            value={users}
            onChange={handleUsers}
          />
        </div>

        <DefaultButton
          className="invite-to-company__send-btn"
          loading={buttonLoading}
          disabled={!users || buttonLoading}
          onClick={sendInvites}
        >
          Send invites
        </DefaultButton>
      </div>

      <InvitesSentDialog open={successDialog} onCLose={closeSuccessDialog} />
    </div>
  );
};

export default InviteToCompany;
