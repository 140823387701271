import React, { useEffect, useState } from "react";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import { ClickAwayListener } from "@material-ui/core";
import { GOOGLE_MAPS_KEY } from "../../core/config";
import InputField from "../InputField";
import "./MapWithAutocomplete.scss";

export default function MapWithAutocomplete({ className = "", address, latitude, longitude, error, onChange }) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAPS_KEY,
    libraries: ["places"]
  });
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (address && latitude && longitude) {
      setSelected({ lat: latitude, lng: longitude });
    }
  }, []);

  if (!isLoaded) return null;
  return (
    <div className={`map-with-autocomplete${className && ` ${className}`}`}>
      <PlacesAutocomplete address={address} error={error} setSelected={setSelected} onChange={onChange} />

      {selected ? (
        <GoogleMap zoom={15} center={selected} mapContainerClassName="map-with-autocomplete__map">
          <Marker position={selected} clickable={false} />
        </GoogleMap>
      ) : (
        <div className="map-with-autocomplete__map no-data">Select location</div>
      )}
    </div>
  );
}

const PlacesAutocomplete = ({ address, error, setSelected, onChange }) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions
  } = usePlacesAutocomplete();

  const handleClickAway = () => clearSuggestions();
  const handleInput = event => setValue(event.target.value);

  useEffect(() => {
    setValue(address, false);
  }, []);

  const handleSelect = description => async () => {
    setValue(description, false);
    clearSuggestions();

    const results = await getGeocode({ address: description });
    const { lat, lng } = await getLatLng(results[0]);
    setSelected({ lat, lng });

    if (typeof onChange === "function") {
      onChange({ address: description, latitude: lat, longitude: lng });
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className="places-autocomplete mb-16">
        <InputField
          className="places-autocomplete__input"
          label="Location"
          fullWidth
          value={value}
          disabled={!ready}
          error={error}
          onChange={handleInput}
        />
        {status === "OK" && (
          <div className="places-autocomplete__dropdown">
            <ul className="places-autocomplete__list">
              {data?.map(({ place_id, description, structured_formatting: { main_text, secondary_text } }) => (
                <li key={place_id} className="places-autocomplete__dropdown-item">
                  <button
                    className="places-autocomplete__dropdown-btn truncate-ellipsis"
                    type="button"
                    onClick={handleSelect(description)}
                  >
                    <b>
                      {main_text}
                      {secondary_text && ", "}
                    </b>
                    {secondary_text}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};
