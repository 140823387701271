import React from "react";
import { NavLink } from "react-router-dom";
import urls from "../../core/urls";
// import { ReactComponent as HomeIcon } from "./icons/home.svg";
import { ReactComponent as DevicesIcon } from "./icons/devices.svg";
import { ReactComponent as UsersIcon } from "./icons/users.svg";
import { ReactComponent as ActivityIcon } from "./icons/activity.svg";
import "./Sidebar.scss";

const Sidebar = () => {
  return (
    <nav className="sidebar">
      {/*<NavLink className="sidebar__link" to={urls.dashboardUrl.path} exact>*/}
      {/*  <HomeIcon />*/}
      {/*  Home*/}
      {/*</NavLink>*/}
      <NavLink className="sidebar__link" to={urls.devicesUrl.path} exact>
        <DevicesIcon />
        Devices
      </NavLink>
      <NavLink className="sidebar__link" to={urls.usersUrl.path} exact>
        <UsersIcon />
        Users
      </NavLink>
      <NavLink className="sidebar__link" to={urls.activityUrl.path} exact>
        <ActivityIcon />
        Activity
      </NavLink>
    </nav>
  );
};

export default Sidebar;
