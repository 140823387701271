import { ACTIVITY } from "./activityActionTypes";

export const getActivity = (query = "") => {
  return {
    type: ACTIVITY.GET_ACTIVITY,
    payload: {
      request: {
        url: `access-log/${query}`
      }
    }
  };
};

export function getActivityOptions() {
  return {
    type: ACTIVITY.GET_ACTIVITY_OPTIONS,
    payload: {
      request: {
        url: "access-log/options/"
      }
    }
  };
}
