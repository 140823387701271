// You need to import the function into rootReducer.js
import { PROFILE } from "./profileActionTypes";

const INITIAL_STATE = {
  profile: {}
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case PROFILE.EDIT_PROFILE_SUCCESS:
      return { ...state, profile: action?.payload?.data };
    case PROFILE.EDIT_PROFILE_FAIL:
      return { ...state, profile: action?.error?.response?.data };
    default:
      return state;
  }
}
