import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { getActivity, getActivityOptions } from "./activityActions";
import {
  addQueryFilter,
  capitalize,
  dateForQuery,
  handlePage,
  onChangeDate,
  onChangeSearch,
  onChangeSort,
  transformForSelect
} from "../../core/helpers/functions";
import { defaultSort } from "../../core/helpers/constants";
import { toastErrors } from "../../core/helpers/toastErrors";
import urls from "../../core/urls";
import InputField from "../../Shared/InputField";
import SelectComponent from "../../Shared/SelectComponent";
import DateTimePickerMUI from "../../Shared/DateTimePickerMUI";
import TableHead from "../../Shared/TableHead";
import SkeletonMUI from "../../Shared/SkeletonMUI";
import Avatar from "../../Shared/Avatar";
import NotFound from "../../Shared/NotFound";
import Pagination from "../../Shared/Pagination";
import { ReactComponent as SuccessIcon } from "../../assets/images/success-2.svg";
import { ReactComponent as RejectIcon } from "../../assets/images/reject.svg";
import "./Activity.scss";

const Activity = () => {
  const dispatch = useDispatch();
  const {
    activityData,
    options: { types, methods }
  } = useSelector(({ activity }) => activity);
  const { profile } = useSelector(({ app }) => app);

  const [firstLoad, setFirstLoad] = useState(true);
  const [search, setSearch] = useState("");
  const eventList = transformForSelect(types, defaultSort);
  const [event, setEvent] = useState(defaultSort);
  const methodList = transformForSelect(methods, defaultSort);
  const [method, setMethod] = useState(defaultSort);
  const successList = [defaultSort, {value: "1", label: "Success"}, {value: "0", label: "Failed"}];
  const [success, setSuccess] = useState(defaultSort);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [sortParams, setSortParams] = useState({ sort: "", order: "" });
  const [page, setPage] = useState(0);

  const sortList = [
    { value: "access_time", label: "Date & Time", className: "w-15" },
    { value: "user_info", label: "User", className: "w-25" },
    { value: "device__device_name", label: "Device", className: "w-20" },
    { value: "type", label: "Event", className: "w-15" },
    { value: "method", label: "Access Method", className: "w-15" },
    { value: "success", label: "Status", className: "w-10" },
  ];

  const filters = {
    page: page + 1,
    search,
    type: event.value,
    method: method.value,
    success: success.value,
    access_time_from: dateFrom && dateForQuery(dateFrom),
    access_time_to: dateTo && dateForQuery(dateTo),
    ordering: sortParams.order + sortParams.sort
  };

  useEffect(() => {
    dispatch(getActivityOptions());
  }, []);

  const getActivityData = () => {
    dispatch(addQueryFilter(getActivity, filters)).then(res => {
      res?.payload?.status && firstLoad && setFirstLoad(false);
      res?.error && toastErrors(res);
    });
  };

  useEffect(() => {
    getActivityData();
  }, [search, event, method, success, dateFrom, dateTo, sortParams, page]);

  return (
    <div className="activity-page page-wrap">
      <h1 className="page-title">{urls.activityUrl.name}</h1>
      <div className="section-wrap p-32">
        <header className="activity-page__header">
          <InputField
            className="max-w-256"
            label="Search logs"
            type="search"
            fullWidth
            onChange={onChangeSearch(setSearch, 3, setPage)}
          />
          <DateTimePickerMUI
            label="From"
            emptyLabel="Date from"
            value={dateFrom}
            clearable
            maxDate={dateTo || new Date()}
            onChange={onChangeDate(setDateFrom, setPage)}
          />
          <DateTimePickerMUI
            label="To"
            emptyLabel="Date to"
            value={dateTo}
            clearable
            minDate={dateFrom || undefined}
            maxDate={new Date()}
            onChange={onChangeDate(setDateTo, setPage)}
          />
          <SelectComponent
            className="min-w-160"
            label="Event"
            options={eventList}
            value={event}
            onChange={onChangeSort(setEvent, setPage)}
          />
          <SelectComponent
            className="min-w-160"
            label="Access method"
            options={methodList}
            value={method}
            onChange={onChangeSort(setMethod, setPage)}
          />
          <SelectComponent
            className="min-w-160"
            label="Status"
            options={successList}
            value={success}
            onChange={onChangeSort(setSuccess, setPage)}
          />
        </header>

        {activityData?.results?.length ? (
          <table className="activity-page__table table full-width">
            <TableHead {...{ sortList, sortParams, setSortParams }} />
            <tbody>
              {activityData?.results?.map(
                ({ access_time, user, user_role, device, type, method, relay, success }, index) => {
                  const username = `${user?.first_name || ""} ${user?.last_name || ""}`.trim();
                  const self = profile.id === user?.id;
                  const unknown_user = !username && !user?.email && !user?.phone_number;
                  
                  const userCell = (
                    <>
                      <SkeletonMUI variant="circle" width={40} height={40} loading={firstLoad}>
                        <Avatar firstName={unknown_user ? "?" : user?.first_name} lastName={user?.last_name} src={user?.photo} userIcon={!unknown_user && !user?.registered} />
                      </SkeletonMUI>
                      <div className="activity-page__name-wrap">
                        <SkeletonMUI maxWidth={112} loading={firstLoad}>
                          <span className="truncate-ellipsis">
                            { self ? <strong>{username + " (You)"}</strong> : username || user?.email || user?.phone_number || "Unknown User"}
                          </span>
                        </SkeletonMUI>
                        <SkeletonMUI maxWidth={37} loading={firstLoad}>
                          { !unknown_user &&
                            <span className={`activity-page__role ${user_role} truncate-ellipsis`}>
                              { !user?.registered ? "Invited" : user_role ? capitalize(user_role) : "-" }
                            </span>
                          }
                        </SkeletonMUI>
                      </div>
                    </>
                  );

                  return (
                    <tr key={index}>
                      <td className={sortList[0].className}>
                        <SkeletonMUI maxWidth={122} height={20} loading={firstLoad}>
                          <span>{access_time ? dayjs(access_time).format("DD.MM.YYYY HH:mm") : "-"}</span>
                        </SkeletonMUI>
                      </td>
                      <td className={sortList[1].className}>
                        { user?.registered && !self ? 
                          <Link className="activity-page__user-wrap" to={{pathname: `${urls.usersUrl.path}/${user.id}`, state: {from: urls.activityUrl, user: user}}}>{userCell}</Link> : 
                          <div className="activity-page__user-wrap">{userCell}</div>
                        }
                      </td>
                      <td className={sortList[2].className + " activity-page__device"}>
                        <SkeletonMUI maxWidth={124} loading={firstLoad}>
                          <span className="truncate-ellipsis w-100">
                            {device?.device_name ? device?.device_name : "-"}
                          </span>
                        </SkeletonMUI>
                        <SkeletonMUI maxWidth={84} loading={firstLoad}>
                          <span className="activity-page__number truncate-ellipsis w-100">
                            {device?.serial_number ? device?.serial_number : "-"}
                          </span>
                        </SkeletonMUI>
                      </td>
                      <td className={sortList[3].className}>
                        <SkeletonMUI maxWidth={94} loading={firstLoad}>
                          <span>{type + (relay ? ` (${relay})` : "")}</span>
                        </SkeletonMUI>
                      </td>
                      <td className={sortList[4].className}>
                        <SkeletonMUI maxWidth={94} loading={firstLoad}>
                          <span>{method || "-"}</span>
                        </SkeletonMUI>
                      </td>
                      <td className={sortList[5].className}>
                        <SkeletonMUI variant="circle" width={24} height={24} loading={firstLoad}>
                          {success ? <SuccessIcon /> : <RejectIcon />}
                        </SkeletonMUI>
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        ) : (
          <NotFound noData small title="No activity" />
        )}

        {activityData?.pages > 1 && (
          <Pagination pageCount={activityData?.pages} active={page} onChange={handlePage(setPage, true)} />
        )}
      </div>
    </div>
  );
};

export default Activity;
