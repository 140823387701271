export const PROFILE = {
  SET_PHOTO: "SET_PHOTO",
  SET_PHOTO_SUCCESS: "SET_PHOTO_SUCCESS",
  SET_PHOTO_FAIL: "SET_PHOTO_FAIL",

  DELETE_PHOTO: "DELETE_PHOTO",
  DELETE_PHOTO_SUCCESS: "DELETE_PHOTO_SUCCESS",
  DELETE_PHOTO_FAIL: "DELETE_PHOTO_FAIL",

  EDIT_PROFILE: "EDIT_PROFILE",
  EDIT_PROFILE_SUCCESS: "EDIT_PROFILE_SUCCESS",
  EDIT_PROFILE_FAIL: "EDIT_PROFILE_FAIL",

  CHANGE_EMAIL: "CHANGE_EMAIL",
  CHANGE_EMAIL_SUCCESS: "CHANGE_EMAIL_SUCCESS",
  CHANGE_EMAIL_FAIL: "CHANGE_EMAIL_FAIL",

  VERIFY_EMAIL: "VERIFY_EMAIL",
  VERIFY_EMAIL_SUCCESS: "VERIFY_EMAIL_SUCCESS",
  VERIFY_EMAIL_FAIL: "VERIFY_EMAIL_FAIL",

  CHANGE_PHONE: "CHANGE_PHONE",
  CHANGE_PHONE_SUCCESS: "CHANGE_PHONE_SUCCESS",
  CHANGE_PHONE_FAIL: "CHANGE_PHONE_FAIL",

  VERIFY_PHONE: "VERIFY_PHONE",
  VERIFY_PHONE_SUCCESS: "VERIFY_PHONE_SUCCESS",
  VERIFY_PHONE_FAIL: "VERIFY_PHONE_FAIL",

  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAIL: "CHANGE_PASSWORD_FAIL"
};
