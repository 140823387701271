import React from "react";
import DialogMUI from "../../../Shared/DialogMUI";
import DefaultButton from "../../../Shared/Buttons/DefaultButton";
import "./Dialogs.scss";

const OverwriteAccessDialog = ({ open, onCLose, loading, onConfirm }) => {
  return (
    <DialogMUI className="users-dialog t-center" open={open} onClose={onCLose}>
      <h2 className="mb-16">Warning</h2>
      <p>
        The users marked with orange already have this type of access with different limitations. Overwrite the existing
        accesses?
      </p>
      <div className="btn-group">
        <DefaultButton className="gray-btn" disabled={loading} onClick={onCLose}>
          Cancel
        </DefaultButton>
        <DefaultButton loading={loading} disabled={loading} onClick={onConfirm}>
          Overwrite
        </DefaultButton>
      </div>
    </DialogMUI>
  );
};

export default OverwriteAccessDialog;
