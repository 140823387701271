import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { ReactComponent as ArrowIcon } from "./icons/left.svg";
import "./BackLink.scss";

const BackLink = ({ href, text, noMargin }) => {
  return (
    <Link className={`back-link${noMargin ? " back-link--no-margin" : ""}`} to={href}>
      <ArrowIcon className="back-link__icon" />
      <span className="back-link__text">Back to {text}</span>
    </Link>
  );
};

BackLink.propTypes = {
  href: PropTypes.string.isRequired,
  noMargin: PropTypes.bool,
  text: PropTypes.string.isRequired
};

export default BackLink;
