import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import Container from "./containers/Container";
import AuthContainer from "./containers/AuthContainer";
import RemoteLinkContainer from "./containers/RemoteLinkContainer";
import App from "./containers/app";
import { isLoggedIn } from "./helpers/auth";

export default (
  <App>
    <Switch>
      <Route
        path="/"
        exact
        render={() => (isLoggedIn() ? <Redirect to="/main" /> : <Redirect to="/auth" />)}
      />
      <Route path="/auth" component={AuthContainer} />
      <Route path="/main" component={Container} />
      <Route path="/link" component={RemoteLinkContainer} />
      <Route render={() => <Redirect to="/" />} />
    </Switch>
  </App>
);
