import React, { useState } from "react";
import { ClickAwayListener } from "@material-ui/core";
import { onChangeSearch } from "../../core/helpers/functions";
import InputField from "../InputField";
import { ReactComponent as ArrowIcon } from "../../assets/images/caret-down.svg";
import "./SearchableSelect.scss";

const SearchableSelect = ({ className, label, value = {}, options = [], searchLabel = "Search", disabled, onChange }) => {
  const rootClasses = ["searchable"];
  if (className) rootClasses.push(className);

  const [open, setOpen] = useState(false);
  const openDropdown = () => setOpen(true);
  const closeDropdown = () => {
    setOpen(false);
    setSearch("");
  };

  const [selected, setSelected] = useState(value);
  const selectItem = (value, label, firstInfo) => () => {
    if (typeof onChange === "function") onChange({ value, label, firstInfo });
    setSelected({ value, label, firstInfo});
    closeDropdown();
  };

  const [search, setSearch] = useState("");
  let filteredOptions = options?.filter(item => {
    return (
      item.label?.toLowerCase().includes(search.toLowerCase()) ||
      item.secondInfo?.toLowerCase().includes(search.toLowerCase())
    );
  });

  const noOptions = <span className="searchable__no-options">No options</span>;

  return (
    <div className={`${rootClasses.join(" ")}`}>
      <button className="searchable-btn" type="button" disabled={disabled} onClick={openDropdown}>
        {label && <span className={`searchable-btn__label${selected.value ? " shrink" : ""}`}>{label}</span>}
        {selected?.label && <span className="searchable-btn__values">{selected.label}</span>}
        <ArrowIcon className={`searchable-btn__arrow${open ? " searchable-btn__arrow--open" : ""}`} />
      </button>

      {open && (
        <ClickAwayListener onClickAway={closeDropdown}>
          <div className="searchable-dropdown">
            <InputField
              className="searchable-dropdown__search"
              label={searchLabel}
              type="search"
              onChange={onChangeSearch(setSearch)}
            />

            <div className="searchable-dropdown__list">
              {filteredOptions?.map(({ value, label, firstInfo, secondInfo }) => {
                if (!value) return (filteredOptions = []);
                return (
                  <button
                    key={value}
                    className={`searchable-item${selected.value === value ? " searchable-item--selected" : ""}`}
                    type="button"
                    onClick={selectItem(value, label, firstInfo)}
                  >
                    {label && <span className="searchable-item__title">{label}</span>}
                    {(firstInfo || secondInfo) && (
                      <span className="searchable-item__subtitle">
                        {firstInfo && `${firstInfo} • `}
                        {secondInfo && secondInfo}
                      </span>
                    )}
                  </button>
                );
              })}

              {!filteredOptions.length && noOptions}
            </div>
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default SearchableSelect;
