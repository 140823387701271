import {
    POST_LOGIN,
    GET_COUNTRIES,
    SEND_CODE,
    VERIFY_CODE,
    POST_REGISTER,
    RESTORE_SEND_CODE,
    RESTORE_VERIFY_CODE,
    CHANGE_PASSWORD,
    GET_IDP
} from "./actionTypes";

export function postLogin(data, isEmail = false) {
    return {
        type: POST_LOGIN,
        payload: {
            client: "default",
            request: {
                url: `/auth/sign-in/${isEmail ? "email" : "phone-number"}`,
                method: "post",
                data
            }
        }
    };
}

export function getCountries() {
    return {
        type: GET_COUNTRIES,
        payload: {
            client: "default",
            request: {
                url: "/auth/country-phone-codes",
                method: "get"
            }
        }
    };
}

export function sendCode(phone) {
    return {
        type: SEND_CODE,
        payload: {
            client: "default",
            request: {
                url: `/auth/registration/verification-code/send?phone_number=${phone}`,
                method: "post"
            }
        }
    };
}

export function restoreSendCode(phone) {
    return {
        type: RESTORE_SEND_CODE,
        payload: {
            client: "default",
            request: {
                url: `/auth/restore/verification-code/send?phone_number=${phone}`,
                method: "post"
            }
        }
    };
}

export function restoreSendCodeEmail(email) {
    return {
        type: RESTORE_SEND_CODE,
        payload: {
            client: "default",
            request: {
                url: `/auth/restore/verification-code/send?email=${encodeURIComponent(email)}`,
                method: "post"
            }
        }
    };
}

export function verifyCode(code, phone) {
    return {
        type: VERIFY_CODE,
        payload: {
            client: "default",
            request: {
                url: `/auth/registration/verification-code/verify?code=${code}&phone_number=${phone}`,
                method: "post"
            }
        }
    };
}

export function restoreVerifyCode(code, phone) {
    return {
        type: RESTORE_VERIFY_CODE,
        payload: {
            client: "default",
            request: {
                url: `/auth/restore/verification-code/verify?code=${code}&phone_number=${phone}`,
                method: "post"
            }
        }
    };
}

export function restoreVerifyCodeEmail(code, email) {
    return {
        type: RESTORE_VERIFY_CODE,
        payload: {
            client: "default",
            request: {
                url: `/auth/restore/verification-code/verify?code=${code}&email=${encodeURIComponent(email)}`,
                method: "post"
            }
        }
    };
}

export function postRegister(data) {
    return {
        type: POST_REGISTER,
        payload: {
            client: "default",
            request: {
                url: "/auth/registration/create-profile",
                method: "put",
                data
            }
        }
    };
}

export function sendCodeEmail(email) {
    return {
        type: SEND_CODE,
        payload: {
            client: "default",
            request: {
                url: `/auth/registration/verification-code/send?email=${encodeURIComponent(email)}`,
                method: "post"
            }
        }
    };
}

export function verifyCodeEmail(code, email) {
    return {
        type: VERIFY_CODE,
        payload: {
            client: "default",
            request: {
                url: `/auth/registration/verification-code/verify?code=${code}&email=${encodeURIComponent(email)}`,
                method: "post"
            }
        }
    };
}

export function changePassword(data) {
    return {
        type: CHANGE_PASSWORD,
        payload: {
            client: "default",
            request: {
                url: "/auth/restore/change-password",
                method: "post",
                data
            }
        }
    };
}

export function getIDP(idp) {
    return {
        type: GET_IDP,
        payload: {
            client: "default",
            request: {
                url: `../../v1/auth/identity-provider/${idp}`,
                method: "get"
            }
        }
    };
}
