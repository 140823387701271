import APP from "./actionTypes";

export function closeErrorSnack() {
  return {
    type: APP.ERROR_SNACK_CLOSE
  };
}

export function changePanelState() {
  return {
    type: APP.CHANGE_PANEL_STATE
  };
}

export function setConfirmationDialog(confirmationDialog) {
  return {
    type: APP.SET_CONFIRMATION_DIALOG,
    confirmationDialog
  };
}

export function closeConfirmationDialog() {
  return {
    type: APP.CLOSE_CONFIRMATION_DIALOG
  };
}

export function getProfile() {
  return {
    type: APP.PROFILE,
    payload: {
      request: {
        url: "/profile"
      }
    }
  };
}
