import React from "react";
import { Link } from "react-router-dom";
import urls from "../../../core/urls";
import TableHead from "../../../Shared/TableHead";
import Avatar from "../../../Shared/Avatar";
import SkeletonMUI from "../../../Shared/SkeletonMUI";
import CopyToClipboard from "../../../Shared/CopyToClipboard";
import { ReactComponent as CloseIcon } from "../../../assets/images/close.svg";
import "./OwnersAdminsTable.scss";

const OwnersAdminsTable = ({
  users,
  myRole,
  sortList,
  sortParams,
  setSortParams,
  firstLoad,
  onRemoveAccessOpen
}) => {
  const removeAccess = data => () => onRemoveAccessOpen(data);
  const noData = <b className="owners-admins-table__no-data">-</b>;

  return (
    <table className="owners-admins-table table full-width">
      <TableHead {...{ sortList, sortParams, setSortParams }} />
      <tbody>
        {users?.map(({ id, is_me, role, role_display, user }) => {
          const userId = `${user?.id ? user?.id : ""}`;
          const username = `${user?.first_name ? user?.first_name : ""}${user?.last_name ? ` ${user?.last_name}` : ""}`;
          const photo = `${user?.photo ? user?.photo : ""}`;
          const email = `${user?.email ? user?.email : ""}`;
          const phone = `${user?.phone_number ? user?.phone_number : ""}`;

          return (
            <tr key={id}>
              <td className={sortList[0].className}>
                {is_me ? (
                  <div className="owners-admins-table__user">
                    {username && <Avatar firstName={user?.first_name} lastName={user?.last_name} src={photo} />}
                    <b className="truncate-ellipsis">You</b>
                  </div>
                ) : (
                  <Link className="owners-admins-table__user" to={`${urls.usersUrl.path}/${userId}`}>
                    <SkeletonMUI variant="circle" width={40} height={40} loading={firstLoad}>
                      {username && <Avatar firstName={user?.first_name} lastName={user?.last_name} src={photo} />}
                    </SkeletonMUI>
                    <SkeletonMUI maxWidth={95} loading={firstLoad}>
                      {username ? <b className="truncate-ellipsis">{username}</b> : noData}
                    </SkeletonMUI>
                  </Link>
                )}
              </td>
              <td className={sortList[1].className}>
                <SkeletonMUI className="items-center" maxWidth={220} loading={firstLoad}>
                  {email ? (
                    <>
                      <span className="truncate-ellipsis">{email}</span>
                      <CopyToClipboard className="ml-4" text={email} ariaLabel="email" />
                    </>
                  ) : (
                    noData
                  )}
                </SkeletonMUI>
              </td>
              <td className={sortList[2].className}>
                <SkeletonMUI className="items-center" maxWidth={115} loading={firstLoad}>
                  {phone ? (
                    <>
                      <span className="truncate-ellipsis">{phone}</span>
                      <CopyToClipboard className="ml-4" text={phone} ariaLabel="phone" />
                    </>
                  ) : (
                    noData
                  )}
                </SkeletonMUI>
              </td>
              <td className={sortList[3].className}>
                <SkeletonMUI maxWidth={46} loading={firstLoad}>
                  {role && role_display && (
                    <span className={`owners-admins-table__role ${role} truncate-ellipsis`}>{role_display}</span>
                  )}
                  {myRole === "owner" && !is_me && (
                    <button
                      className="owners-admins-table__remove-btn"
                      type="button"
                      aria-label="Remove user"
                      onClick={removeAccess({ userId, username, email, phone })}
                    >
                      <CloseIcon />
                    </button>
                  )}
                </SkeletonMUI>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default OwnersAdminsTable;
