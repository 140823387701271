import { USERS } from "./usersActionTypes";

const INITIAL_STATE = {
  loading: true,
  buttonLoading: false,
  validationLoading: false,
  activeUsers: {},
  deviceAdmins: {},
  invitedUsers: {},
  userInfo: {},
  userDevices: {},
  shareOptions: {},
  deviceUsers: [],
  isValid: null,
  validatedData: []
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case USERS.GET_ACTIVE_USERS:
      return { ...state, loading: true };
    case USERS.GET_ACTIVE_USERS_SUCCESS:
      return { ...state, activeUsers: action?.payload?.data, loading: false };
    case USERS.GET_ACTIVE_USERS_FAIL:
      return { ...state, loading: false };

    case USERS.GET_DEVICE_ADMINS:
      return { ...state, loading: true };
    case USERS.GET_DEVICE_ADMINS_SUCCESS:
      return { ...state, deviceAdmins: action?.payload?.data, loading: false };
    case USERS.GET_DEVICE_ADMINS_FAIL:
      return { ...state, loading: false };

    case USERS.GET_INVITED_USERS:
      return { ...state, loading: true };
    case USERS.GET_INVITED_USERS_SUCCESS:
      return { ...state, invitedUsers: action?.payload?.data, loading: false };
    case USERS.GET_INVITED_USERS_FAIL:
      return { ...state, loading: false };

    case USERS.GET_USER_INFO:
      return { ...state, loading: true };
    case USERS.GET_USER_INFO_SUCCESS:
      return { ...state, userInfo: action?.payload?.data, loading: false };
    case USERS.GET_USER_INFO_FAIL:
      return { ...state, loading: false, userInfo: null };

    case USERS.GET_USER_DEVICES:
      return { ...state, loading: true, userDevices: {} };
    case USERS.GET_USER_DEVICES_SUCCESS:
      return { ...state, userDevices: action?.payload?.data, loading: false };
    case USERS.GET_USER_DEVICES_FAIL:
      return { ...state, loading: false, userDevices: {} };

    case USERS.GET_SHARE_OPTIONS:
      return { ...state, loading: true };
    case USERS.GET_SHARE_OPTIONS_SUCCESS:
      return { ...state, shareOptions: action?.payload?.data, loading: false };
    case USERS.GET_SHARE_OPTIONS_FAIL:
      return { ...state, loading: false };

    case USERS.GET_DEVICE_USERS:
      return { ...state, loading: true };
    case USERS.GET_DEVICE_USERS_SUCCESS:
      return { ...state, deviceUsers: action?.payload?.data, loading: false };
    case USERS.GET_DEVICE_USERS_FAIL:
      return { ...state, loading: false };

    case USERS.GET_VALIDATE_VALUE:
      return { ...state, validationLoading: true };
    case USERS.GET_VALIDATE_VALUE_SUCCESS:
      return { ...state, isValid: action?.payload?.data?.is_valid, validationLoading: false };
    case USERS.GET_VALIDATE_VALUE_FAIL:
      return { ...state, validationLoading: false };

    case USERS.POST_VALIDATE_EMAILS_PHONES:
      return { ...state, validationLoading: true };
    case USERS.POST_VALIDATE_EMAILS_PHONES_SUCCESS:
      return { ...state, validatedData: action?.payload?.data?.users, validationLoading: false };
    case USERS.POST_VALIDATE_EMAILS_PHONES_FAIL:
      return { ...state, validationLoading: false };

    case USERS.POST_INVITE_USERS:
      return { ...state, buttonLoading: true };
    case USERS.POST_INVITE_USERS_SUCCESS:
      return { ...state, buttonLoading: false };
    case USERS.POST_INVITE_USERS_FAIL:
      return { ...state, buttonLoading: false };

    case USERS.SET_USER_INFO:
      return { ...state, userInfo: action.payload };

    default:
      return state;
  }
}
