import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { MenuItem } from "@material-ui/core";
import { changePhone } from "../profileActions";
import { editPhoneSchema } from "../../../core/helpers/validations";
import DialogMUI from "../../../Shared/DialogMUI";
import InputField from "../../../Shared/InputField";
import DefaultButton from "../../../Shared/Buttons/DefaultButton";
import ConfirmDialog from "./ConfirmDialog";
import "./Dialogs.scss";

const EditPhoneDialog = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const buttonLoading = useSelector(({ app }) => app.buttonLoading);
  const countries = useSelector(({ authorization }) => authorization.countries);
  const { country_phone_codes } = countries;

  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: { errors, isValid }
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(editPhoneSchema),
    defaultValues: {
      country: "",
      phone: ""
    }
  });

  const onDialogClose = () => {
    onClose();
    reset();
  };

  const [phoneNumber, setPhoneNumber] = useState("");
  const [confirm, setConfirm] = useState(false);
  const onConfirmOpen = () => {
    setConfirm(true);
    onDialogClose();
  };
  const onConfirmClose = () => setConfirm(false);

  const onSubmit = async data => {
    const { country, phone } = data;
    setPhoneNumber(`+${country}${phone}`);

    const res = await dispatch(changePhone(`+${country}${phone}`));
    const errors = res.error?.response.data;

    if (res?.payload) onConfirmOpen();
    if (res?.error) {
      errors.phone_number && setError("phone", { type: "manual", message: errors.phone_number[0].message });
      errors.non_field_errors && setError("phone", { type: "manual", message: errors.non_field_errors[0].message });
    }
  };

  return (
    <>
      <DialogMUI className="profile-dialog" open={open} onClose={onDialogClose}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h2 className="profile-dialog__title">Edit your phone number</h2>
          <p className="profile-dialog__info">Please enter your new phone number</p>
          <Controller
            name="country"
            control={control}
            render={({ field }) => (
              <InputField
                className="mb-24"
                label="Country / Region"
                fullWidth
                select
                error={errors.country?.message}
                inputProps={field}
              >
                {country_phone_codes &&
                  country_phone_codes.map(({ country_iso_code, phone_code, country_name }) => (
                    <MenuItem key={country_iso_code} value={phone_code}>
                      {country_name} (+{phone_code})
                    </MenuItem>
                  ))}
              </InputField>
            )}
          />
          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <InputField type="tel" label="Phone number" fullWidth error={errors.phone?.message} inputProps={field} />
            )}
          />
          <DefaultButton
            className="profile-dialog__btn"
            disabled={!isValid || buttonLoading}
            loading={buttonLoading}
            formAction
          >
            Continue
          </DefaultButton>
        </form>
      </DialogMUI>

      <ConfirmDialog open={confirm} onClose={onConfirmClose} phone={phoneNumber} />
    </>
  );
};

export default EditPhoneDialog;
