import React from "react";
import DialogMUI from "../../../Shared/DialogMUI";
import DefaultButton from "../../../Shared/Buttons/DefaultButton";
import { ReactComponent as InfoIcon } from "../../../assets/images/info-2.svg";

const RemoveUserAccessDialog = ({
  open,
  onCLose,
  revokeType,
  deviceName,
  username,
  accessType,
  accessName,
  loading,
  onConfirm
}) => {
  let title = "Remove access";
  let text = accessName ? `remove ${accessName ? accessName : ""} access` : "remove all accesses";

  if (revokeType) {
    title = "Revoke invitation";
    text = accessName ? "revoke invitation" : "revoke all invitations";
  }

  return (
    <DialogMUI className="remove-user-access-dialog" open={open} onClose={onCLose}>
      <h2 className="mb-32">{title}</h2>
      <p className="mb-24">
        Are you sure you want to {text} to <b>{deviceName}</b> for{" "}
        <b>{username ? username : "this" + " user"}</b>?
      </p>

      {accessType && (accessType === "digital_key" || accessType === "vrfid") && (
        <div className="remove-user-access-dialog__info">
          <InfoIcon />
          <b>Digital key & VRFID can be deleted only together</b>
        </div>
      )}

      {!revokeType && (
        <p className="my-24">
          Please, note that the existing key will be deleted. A new key will be generated. This new key will be taken
          from you key pool.
        </p>
      )}

      <div className="btn-group">
        <DefaultButton className="gray-btn" disabled={loading} onClick={onCLose}>
          Cancel
        </DefaultButton>
        <DefaultButton loading={loading} disabled={loading} onClick={onConfirm}>
          {revokeType ? "Revoke" : "Remove"}
        </DefaultButton>
      </div>
    </DialogMUI>
  );
};

export default RemoveUserAccessDialog;
