import React from "react";
import PropTypes from "prop-types";
import Select, { components } from "react-select";

import "./SelectComponent.scss";

import { ReactComponent as ExpandIcon } from "./icons/chevron-down.svg";

export const DropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <div className={props.selectProps.menuIsOpen ? "select-indicator indicator-active" : "select-indicator"}>
          <ExpandIcon alt="Expand icon" />
        </div>
      </components.DropdownIndicator>
    )
  );
};

const SelectComponent = ({
  className = "",
  disabled,
  id,
  input,
  isClearable,
  isSearchable = false,
  label,
  loading,
  loadingMessage = "Loading...",
  menuIsOpen,
  noOptionsMessage = "No options",
  onBlur,
  onChange,
  onKeyDown,
  options,
  placeholder,
  reduxForm = false,
  value,
  defaultValue,
  withSearchIcon
}) => (
  <div className={`select-component-wrap${className && ` ${className}`}`}>
    {label && <span className="select-component-wrap__label">{label}</span>}
    <Select
      {...input}
      inputId={id}
      menuIsOpen={menuIsOpen}
      className={`select-component${isSearchable ? " select-component--searchable" : ""}${
        withSearchIcon ? " select-component--search-icon" : ""
      }`}
      classNamePrefix="select"
      isDisabled={disabled}
      isLoading={loading}
      isClearable={isClearable}
      isSearchable={isSearchable}
      value={value}
      defaultValue={defaultValue}
      options={options}
      onChange={reduxForm ? value => input.onChange(value) : onChange}
      onBlur={reduxForm ? () => input.onBlur(input.value) : onBlur}
      loadingMessage={() => loadingMessage}
      placeholder={placeholder}
      onKeyDown={onKeyDown}
      components={{ DropdownIndicator }}
      noOptionsMessage={() => noOptionsMessage}
    />
  </div>
);

SelectComponent.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  input: PropTypes.object,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  label: PropTypes.string,
  loading: PropTypes.bool,
  loadingMessage: PropTypes.string,
  menuIsOpen: PropTypes.bool,
  noOptionsMessage: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  reduxForm: PropTypes.bool,
  value: PropTypes.any,
  withSearchIcon: PropTypes.bool
};

export default SelectComponent;
