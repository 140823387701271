import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import AuthReducer from "../Features/Authorization/reducer";
import profileReducer from "../Features/ProfileSettings/profileReducer";
import devicesReducer from "../Features/Devices/devicesReducer";
import usersReducer from "../Features/Users/usersReducer";
import companiesReducer from "../Features/Companies/companiesReducer";
import activityReducer from "../Features/Activity/activityReducer";
import remoteLinkReducer from "../Features/RemoteLink/remoteLinkReducer";
import APP from "./actionTypes";

const INITIAL_STATE = {
  loading: false,
  buttonLoading: false,
  errorSnack: false,
  errorSnackText: "",
  successSnack: "",
  successSnackText: "",
  profile: {
    is_email_confirmed: true
  }
};

const appReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case APP.LOADING:
      return { ...state, loading: payload };
    case APP.BUTTON_LOADING:
      return { ...state, buttonLoading: payload };
    case APP.ERROR_SNACK_OPEN:
      return { ...state, errorSnack: true, errorSnackText: payload };
    case APP.ERROR_SNACK_CLOSE:
      return { ...state, errorSnack: false };
    case APP.SUCCESS_SNACK_OPEN:
      return { ...state, successSnack: true, successSnackText: payload };
    case APP.SUCCESS_SNACK_CLOSE:
      return { ...state, successSnack: false };
    case APP.PROFILE_SUCCESS:
      return { ...state, profile: payload?.data };
    default:
      return state;
  }
};

const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    authorization: AuthReducer,
    app: appReducer,
    profileSettings: profileReducer,
    devices: devicesReducer,
    users: usersReducer,
    companies: companiesReducer,
    activity: activityReducer,
    remoteLink: remoteLinkReducer
  });

export default rootReducer;
