import React from "react";
import { Link } from "react-router-dom";
import ProfileDropdown from "../ProfileDropdown";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { ReactComponent as NotificationIcon } from "../../assets/images/notification.svg";
import "./Header.scss";

const Header = ({ headerText, headerLink, headerLinkTitle, notifyIcon, profile }) => {
  return (
    <header className="header">
      <div className="header__wrap">
        <Link className="header__logo-link" to="/">
          <Logo className="header__logo good-hover" />
        </Link>

        <div className="header__col">
          {headerText && <span className="header__text">{headerText}</span>}
          {headerLink && headerLinkTitle && (
            <Link className="header__link" to={headerLink}>
              {headerLinkTitle}
            </Link>
          )}
          {notifyIcon && <NotificationIcon />}
          {profile && <ProfileDropdown />}
        </div>
      </div>
    </header>
  );
};

export default Header;
