import { all, delay, put, takeEvery } from "redux-saga/effects";
import { handleAuthLoaders } from "../Features/Authorization/saga";
import { handleProfileLoaders } from "../Features/ProfileSettings/profileSaga";
import { handleMqttOpenLock } from "../Features/Devices/devicesSaga";
import { handleUsersLoaders } from "../Features/Users/usersSaga";

export default function* rootSaga() {
  yield all([handleAuthLoaders(), handleSnack(), handleProfileLoaders(), handleMqttOpenLock(), handleUsersLoaders()]);
}

export function* openErrorSnack(e) {
  yield put({
    type: "ERROR_SNACK_OPEN",
    payload:
      e && e.error && e.error.response && e.error.response.data
        ? typeof Object?.values(e.error.response.data)[0] === "string"
          ? Object?.values(e.error.response.data)[0]
          : Object?.values(e.error.response.data)[0][0]
        : typeof e.error === "string"
        ? e.error
        : "Something went wrong"
  });
}

export function* openSuccessSnack(e) {
  yield put({ type: "SUCCESS_SNACK_OPEN", payload: e });
}

export function* resetErrorSnack() {
  yield delay(100);
  yield put({ type: "ERROR_SNACK_CLOSE" });
}

export function* resetSuccessSnack() {
  yield delay(100);
  yield put({ type: "SUCCESS_SNACK_CLOSE" });
}

export function* handleSnack() {
  yield takeEvery("ERROR_SNACK_OPEN", resetErrorSnack);
  yield takeEvery("SUCCESS_SNACK_OPEN", resetSuccessSnack);
}

export function* enableLoader() {
  yield put({ type: "LOADING", payload: true });
}

export function* disableLoader() {
  yield put({ type: "LOADING", payload: false });
}

export function* enableButtonLoader() {
  yield put({ type: "BUTTON_LOADING", payload: true });
}

export function* disableButtonLoader() {
  yield put({ type: "BUTTON_LOADING", payload: false });
}
