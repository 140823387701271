import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCountries } from "../Authorization/actions";
import ProfilePhoto from "./ProfilePhoto";
import ProfileField from "./ProfileField";
import EditNameDialog from "./Dialogs/EditNameDialog";
import EditEmailDialog from "./Dialogs/EditEmailDialog";
import EditPhoneDialog from "./Dialogs/EditPhoneDialog";
import ChangePasswordDialog from "./Dialogs/ChangePasswordDialog";
import "./ProfileSettings.scss";

const ProfileSettings = () => {
  const dispatch = useDispatch();
  const { first_name, last_name, photo, email, phone_number, is_federated_user } = useSelector(({ app }) => app.profile);
  const fields = [
    {
      label: "Full name",
      value: `${first_name ? first_name : ""} ${last_name ? last_name : ""}`,
      func: () => onDialogOpen("name")
    },
    { label: "Email", value: email ? email : "-", func: () => onDialogOpen("email") },
    { label: "Phone number", value: phone_number ? phone_number : "-", func: () => onDialogOpen("phone") },
    ...(!is_federated_user ? [{ label: "Password", value: "••••••••••", func: () => onDialogOpen("password") }] : [])
  ];

  useEffect(() => {
    dispatch(getCountries());
  }, []);

  const [dialog, setDialog] = useState(null);
  const onDialogOpen = type => setDialog(type);
  const onDialogClose = () => setDialog(false);

  return (
    <main className="profile-settings page-wrap">
      <h1 className="page-title">Profile settings</h1>
      <div className="section-wrap p-48">
        <div className="profile-settings__inner">
          <ProfilePhoto photo={photo} firstName={first_name} lastName={last_name} />
          {fields.map(({ label, value, func }) => (
            <ProfileField key={label} label={label} text={value} onButtonClick={func} editable={!is_federated_user} />
          ))}
          { is_federated_user &&
            <ProfileField label="Password" text={<i className="profile-settings__sso-text">Authentication via Single Sign On</i>} editable={false} />
          }
        </div>
      </div>

      {dialog === "name" && (
        <EditNameDialog open={dialog === "name"} onClose={onDialogClose} firstName={first_name} lastName={last_name} />
      )}
      <EditEmailDialog open={dialog === "email"} onClose={onDialogClose} />
      <EditPhoneDialog open={dialog === "phone"} onClose={onDialogClose} />
      <ChangePasswordDialog open={dialog === "password"} onClose={onDialogClose} />
    </main>
  );
};

export default ProfileSettings;
