export const defaultSort = { value: "", label: "Any" };

export const accessTypeData = [
  { value: "DIGITAL_KEY_ACCESS", label: "Digital key", name: "digital_key" },
  { value: "PIN_ACCESS", label: "PIN-code", name: "pin" },
  { value: "RFID_ACCESS", label: "RFID", name: "rfid" },
  { value: "VIRTUAL_RFID_ACCESS", label: "VRFID", name: "vrfid" },
  { value: "LINK_ACCESS", label: "Link", name: "link" }
];

export const pinCodeOptions = [
  { value: 4, label: "4-digit" },
  { value: 5, label: "5-digit" },
  { value: 6, label: "6-digit" }
];

export const limitTypeData = [
  { value: "unlimited", label: "Full access" },
  { value: "datetime", label: "Date & time" },
  { value: "total_count", label: "Number of usages" },
  { value: "datetime_count", label: "Date & time with limited usages" }
];

export const AUTH_KEYCLOAK = "keycloak";
export const AUTH_NATIVE = "native";