import * as yup from "yup";
import { maxChars, incorrectEmail, incorrectPhone, minChars } from "./validationTexts";

export const editNameSchema = yup.object({
  first_name: yup
    .string()
    .required("Required")
    .max(32, maxChars(32)),
  last_name: yup
    .string()
    .required("Required")
    .max(32, maxChars(32))
});

export const editEmailSchema = yup.object({
  email: yup
    .string()
    .email()
    .required("Required")
    .max(32, maxChars(32))
});

export const editPhoneSchema = yup.object({
  country: yup.string().required("Required"),
  phone: yup.number().required("Required")
});

export const changePasswordSchema = yup.object({
  old_password: yup
    .string()
    .min(8, minChars(8))
    .required("Required")
    .max(255, maxChars(255))
    .notOneOf([yup.ref("new_password"), null], "Passwords must not match"),
  new_password: yup
    .string()
    .min(8, minChars(8))
    .required("Required")
    .max(255, maxChars(255))
    .notOneOf([yup.ref("old_password"), null], "Passwords must not match")
});

export const editDeviceSchema = yup.object({
  device_name: yup
    .string()
    .required("Required")
    .max(255, maxChars(255)),
  address: yup.string().max(255, maxChars(255)),
  latitude: yup.number().nullable(true),
  longitude: yup.number().nullable(true),
  address_description: yup.string().max(255, maxChars(255))
});

export const manageCompanySchema = yup.object({
  account_name: yup
    .string()
    .required("Required")
    .max(15, maxChars(15))
    .matches(/^[a-zA-Z\d ]*$/, "Use only Latin letters and digits"),
  full_company_name: yup.string().required("Required"),
  description: yup
    .string()
    .required("Required")
    .max(250, maxChars(250)),
  country_code: yup.string().required("Required"),
  city: yup
    .string()
    .required("Required")
    .matches(/^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/, "Use only Latin letters")
    .max(50, maxChars(50)),
  address: yup
    .string()
    .required("Required")
    .max(100, maxChars(100)),
  zip_code: yup
    .string()
    .required("Required")
    .max(20, maxChars(20)),
  registration_code: yup
    .string()
    .required("Required")
    .max(20, maxChars(20)),
  vat_code: yup
    .string()
    .required("Required")
    .max(20, maxChars(20)),
  contact_email: yup
    .string()
    .email(incorrectEmail)
    .required("Required"),
  contact_phone: yup
    .string()
    .required("Required")
    .matches(/^(\+)?(\d){10,16}$/g, incorrectPhone),
  invoice_email: yup
    .string()
    .email(incorrectEmail)
    .required("Required")
});
