import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { ClickAwayListener } from "@material-ui/core";
import urls from "../../../core/urls";
import { accessTypeData } from "../../../core/helpers/constants";
import TableHead from "../../../Shared/TableHead";
import Avatar from "../../../Shared/Avatar";
import SkeletonMUI from "../../../Shared/SkeletonMUI";
import CopyToClipboard from "../../../Shared/CopyToClipboard";
import Tooltip from "../../../Shared/Tooltip";
import { ReactComponent as MoreIcon } from "../../../assets/images/more.svg";
import { ReactComponent as DowngradeIcon } from "./icons/downgrade.svg";
import { ReactComponent as UpgradeIcon } from "./icons/upgrade.svg";
import { ReactComponent as CloseIcon } from "../../../assets/images/close.svg";
import { ReactComponent as CaretIcon } from "../../../assets/images/caret-down-3.svg";
import { ReactComponent as AddIcon } from "../../../assets/images/add.svg";
import "./ManageUsersTable.scss";

const ManageUsersTable = ({
                            users,
                            deviceId,
                            deviceAccess,
                            deviceType,
                            deviceRemoteAccess,
                            sortList,
                            sortParams,
                            setSortParams,
                            firstLoad,
                            inviteTab,
                            onChangeRoleOpen,
                            onRemoveAccessOpen
                          }) => {
  const [more, setMore] = useState(null);
  const openMore = id => () => setMore(id);
  const closeMore = () => setMore(null);

  const [expand, setExpand] = useState(null);
  const handleExpand = id => () => setExpand(prev => (prev === id ? null : id));

  const changeRole = data => () => {
    onChangeRoleOpen(data);
    closeMore();
  };

  const removeAccess = data => () => {
    onRemoveAccessOpen(data);
    closeMore();
  };

  const noData = <b className="manage-users-table__no-data">-</b>;

  return (
    <table className="manage-users-table table full-width">
      <TableHead {...{ sortList, sortParams, setSortParams }} />
      <tbody>
      {users?.map(({ id, user, access, relation_type, relation_type_display, is_company_owner_or_admin }) => {
        const userId = `${user?.id ? user?.id : ""}`;
        const username = `${user?.first_name ? user?.first_name : ""}${user?.last_name ? ` ${user?.last_name}` : ""}`;
        const photo = `${user?.photo ? user?.photo : ""}`;
        const email = `${user?.email ? user?.email : ""}`;
        const phone = `${user?.phone_number ? user?.phone_number : ""}`;
        let accessData = accessTypeData.map(item => {
          return {
            access_type: item?.value,
            access_type_display: item?.label,
            access_type_query: item?.name,
            ...access?.[item?.name]
          };
        });
        const accessLabels = accessData
          .map(({ access_type_display, limit_type }) => limit_type && access_type_display)
          .filter(Boolean);

        if (deviceType === "EURO_1" || deviceType === "STANDARD_1") {
          accessData = accessData.filter(item => item.access_type === "DIGITAL_KEY_ACCESS");
        }

        return (
          <Fragment key={id}>
            <tr>
              <td className={sortList[0].className}>
                <div className="manage-users-table__user">
                  <SkeletonMUI variant="circle" width={40} height={40} loading={firstLoad}>
                    {username && <Avatar firstName={user?.first_name} lastName={user?.last_name} src={photo} />}
                  </SkeletonMUI>
                  <div className="manage-users-table__name-wrap">
                    <SkeletonMUI className="manage-users-table__name" maxWidth={85} loading={firstLoad}>
                      {username ? (
                        <span className="manage-users-table__name truncate-ellipsis" title={username}>
                            {username}
                          </span>
                      ) : (
                        noData
                      )}
                    </SkeletonMUI>
                    <SkeletonMUI className="manage-users-table__role" maxWidth={37} loading={firstLoad}>
                      {relation_type_display && (
                        <span
                          className={`manage-users-table__role manage-users-table__role--${relation_type_display}`}
                        >
                            {relation_type_display}
                          </span>
                      )}
                    </SkeletonMUI>
                  </div>
                </div>
              </td>
              <td className={sortList[1].className}>
                <SkeletonMUI className="items-center" maxWidth={180} loading={firstLoad}>
                  {email ? (
                    <>
                        <span className="truncate-ellipsis" title={email}>
                          {email}
                        </span>
                      <CopyToClipboard className="ml-4" text={email} ariaLabel="email" />
                    </>
                  ) : (
                    noData
                  )}
                </SkeletonMUI>
              </td>
              <td className={sortList[2].className}>
                <SkeletonMUI className="items-center" maxWidth={115} loading={firstLoad}>
                  {phone ? (
                    <>
                        <span className="truncate-ellipsis" title={phone}>
                          {phone}
                        </span>
                      <CopyToClipboard className="ml-4" text={phone} ariaLabel="phone" />
                    </>
                  ) : (
                    noData
                  )}
                </SkeletonMUI>
              </td>
              <td className={`manage-users-table__access-cell ${sortList[3].className}`}>
                <SkeletonMUI variant="rect" maxWidth={58} height={27} loading={firstLoad}>
                  {accessLabels?.length
                    ? accessLabels.map((label, index) => (
                      <span key={index} className="manage-users-table__access-label">
                            {label}
                          </span>
                    ))
                    : noData}
                </SkeletonMUI>
                <SkeletonMUI variant="rect" maxWidth={58} height={27} loading={firstLoad} />
                <SkeletonMUI variant="rect" maxWidth={58} height={27} loading={firstLoad} />
              </td>
              <td className={sortList[4].className}>
                <SkeletonMUI className="mr-8" variant="circle" width={32} height={32} loading={firstLoad}>
                  {!is_company_owner_or_admin &&
                    (deviceAccess === "DEVICE_OWNER" ||
                      (deviceAccess === "DEVICE_ADMIN" && relation_type === "DEVICE_USER")) && (
                      <div className="manage-users-table__more-wrap">
                        <button
                          className={`manage-users-table__more-btn${
                            more === id ? " manage-users-table__more-btn--active" : ""
                          }`}
                          type="button"
                          aria-label="More actions"
                          onClick={openMore(id)}
                        >
                          <MoreIcon />
                        </button>

                        {more === id && (
                          <ClickAwayListener onClickAway={closeMore}>
                            <div className="manage-users-table__more-menu">
                              {deviceAccess === "DEVICE_OWNER" &&
                                access?.digital_key?.access_type === "DIGITAL_KEY_ACCESS" &&
                                access?.digital_key?.limit_type === "unlimited" && (
                                  <>
                                    {relation_type === "DEVICE_ADMIN" && (
                                      <button
                                        className="manage-users-table__more-item"
                                        type="button"
                                        onClick={changeRole({ userId, username, role: "DEVICE_USER" })}
                                      >
                                        <DowngradeIcon />
                                        <span>Make user</span>
                                      </button>
                                    )}
                                    {relation_type === "DEVICE_USER" && (
                                      <button
                                        className="manage-users-table__more-item manage-users-table__more-item--red"
                                        type="button"
                                        onClick={changeRole({ userId, username, role: "DEVICE_ADMIN" })}
                                      >
                                        <UpgradeIcon />
                                        <span>Make admin</span>
                                      </button>
                                    )}
                                  </>
                                )}
                              <button
                                className="manage-users-table__more-item manage-users-table__more-item--red"
                                type="button"
                                onClick={removeAccess({ userId, username, email, phone })}
                              >
                                <CloseIcon />
                                <span>{inviteTab ? "Revoke all invitations" : "Remove all accesses"}</span>
                              </button>
                            </div>
                          </ClickAwayListener>
                        )}
                      </div>
                    )}
                </SkeletonMUI>

                <SkeletonMUI variant="circle" width={32} height={32} loading={firstLoad}>
                  <button
                    className={`manage-users-table__expand-btn${
                      expand === id ? " manage-users-table__expand-btn--active" : ""
                    }`}
                    type="button"
                    aria-label="Show access actions"
                    onClick={handleExpand(id)}
                  >
                    <CaretIcon />
                  </button>
                </SkeletonMUI>
              </td>
            </tr>

            {accessData.map(
              (
                {
                  access_type_display,
                  access_type_query,
                  limit_type,
                  limit_type_display,
                  total_count_remaining,
                  date_from,
                  date_to
                },
                index
              ) => {
                const removeData = {
                  userId,
                  username,
                  email,
                  phone,
                  accessType: access_type_query,
                  accessName: access_type_display
                };
                let accessLink = `${urls.shareAccessUrl.path}/${deviceId}/?access=${access_type_query}&`;
                if (inviteTab) accessLink = accessLink + `invite=${encodeURIComponent(email || phone)}`;
                if (!inviteTab) accessLink = accessLink + `user=${userId}`;

                return (
                  <tr key={index} className={`expand-row${expand === id ? " expand-row--active" : ""}`}>
                    <td className="w-60" />
                    <td className="w-14">{access_type_display ? access_type_display : noData}</td>
                    <td className="w-18" style={!limit_type_display ? { opacity: "0.5" } : null}>
                      <div>
                        <span>{limit_type_display ? limit_type_display : "No access"}</span>
                        {limit_type === "total_count" &&
                          <span className="expand-row__limit">
                                {total_count_remaining} times left
                              </span>
                        }
                        {limit_type === "datetime" &&
                          <span className="expand-row__limit">
                                {date_from && dayjs(date_from).format("DD.MM.YYYY HH:mm")}
                            {date_from && date_to && " - "}
                            {date_to && dayjs(date_to).format("DD.MM.YYYY HH:mm")}
                              </span>
                        }
                        {limit_type === "datetime_count" &&
                          <><span className="expand-row__limit">
                                {`${total_count_remaining} times left`}
                              </span>
                            <span className="expand-row__limit">
                                {`${dayjs(date_from).format("DD.MM.YYYY HH:mm")} - ${dayjs(date_to).format("DD.MM.YYYY HH:mm")}`}
                              </span></>
                        }
                      </div>
                    </td>
                    <td className="w-8">
                      {((deviceAccess === "DEVICE_OWNER" && (
                          (relation_type === "DEVICE_USER" || inviteTab || relation_type === "DEVICE_ADMIN" && (
                            !is_company_owner_or_admin || (is_company_owner_or_admin && (
                              access_type_query === "pin" || access_type_query === "rfid"
                            ))
                          ))
                        )) || (deviceAccess === "DEVICE_ADMIN" && (relation_type === "DEVICE_USER" || inviteTab))) &&
                        (limit_type_display ? (
                          <button
                            className="expand-row__btn expand-row__btn--red"
                            type="button"
                            aria-label="Remove access"
                            onClick={removeAccess(removeData)}
                          >
                            <CloseIcon />
                          </button>
                        ) : (
                          access_type_query === "link" && !deviceRemoteAccess ?
                            <div className="expand-row__btn expand-row__btn--disabled">
                              <Tooltip direction="left" text="The device must have remote access enabled">
                                <AddIcon />
                              </Tooltip>
                            </div> :
                            <Link className="expand-row__btn" to={accessLink} aria-label="Add access">
                              <AddIcon />
                            </Link>
                        ))}
                    </td>
                  </tr>
                );
              }
            )}
          </Fragment>
        );
      })}
      </tbody>
    </table>
  );
};

export default ManageUsersTable;
