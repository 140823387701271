import React from "react";
import { Link } from "react-router-dom";
import { Button, CircularProgress } from "@material-ui/core";
import PropTypes from "prop-types";

import "./styles.scss";

const propTypes = {
    children: PropTypes.node.isRequired,
    variant: PropTypes.string,
    type: PropTypes.string,
    size: PropTypes.string,
    to: PropTypes.string,
    formAction: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    loading: PropTypes.bool,
    classes: PropTypes.string,
    target: PropTypes.string,
    startIcon: PropTypes.node,
};

const DefaultButton = ({
    children,
    variant = "contained",
    type = "button",
    size,
    to,
    formAction,
    disabled,
    onClick,
    loading,
    className = "",
    startIcon
}) => {
    const loadingSize = size === "small" ? 16 : 24;
    if (type === "link") {
        return (
            <Button
                component={Link}
                to={to}
                variant={variant}
                size={size}
                classes={{
                    root: `default-button default-button--${variant} ${className}`
                }}
                startIcon={startIcon}
            >
                {children}
            </Button>
        );
    } else {
        return (
            <Button
                type={type === "submit" ? "submit" : formAction ? "submit" : "button"}
                // type={"submit"}
                variant={variant}
                size={size}
                disabled={disabled}
                onClick={onClick}
                classes={{
                    root: `default-button default-button--${variant} ${className} ${loading && "loading"}`
                }}
                startIcon={startIcon}
            >
                {loading ? <CircularProgress size={loadingSize} color="inherit" /> : children}
            </Button>
        );
    }
};

DefaultButton.propTypes = propTypes;

export default DefaultButton;
