import React, { useState } from "react";
import { ReactComponent as InfoIcon } from "./icons/info-icon.svg";
import { ReactComponent as CloseIcon } from "./icons/close.svg";
import "./Toast.scss";

const Toast = ({ text }) => {
  const [toast, setToast] = useState(true);
  const onToastClose = () => setToast(false);

  if (!toast) return null;
  return (
    <div className="toast">
      <InfoIcon />
      <span className="toast__text">{text}</span>
      <button className="toast__btn" type="button" onClick={onToastClose}>
        <CloseIcon />
        <span className="visually-hidden">Close toast</span>
      </button>
    </div>
  );
};

export default Toast;
