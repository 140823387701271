import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router";
import { useForm, Controller } from "react-hook-form";
import DefaultButton from "../../../Shared/Buttons/DefaultButton";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import InputField from "../../../Shared/InputField";
import successImg from "../../../assets/images/success-img.png";
import urls from "../../../core/urls";
import { MenuItem } from "@material-ui/core";
import { connect } from "react-redux";
import {
    getCountries,
    restoreSendCode,
    restoreSendCodeEmail,
    restoreVerifyCode,
    restoreVerifyCodeEmail,
    changePassword
} from "../actions";
// import { incorrectEmail } from "../../../core/helpers/validationTexts";
import _ from "lodash";
import { ReactComponent as AlertIcon } from "../../../assets/images/alert.svg";
import { toastErrors } from "../../../core/helpers/toastErrors";

const ResetPassword = ({
    withNumber,
    getCountries,
    countries: { country_phone_codes },
    restoreSendCode,
    restoreSendCodeEmail,
    restoreVerifyCode,
    restoreVerifyCodeEmail,
    changePassword
}) => {
    useEffect(() => {
        getCountries();
    }, []);

    const schema = useMemo(
        () =>
            yup.object().shape({
                // password: yup.string().required("No password provided"),
                // country: yup.string().required(),
                // phone: yup.string().required(),
                // email: yup
                //     .string()
                //     .email(incorrectEmail)
                //     .required("No email provided")
            }),
        []
    );

    const history = useHistory();
    const {
        control,
        handleSubmit,
        setError,
        register,
        setValue,
        formState: { errors }
    } = useForm({
        mode: "onTouched",
        reValidateMode: "onChange",
        resolver: yupResolver(schema),
        shouldFocusError: true
    });

    const [localError, setErrorLocal] = useState(false);

    const [step, setStep] = useState(0);
    const [resend, setResend] = useState(false);
    const [zeroStepData, setZeroStepData] = useState({});
    const [firstStepData, setFirstStepData] = useState({});

    const onSubmit = () => {
        console.log(history);
    };

    const onZeroStepSubmit = data => {
        const { country, phone, email } = data;
        if (!country) {
            setError("country", {
                type: "server",
                message: "Required field"
            });
        }
        if (!phone) {
            setError("phone", {
                type: "server",
                message: "Required field"
            });
        }
        if (!email) {
            setError("email", {
                type: "server",
                message: "Required field"
            });
        }
        if (withNumber) {
            restoreSendCode(`%2B${country}${phone}`).then(res => {
                if (res.payload && res.payload.status && res.payload.status === 200) {
                    setZeroStepData({ phone: `${country}${phone}` });
                    setStep(1);
                } else {
                    toastErrors(res);
                }
            });
        } else {
            restoreSendCodeEmail(email).then(res => {
                if (res.payload && res.payload.status && res.payload.status === 200) {
                    setZeroStepData({ email });
                    setStep(1);
                } else {
                    toastErrors(res);
                }
            });
        }
    };

    const onFirstStepSubmit = data => {
        const { firstDigit, secondDigit, thirdDigit, fourthDigit, fifthDigit, sixthDigit } = data;
        if (withNumber) {
            restoreVerifyCode(
                firstDigit + secondDigit + thirdDigit + fourthDigit + fifthDigit + sixthDigit,
                `%2B${zeroStepData.phone}`
            ).then(res => {
                if (res.payload && res.payload.status && res.payload.status === 200) {
                    const token = res.payload.data.verification_token;
                    setFirstStepData({ token });
                    setStep(2);
                } else {
                    setErrorLocal(true);
                    toastErrors(res);
                }
            });
        } else {
            restoreVerifyCodeEmail(
                firstDigit + secondDigit + thirdDigit + fourthDigit + fifthDigit + sixthDigit,
                zeroStepData.email
            ).then(res => {
                if (res.payload && res.payload.status && res.payload.status === 200) {
                    const token = res.payload.data.verification_token;
                    setFirstStepData({ token });
                    setStep(2);
                } else {
                    setErrorLocal(true);
                    toastErrors(res);
                }
            });
        }
    };
    const onSecondStepSubmit = data => {
        const { new_password } = data;
        const obj = { new_password, verification_token: firstStepData.token };

        if (withNumber) {
            obj.phone_number = "+" + zeroStepData.phone;
        } else {
            obj.email = zeroStepData.email;
        }
        changePassword(obj).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                setStep(3);
            } else {
                toastErrors(res);
                if (
                    res.error.response &&
                    res.error.response.data &&
                    res.error.response.data.password &&
                    res.error.response.data.password.length > 0 &&
                    res.error.response.data.password[0].message
                ) {
                    setError("password", {
                        type: "server",
                        message: res.error.response.data.password[0].message
                    });
                    toastErrors(res);
                }
            }
        });
    };

    const [timer, setTimer] = useState(59);

    let interval = null;

    const resendCode = () => {
        let tempTimer = 59;
        setResend(true);
        interval = setInterval(() => {
            if (tempTimer === 0) {
                setResend(false);
                setTimer(59);
                clearInterval(interval);
                return;
            } else {
                setTimer(tempTimer - 1);
                tempTimer--;
            }
        }, 1000);
    };

    return (
        <div className="auth-box">
            <h1 className="auth-box__title">Reset password</h1>
            {step === 0 && (
                <form
                    className={`auth-box__wrap${!withNumber ? " px-56" : ""}`}
                    onSubmit={handleSubmit(onZeroStepSubmit)}
                >
                    {withNumber ? (
                        <span className="auth-box__desc mb-40 mx-auto max-w-306 t-center">
                            Please enter your phone number. We will send the verification code by SMS.
                        </span>
                    ) : (
                        <span className="auth-box__desc mb-40 mx-auto t-center">
                            Please enter your email and we will send you the password reset link. If you don’t have
                            access to this email anymore please contact <a href="#">our support.</a>
                        </span>
                    )}

                    {withNumber ? (
                        <>
                            <Controller
                                name="country"
                                control={control}
                                render={({ field }) => (
                                    <InputField
                                        select
                                        id="country"
                                        className="mb-24"
                                        type="text"
                                        label="Country / Region"
                                        error={errors.country?.message}
                                        {..._.omit(field, "ref")}
                                    >
                                        {country_phone_codes &&
                                            country_phone_codes.map(option => (
                                                <MenuItem key={option.country_iso_code} value={option.phone_code}>
                                                    {option.country_name} (+{option.phone_code})
                                                </MenuItem>
                                            ))}
                                    </InputField>
                                )}
                            />
                            <Controller
                                name="phone"
                                control={control}
                                render={({ field }) => (
                                    <InputField
                                        id="phone"
                                        className="mb-24"
                                        type="tel"
                                        label="Phone number"
                                        placeholder="0000 0000"
                                        error={errors.phone?.message}
                                        {..._.omit(field, "ref")}
                                    />
                                )}
                            />
                        </>
                    ) : (
                        <Controller
                            name="email"
                            control={control}
                            render={({ field }) => (
                                <InputField
                                    id="email"
                                    className="mb-24"
                                    type="email"
                                    label="Email"
                                    error={errors.email?.message}
                                    {..._.omit(field, "ref")}
                                />
                            )}
                        />
                    )}

                    <DefaultButton className="mt-48 mx-auto" type="submit">
                        Continue
                    </DefaultButton>
                </form>
            )}

            {step === 1 && (
                <>
                    <form className="auth-box__wrap" onSubmit={handleSubmit(onFirstStepSubmit)}>
                        <span className={`auth-box__desc mb-40 mx-auto t-center${withNumber ? " max-w-260" : ""}`}>
                            Enter the code we’ve sent by {withNumber ? "SMS to verify" : "email to reset password for"}
                            <b> {withNumber ? "+" + zeroStepData.phone : zeroStepData.email}</b>
                        </span>

                        <div className="code-wrap">
                            <div className="code-wrap__fields">
                                <input
                                    className="code-wrap__input"
                                    defaultValue={""}
                                    maxLength="1"
                                    id="firstInput"
                                    onPaste={e => {
                                        const pasted = e.clipboardData.getData("Text").split("");
                                        const fields = [
                                            "firstDigit",
                                            "secondDigit",
                                            "thirdDigit",
                                            "fourthDigit",
                                            "fifthDigit",
                                            "sixthDigit"
                                        ];
                                        pasted.forEach((el, idx) => {
                                            setValue(fields[idx], el);
                                        });
                                    }}
                                    {...register("firstDigit", { required: true, maxLength: 1 })}
                                    onChange={e => {
                                        if (e.target.value && e.target.value.length > 0) {
                                            document.getElementById("secondInput").focus();
                                        }
                                    }}
                                />
                                <input
                                    className="code-wrap__input"
                                    maxLength="1"
                                    id="secondInput"
                                    defaultValue={""}
                                    {...register("secondDigit", { required: true, maxLength: 1 })}
                                    onChange={e => {
                                        if (e.target.value && e.target.value.length > 0) {
                                            document.getElementById("thirdInput").focus();
                                        } else {
                                            document.getElementById("firstInput").focus();
                                        }
                                    }}
                                />
                                <input
                                    className="code-wrap__input"
                                    maxLength="1"
                                    id="thirdInput"
                                    defaultValue={""}
                                    {...register("thirdDigit", { required: true, maxLength: 1 })}
                                    onChange={e => {
                                        if (e.target.value && e.target.value.length > 0) {
                                            document.getElementById("fourthInput").focus();
                                        } else {
                                            document.getElementById("secondInput").focus();
                                        }
                                    }}
                                />
                                <input
                                    className="code-wrap__input"
                                    maxLength="1"
                                    id="fourthInput"
                                    defaultValue={""}
                                    {...register("fourthDigit", { required: true, maxLength: 1 })}
                                    onChange={e => {
                                        if (e.target.value && e.target.value.length > 0) {
                                            document.getElementById("fifthInput").focus();
                                        } else {
                                            document.getElementById("thirdInput").focus();
                                        }
                                    }}
                                />
                                <input
                                    className="code-wrap__input"
                                    maxLength="1"
                                    id="fifthInput"
                                    defaultValue={""}
                                    {...register("fifthDigit", { required: true, maxLength: 1 })}
                                    onChange={e => {
                                        if (e.target.value && e.target.value.length > 0) {
                                            document.getElementById("sixthInput").focus();
                                        } else {
                                            document.getElementById("fourthInput").focus();
                                        }
                                    }}
                                />
                                <input
                                    className="code-wrap__input"
                                    maxLength="1"
                                    id="sixthInput"
                                    defaultValue={""}
                                    {...register("sixthDigit", { required: true, maxLength: 1 })}
                                    onChange={e => {
                                        if (e.target.value && e.target.value.length > 0) {
                                            document.getElementById("sixthInput").blur();
                                        } else {
                                            document.getElementById("fifthInput").focus();
                                        }
                                    }}
                                />
                            </div>
                            {localError && (
                                <div className="auth-box__msg auth-box__msg--alert mt-14">
                                    <AlertIcon />
                                    <p>
                                        Sorry we are not able to verify the code. Please make sure you entered correct
                                        code for that number.
                                    </p>
                                </div>
                            )}
                        </div>

                        <DefaultButton className="mt-48 mx-auto" type="submit">
                            Verify
                        </DefaultButton>
                    </form>

                    <div className="auth-box__resend">
                        Didn’t receive the{withNumber && " SMS"} code?
                        {resend ? (
                            <span className="auth-box__resend-countdown">
                                Resend in 00:{String(timer).length === 1 ? "0" + timer : timer}
                            </span>
                        ) : (
                            <button
                                className="auth-box__resend-btn good-hover"
                                onClick={() => {
                                    if (withNumber) {
                                        restoreSendCode("%2B" + zeroStepData.phone).then(res => {
                                            if (res.payload && res.payload.status && res.payload.status === 200) {
                                                console.log(1);
                                            } else {
                                                toastErrors(res);
                                            }
                                        });
                                    } else {
                                        restoreSendCodeEmail(zeroStepData.email).then(res => {
                                            if (res.payload && res.payload.status && res.payload.status === 200) {
                                                console.log(1);
                                            } else {
                                                toastErrors(res);
                                            }
                                        });
                                    }
                                    resendCode();
                                }}
                            >
                                Resend
                            </button>
                        )}
                    </div>
                </>
            )}

            {step === 2 && (
                <form className="auth-box__wrap" onSubmit={handleSubmit(onSecondStepSubmit)}>
                    <span className="auth-box__desc mb-40 mx-auto max-w-265 t-center">
                        Please choose your new password
                    </span>

                    <Controller
                        name="new_password"
                        control={control}
                        render={({ field }) => (
                            <InputField
                                id="password"
                                type="password"
                                label="Password"
                                error={errors.password?.message}
                                {..._.omit(field, "ref")}
                            />
                        )}
                    />

                    <DefaultButton className="mt-48 mx-auto" type="submit">
                        Continue
                    </DefaultButton>
                </form>
            )}

            {step === 3 && (
                <form className="auth-box__wrap" onSubmit={handleSubmit(onSubmit)}>
                    <span className={`auth-box__desc mb-40 mx-auto t-center${withNumber ? " max-w-265" : ""}`}>
                        Password for <b>{withNumber ? "+" + zeroStepData.phone : zeroStepData.email}</b> has been
                        successfully updated
                    </span>

                    <img className="max-w-176 mx-auto" src={successImg} alt="success" />

                    <DefaultButton className="mt-48 mx-auto" type="link" to={urls.signInUrl.path}>
                        Sign in
                    </DefaultButton>
                </form>
            )}
        </div>
    );
};

const mapStateToProps = ({ authorization }) => {
    return {
        countries: authorization.countries
    };
};

const mapDispatchToProps = {
    getCountries,
    restoreSendCode,
    restoreSendCodeEmail,
    restoreVerifyCode,
    restoreVerifyCodeEmail,
    changePassword
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
