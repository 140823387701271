import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteDevice, getUserDevices, getUserInfo, setUserInfo } from "../usersActions";
import { handlePage } from "../../../core/helpers/functions";
import urls from "../../../core/urls";
import BackLink from "../../../Shared/BackLink";
import Avatar from "../../../Shared/Avatar";
import CopyToClipboard from "../../../Shared/CopyToClipboard";
import { TabItem, Tabs } from "../../../Shared/Tabs";
import NotFound from "../../../Shared/NotFound";
import UserDeviceCard from "../UserDeviceCard";
import Pagination from "../../../Shared/Pagination";
import Alert from "@material-ui/lab/Alert";
// import UserGroupCard from "../UserGroupCard";
import RemoveUserAccessDialog from "../Dialogs/RemoveUserAccessDialog";
// import DeviceUsersDialog from "../../Devices/Dialogs/DeviceUsersDialog";
// import AccessToDevicesDialog from "../Dialogs/AccessToDevicesDialog";
// import AddUserToGroupManuallyDialog from "../Dialogs/AddUserToGroupManuallyDialog";
// import RemoveUserFromGroupDialog from "../Dialogs/RemoveUserFromGroupDialog";
// import AddUserToGroupDialog from "../Dialogs/AddUserToGroupDialog";
// import { accessViaGroupDevices, allUserDevices, directAccessDevices } from "../../../core/helpers/demoData";
// import { allUserDevices } from "../../../core/helpers/demoData";
import "./UserPage.scss";

const UserPage = ({location}) => {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const {
    loading,
    userInfo,
    userDevices
  } = useSelector(({ users }) => users);

  const [page, setPage] = useState(0);

  useEffect(() => {
    if (location?.state?.user) {
      dispatch(setUserInfo(location.state.user));
    } else {
      dispatch(getUserInfo(slug));
    }
    dispatch(getUserDevices(slug, page + 1));
  }, [page]);

  const [removeAccess, setRemoveAccess] = useState({
    open: false,
    deviceId: "",
    deviceName: "",
    error: false
  });
  const onRemoveAccessOpen = (deviceId, deviceName) => () => {
    setRemoveAccess({
      ...removeAccess,
      open: true,
      deviceId,
      deviceName
    });
  };
  const onRemoveAccessClose = () => {
    setRemoveAccess({
      ...removeAccess,
      open: false,
      error: false
    });
  };
  const onRemoveAccessConfirm = () => {
    dispatch(deleteDevice(slug, removeAccess.deviceId)).then(res => {
      if (res?.payload?.status === 204) {
        onRemoveAccessClose();
        dispatch(getUserDevices(slug, page + 1));
      } else {
        setRemoveAccess({ ...removeAccess, error: true });
      }
    });
  };

  // const [deviceUsers, setDeviceUsers] = useState(false);
  // const onDeviceUsersOpen = data => () => setDeviceUsers(data);
  // const onDeviceUsersClose = () => setDeviceUsers(false);

  // const [accessDevices, setAccessDevices] = useState(false);
  // const onAccessDevicesOpen = () => setAccessDevices(true);
  // const onAccessDevicesClose = () => setAccessDevices(false);

  // const [addUserManually, setAddUserManually] = useState(false);
  // const onAddUserManuallyOpen = data => () => setAddUserManually(data);
  // const onAddUserManuallyClose = () => setAddUserManually(false);

  // const [remove, setRemove] = useState(false);
  // const [userData, setUserData] = useState(null);
  // const onRemoveOpen = data => () => {
  //   setUserData(data);
  //   setRemove(true);
  // };
  // const onRemoveClose = () => {
  //   setRemove(false);
  //   setUserData(null);
  // };

  // const [addUser, setAddUser] = useState(false);
  // const onAddUserOpen = () => setAddUser(true);
  // const onAddUserClose = () => setAddUser(false);

  const tabs = [
    {
      label: "All devices",
      count: userDevices?.count,
      devices: userDevices?.results,
      pages: userDevices?.pages
    }
    // {
    //   label: "Direct access",
    //   count: directAccessDevices.length,
    //   devices: directAccessDevices
    // },
    // {
    //   label: "Access via groups",
    //   count: accessViaGroupDevices.length,
    //   devices: accessViaGroupDevices
    // }
  ];

  // const userGroups = [{ id: 0, name: "test_group", users: 4 }];
  
  const from = location?.state?.from || urls.usersUrl;

  if (userInfo === null) {
    return (
      <div className="user-page page-wrap">
        <BackLink href={from.path} text={from.name} />
        <Alert className="mt-16" severity="warning">Selected user has no accesses to your devices</Alert>
      </div>
    );
  }

  const { first_name, last_name, email, phone_number, photo } = userInfo;

  const noDevices = <NotFound noData small title="No devices" />;

  return (
    <div className="user-page page-wrap">
      <BackLink href={from.path} text={from.name} />
      <div className="user-page__top">
        <Avatar firstName={first_name} lastName={last_name} src={photo} size={72} fontSize={25.2} />
        <h1>
          {first_name && first_name}
          {last_name && ` ${last_name}`}
        </h1>
      </div>

      <div className="user-page__section section-wrap">
        <div className="user-page__row user-page__indent">
          <div className="user-info">
            <span className="user-info__label">Email</span>
            <p className="user-info__text">
              {email ? email : "-"}
              {email && <CopyToClipboard className="user-info__copy" text={email} />}
            </p>
          </div>
          <div className="user-info">
            <span className="user-info__label">Phone</span>
            <p className="user-info__text">
              {phone_number ? phone_number : "-"}
              {phone_number && <CopyToClipboard className="user-info__copy" text={phone_number} />}
            </p>
          </div>
        </div>

        {/*<Tabs className="user-page__indent">*/}
        <Tabs>
          {tabs?.map(({ label, count, devices, pages }, index) => (
            <TabItem key={index} index={index} label={label} counter={count}>
              {loading ? (
                <h2 className="loading-text">Loading...</h2>
              ) : devices?.length > 0 ? (
                <>
                  <div className="user-page__grid">
                    {devices.map(
                      ({ id, device_name, type, serial_number }) => (
                        <UserDeviceCard
                          key={id}
                          name={device_name}
                          type={type}
                          number={serial_number}
                          onRemove={onRemoveAccessOpen(id, device_name)}
                        />
                      )
                    )}

                    <Link className="user-page__add-device-btn add-card-btn" to={`${urls.inviteUsersUrl.path}/${slug}`}>
                      <span>
                        <b>Click here</b> to give the user <br />
                        direct access to a device
                      </span>
                    </Link>
                  </div>

                  {count > 10 && <Pagination active={page} onChange={handlePage(setPage)} pageCount={pages} />}
                </>
              ) : (
                noDevices
              )}
            </TabItem>
          ))}
        </Tabs>

        {/*<h2 className="subtitle mb-24">*/}
        {/*  Belongs to user groups<span className="subtitle__number">{userGroups?.length}</span>*/}
        {/*</h2>*/}

        {/*<div className="user-page__grid">*/}
        {/*  {userGroups.map(({ id, name, users }) => (*/}
        {/*    <UserGroupCard*/}
        {/*      key={id}*/}
        {/*      name={name}*/}
        {/*      users={users}*/}
        {/*      onNameClick={onAddUserManuallyOpen(name)}*/}
        {/*      onRemove={onRemoveOpen({ firstName: "Peter", lastName: "Wood", name })}*/}
        {/*    />*/}
        {/*  ))}*/}
        {/*  <button className="add-card-btn" type="button" onClick={onAddUserOpen}>*/}
        {/*    <b>Click here</b> to add the user <br />*/}
        {/*    to a group*/}
        {/*  </button>*/}
        {/*</div>*/}
      </div>

      <RemoveUserAccessDialog
        open={removeAccess.open}
        onCLose={onRemoveAccessClose}
        firstName={first_name}
        lastName={last_name}
        deviceName={removeAccess.deviceName}
        error={removeAccess.error}
        onConfirm={onRemoveAccessConfirm}
      />
      {/*<DeviceUsersDialog open={deviceUsers} onCLose={onDeviceUsersClose} deviceName={deviceUsers} />*/}
      {/*<AccessToDevicesDialog open={accessDevices} onCLose={onAccessDevicesClose} />*/}
      {/*<AddUserToGroupManuallyDialog*/}
      {/*  open={addUserManually}*/}
      {/*  onCLose={onAddUserManuallyClose}*/}
      {/*  groupName={addUserManually}*/}
      {/*/>*/}
      {/*<RemoveUserFromGroupDialog open={remove} onCLose={onRemoveClose} userData={userData} />*/}
      {/*<AddUserToGroupDialog open={addUser} onCLose={onAddUserClose} />*/}
    </div>
  );
};

export default UserPage;
