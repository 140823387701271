import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { getProfile } from "../../../core/actions";
import { editProfile } from "../profileActions";
import { editNameSchema } from "../../../core/helpers/validations";
import DialogMUI from "../../../Shared/DialogMUI";
import InputField from "../../../Shared/InputField";
import DefaultButton from "../../../Shared/Buttons/DefaultButton";
import "./Dialogs.scss";

const EditNameDialog = ({ open, onClose, firstName, lastName }) => {
  const dispatch = useDispatch();
  const buttonLoading = useSelector(({ app }) => app.buttonLoading);

  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: { errors, isValid, isDirty }
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(editNameSchema),
    defaultValues: {
      first_name: firstName,
      last_name: lastName
    }
  });

  const onDialogClose = () => {
    onClose();
    reset();
  };

  const onSubmit = async data => {
    const res = await dispatch(editProfile(data));
    const errors = res.error?.response.data;

    if (res?.payload) {
      dispatch(getProfile());
      onDialogClose();
    }
    if (res?.error) {
      errors.first_name && setError("first_name", { type: "manual", message: errors.first_name[0].message });
      errors.last_name && setError("last_name", { type: "manual", message: errors.last_name[0].message });
    }
  };

  return (
    <DialogMUI className="profile-dialog" open={open} onClose={onDialogClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h2 className="profile-dialog__title">Edit your name</h2>
        <Controller
          name="first_name"
          control={control}
          render={({ field }) => (
            <InputField
              className="mb-24"
              label="First name"
              fullWidth
              error={errors.first_name?.message}
              inputProps={field}
            />
          )}
        />
        <Controller
          name="last_name"
          control={control}
          render={({ field }) => (
            <InputField label="Last name" fullWidth error={errors.last_name?.message} inputProps={field} />
          )}
        />
        <DefaultButton
          className="profile-dialog__btn"
          disabled={!isValid || !isDirty || buttonLoading}
          loading={buttonLoading}
          formAction
        >
          Save
        </DefaultButton>
      </form>
    </DialogMUI>
  );
};

export default EditNameDialog;
