/* eslint-disable no-case-declarations */
import { take, put, call, actionChannel, delay, race, cancelled } from "redux-saga/effects";
import CryptoJS from "crypto-js";
import { mqttConnect, mqttDisconnect, mqttSendCommand } from "../../Shared/Mqtt/actions";
import notifySuccess from "../../core/helpers/notifySuccess";
import notifyError from "../../core/helpers/notifyError";
import { DEVICES } from "./devicesActionTypes";
import { MQTT } from "../../Shared/Mqtt/actionTypes";
import { postHistory } from "./devicesActions";


export function* handleMqttOpenLock() {
  const requestChannel = yield actionChannel(DEVICES.MQTT_OPEN_LOCK);
  while (true) {
    const action = yield take(requestChannel);
    const { posts } = yield race({
      posts: call(openLock, action),
      timeout: delay(10000)
    });
    const { userId, device, secondRelay } = action.payload;
    const buttonName = device.type === "PRO_1" ? (secondRelay ? 2 : 1) : "";
    if (posts) {
      yield put(postHistory(device.id, userId, new Date(), true, buttonName));
      yield put({ type: DEVICES.MQTT_OPEN_LOCK_SUCCESS, payload: { device } });
      notifySuccess("Lock opened");
    } else if (posts === undefined) {
      yield put(postHistory(device.id, userId, new Date(), false, buttonName));
      yield put({ type: DEVICES.MQTT_OPEN_LOCK_FAIL, payload: { device } });
      notifyError("Could not open the lock. Make sure the lock is connected to the internet.");
    } else {
      yield put(postHistory(device.id, userId, new Date(), false, buttonName));
      yield put({ type: DEVICES.MQTT_OPEN_LOCK_FAIL, payload: { device } });
    }
  }
}

function* openLock(action) {
  try {
    const { userId, device, userToken, secondRelay } = action.payload;
    const { serial_number, parent_serial_number, access } = device;
    const keyIndex = access.digital_key.key_index;
    const key = access.digital_key.key;
    const start_time = Date.now();
    yield put(mqttConnect(userId, parent_serial_number ?? serial_number, userToken));
    const mqttAction = yield take([MQTT.MQTT_CONNECTED, MQTT.MQTT_FAILED]);
    if (mqttAction.type === MQTT.MQTT_FAILED) {
      yield put(mqttDisconnect());
      notifyError("Could not connect. Make sure you have an internet connection.");
      return false;
    }
    yield put(mqttSendCommand(`f${keyIndex}`));
    let done = false;

    while (!done) {
      const { message } = yield take(MQTT.MQTT_MESSAGE);
      // Check if message is a successful response
      if (message.length >= 2 && message[message.length - 2] === "D") {
        const body = message.substr(0, message.length - 2);
        const type = message.substr(-1, 1);
        switch (type) {
          case "f":
            const iv = { words: [0, 0, 0, 0], sigBytes: 16 };
            const ciphertext = CryptoJS.AES.encrypt(body, CryptoJS.enc.Hex.parse(key), { iv }).toString(
              CryptoJS.format.Hex
            );
            const halfCrypt = ciphertext.substring(0, 32);
            let command = "";
            if (keyIndex === 0) {
              command = `w${halfCrypt}`;
            } else {
              command = `${secondRelay ? "k" : "l"}${halfCrypt}`;
            }
            yield put(mqttSendCommand(command));
            break;
          case "w":
            yield put(mqttSendCommand(secondRelay ? "j" : "i"));
            break;
          case "i":
          case "j":
          case "l":
          case "k":
            const now = Date.now();
            const time_elapsed = now - start_time;
            console.log(`Lock opened. Process took ${time_elapsed} ms.`);
            yield put(mqttDisconnect());
            done = true;
            break;
          default:
            break;
        }
      }
      // Check if message is an error response 
      else if (message.length === 3 && message[0] === "E") {
        const command = message[1];
        const errorCode = message[2];
        console.log(`Error for command: ${command}, error code: ${errorCode}`);
        switch (command) {
          case "w":
          case "l":
            notifyError("Could not open the lock. Your digital key is not valid.");
            break;
          default:
            notifyError("Error communicating with the lock.");
        }
        yield put(mqttDisconnect());
        return false;
      }
    }
    return true;
  } finally {
    if (yield cancelled()) yield put(mqttDisconnect());
  }
}