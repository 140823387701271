import React from "react";
import { ReactComponent as CloseIcon } from "../../../assets/images/close.svg";
import "./UserGroupCard.scss";

const UserGroupCard = ({ name, onNameClick, users, devices, onRemove }) => {
  return (
    <div className="user-group-card">
      {name && (
        <button className="user-group-card__name truncate-ellipsis" type="button" onClick={onNameClick}>
          {name}
        </button>
      )}
      <span className="user-group-card__info">
        {users && `${users} user${users > 1 ? "s" : ""}`}
        {devices && `${devices} device${devices > 1 ? "s" : ""}`}
      </span>
      {onRemove && (
        <button className="user-group-card__btn" type="button" aria-label="Remove group" onClick={onRemove}>
          <CloseIcon />
        </button>
      )}
    </div>
  );
};

export default UserGroupCard;
