import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ClickAwayListener } from "@material-ui/core";
import urls from "../../../core/urls";
import { ReactComponent as CurrentIcon } from "../../../assets/images/success-2.svg";
import { ReactComponent as MoreIcon } from "../../../assets/images/more.svg";
import { ReactComponent as SwitchIcon } from "../../../assets/images/transfer.svg";
import { ReactComponent as UserIcon } from "../../../assets/images/user.svg";
import { ReactComponent as EditIcon } from "../../../assets/images/edit.svg";
import { ReactComponent as KeyIcon } from "../../../assets/images/key.svg";
import "./CompanyCard.scss";

const CompanyCard = ({ id, title, desc, current, role, onSwitch }) => {
  const [more, setMore] = useState(false);
  const openMore = () => setMore(true);
  const closeMore = () => setMore(false);

  const switchCompany = () => {
    current ? onSwitch(null) : onSwitch(id, title);
    closeMore();
  };

  return (
    <article className="company-card">
      <div className="company-card__row">
        {current && <CurrentIcon className="company-card__current" />}
        <span className="company-card__title truncate-ellipsis">{title ? title : "-"}</span>
      </div>
      <span className="company-card__desc">{desc ? desc : "-"}</span>

      <div className="company-card__more-wrap">
        <button
          className={`company-card__more-btn${more ? " company-card__more-btn--active" : ""}`}
          type="button"
          aria-label="More actions"
          onClick={openMore}
        >
          <MoreIcon />
        </button>

        {more && (
          <ClickAwayListener onClickAway={closeMore}>
            <div className="company-card__more-menu">
              <Link className="company-card__more-item" to={`${urls.ownersAndAdminsUrl.path}/${id}`}>
                <UserIcon />
                <span>{urls.ownersAndAdminsUrl.name}</span>
              </Link>
              <button className="company-card__more-item" type="button" onClick={switchCompany}>
                <SwitchIcon />
                <span>Switch to {current ? "personal profile" : "company"}</span>
              </button>
              {role !== "user" && (
                <>
                  <Link className="company-card__more-item" to={`${urls.editCompanyUrl.path}/${id}`}>
                    <EditIcon />
                    <span>{urls.editCompanyUrl.name}</span>
                  </Link>
                  <Link className="company-card__more-item" to={`${urls.apiKeysUrl.path}/${id}`}>
                    <KeyIcon />
                    <span>{urls.apiKeysUrl.name}</span>
                  </Link>
                </>
              )}
            </div>
          </ClickAwayListener>
        )}
      </div>
    </article>
  );
};

export default CompanyCard;
