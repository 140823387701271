import React, { useEffect, useState } from "react";
import DialogMUI from "../../../Shared/DialogMUI";
import DefaultButton from "../../../Shared/Buttons/DefaultButton";
import InputField from "../../../Shared/InputField";
import { ReactComponent as InfoIcon } from "../../../assets/images/info-2.svg";
import "./Dialogs.scss";

const AddApiKeyDialog = ({ open, onCLose, loading, onConfirm, addedKey }) => {
  const [name, setName] = useState("");
  const handleName = event => setName(event.target.value);

  useEffect(() => {
    !open && setName("");
  }, [open]);

  return (
    <DialogMUI className="company-dialog" open={open} onClose={onCLose}>
      {!addedKey && (
        <div className="t-center">
          <h2 className="mb-24 t-center">Add API key</h2>
          <p className="mb-24 t-center">Please enter a name for the new API key</p>
          <InputField className="mb-48" label="Name" fullWidth value={name} onChange={handleName} />
          <DefaultButton loading={loading} disabled={loading || !name} onClick={onConfirm(name)}>
            Add
          </DefaultButton>
        </div>
      )}
      {addedKey && (
        <>
          <h2 className="mb-32">Add API key</h2>
          <p className="mb-16">
            <b>{addedKey?.name}</b> has been successfully added.
          </p>
          <div className="company-dialog__group mb-16">
            <span>Key</span>
            <b>{addedKey?.key}</b>
          </div>
          <div className="company-dialog__info">
            <InfoIcon />
            <b>The key is only shown once. Store the key securely!</b>
          </div>
        </>
      )}
    </DialogMUI>
  );
};

export default AddApiKeyDialog;
