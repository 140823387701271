import React, { useState } from "react";
import PropTypes from "prop-types";
import { ReactComponent as CopyIcon } from "./icons/copy.svg";
import "./CopyToClipboard.scss";
import Tooltip from "../Tooltip";

const CopyToClipboard = ({ className, text, ariaLabel = "text" }) => {
  const [copy, setCopy] = useState("");
  const onCopy = text => async () => {
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(text);
      setCopy(text);
      const timer = setTimeout(() => setCopy(""), 1000);
      return () => clearTimeout(timer);
    } else {
      setCopy("");
      console.error("Copy to clipboard error");
    }
  };

  const classes = ["copy-to-clipboard"];
  if (className) classes.push(className);

  return (
    <button className={classes.join(" ")} type="button" aria-label={`Copy ${ariaLabel}`} onClick={onCopy(text)}>
      <Tooltip text="Copied" onHover={false} show={copy === text}>
        <CopyIcon />
      </Tooltip>
    </button>
  );
};

CopyToClipboard.propTypes = {
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  text: PropTypes.string.isRequired
};

export default CopyToClipboard;
