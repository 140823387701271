import PropTypes from "prop-types";
import React from "react";
import { Autocomplete } from "@material-ui/lab";
import "./AutocompleteMUI.scss";

const AutocompleteMUI = ({
  className = "",
  forcePopupIcon = false,
  options,
  filterOptions,
  getOptionLabel,
  renderOption,
  renderInput,
  onChange,
  ...props
}) => {
  return (
    <div className={`autocomplete-mui${className && ` ${className}`}`}>
      <Autocomplete
        {...props}
        openOnFocus
        disablePortal
        disabledItemsFocusable
        forcePopupIcon={forcePopupIcon}
        options={options}
        filterOptions={filterOptions}
        getOptionLabel={getOptionLabel}
        renderOption={renderOption}
        renderInput={renderInput}
        onChange={onChange}
      />
    </div>
  );
};

AutocompleteMUI.propTypes = {
  className: PropTypes.string,
  filterOptions: PropTypes.func,
  forcePopupIcon: PropTypes.oneOf(["auto", PropTypes.bool]),
  getOptionLabel: PropTypes.func,
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  renderInput: PropTypes.func.isRequired,
  renderOption: PropTypes.func
};

export default AutocompleteMUI;
